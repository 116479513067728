import './LandingBaner.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Carousel from 'react-material-ui-carousel'
 import 'react-lazy-load-image-component/src/effects/blur.css';
import { yachtactivityImage } from '../../../../Images/cruiseImages';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { CircularProgress } from '@mui/material';


const items = [
  {
    src: yachtactivityImage,
    alt: 'Image 1',
  },
  {
    src: yachtactivityImage,
    alt: 'Image 2',
  },
  {
    src: yachtactivityImage,
    alt: 'Image 3',
  },
];

const Item = ({ item }) => (
  <LazyLoadImage
    src={item}
    className="carousel-image"
    effect="blur"
    visibleByDefault
    placeholder={<> <CircularProgress/></>}
    />
);
const LandingBanner = ({data}) => {

  const settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
  };

  return (
    <div className="LandingBanner">
       <Slider {...settings}>
        {Object.keys(data).length>0 &&  data.imageUrl.map((image, index) => (
          <div key={index} className="carousel-slide">
            <Item key={index} item={image} />
          </div>
        ))}
      </Slider>
    </div>
  );
};
export default LandingBanner