import "./Footer.css";
import Fb from "../Images/Facebook.svg";
import Tw from "../Images/Twitter.svg";
import lk from "../Images/LinkedIn.svg";
import is from "../Images/Instagram.svg";
import location from "../Images/location_on.svg";
import emaill from "../Images/email.svg";
import call from "../Images/call.svg";
import { humrahLogoImage } from "../Images/homepage";
import { Link } from "react-router-dom";
import { FiInstagram } from "react-icons/fi";
import { useState } from "react";

const Footer = () => {
  
  const [email, setEmail] = useState('');
  const [subscribed, setSubscribed] = useState(false);

  const handleSubscribe = () => {
    if (email) {
      setSubscribed(true);
    }
  };

  return (
    <>
      <div className="flex w-full bg-[#E9F9FF] flex-col 2xl:justify-center 2xl:items-center">
        <div className="MainFooterClass w-full 2xl:w-10/12 bg-[#E9F9FF]">
          <div className="flex flex-wrap md:flex-nowrap min-h-[35vh] w-full MainMiddleSectionFooter">
            <div className="flex flex-1 flex-col w-full FooterLogoSection">
            <Link to="/">
              <img
                className="flex mb-3 w-60"
                src={humrahLogoImage}
                alt="Humrah Logo"
                />
                </Link>
              <div className="flex flex-col w-full h-full justify-between">
                <div className="flex w-full flex-col">
                  <p className="flex w-full my-4 text-[#1A2E49] text-base">
                    Unlock your dream Dubai vacation with our swift visa
                    service! Enjoy luxury, safaris, and more hassle-free!
                  </p>
                  {/* <p className="flex py-5 min-h-[3vh]">@Humrahtravels@info</p> */}
                  <a
                    href="mailto:support@evouchr.org"
                    rel="noreferrer"
                    target="_blank"
                  >
                    {" "}
                    <span className="flex py-5 text-[#1A2E49] min-h-[3vh]">
                      
                    {/* azad@humrahtravel.com */}
                    </span>
                  </a>
                  <p className="flex min-h-[3vh] text-[#1A2E49]">
                   {/* +971 58 173 2681 */}
                  </p>
                </div>
              </div>
            </div>

            <div className="flex items-center px- sm:px-10 flex-col">
             <div className="flex justify-start flex-col">
             <div className="NewHeadingSectnFooter font-semibold">
                <Link to="/">Company</Link>
              </div>
              <div className="FooterOtherElements">
                <Link to="/">About Us</Link>
              </div>
              <div className="FooterOtherElements">
                <Link to="/">Privacy</Link>
              </div>
              <div className="FooterOtherElements">
                <Link to="/">Terms and Conditions</Link>
              </div>
              <div className="FooterOtherElements">
                <Link to="/">Others</Link>
              </div>
             </div>
            </div>

            <div className="flex 2xl:flex-0 flex-1 justify-center items-center px-4 sm:px-10 flex-col">
            <div className="flex justify-start flex-col">

              <div className="NewHeadingSectnFooter text-ellipsis overrflow-hidden font-semibold">
                <a href="">Quick Links</a>
              </div>
              <div className="FooterOtherElements text-ellipsis">
                <Link to="/safaris">Desert Safari</Link>
              </div>
              <div className="FooterOtherElements text-ellipsis">
                <Link to="/cruises">Cruise</Link>
              </div>
              <div className="FooterOtherElements text-ellipsis">
                <Link to="/yachts">Yacht</Link>
              </div>
              <div className="FooterOtherElements">
                <Link to="/activities">Activities</Link>
              </div>
              </div>
            </div>

            <div className="flex flex-1 justify-start w-full px-4 sm:px-10 flex-col">
              <div className="NewHeadingSectnFooter font-semibold">
                <a className="py-4" href="">
                  Join a Newsletter
                </a>
              </div>
              <div className="FooterOtherElements my-1">
      <div className="flex flex-col xl:flex-row w-full">
        <input
          type="email"
          placeholder="Enter Email"
          className="flex-grow border-black xl:h-auto min-h-[5vh] lg:w-auto md:w-full px-2 rounded-md xl:rounded-l-md"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <button
          className="subscribe-button rounded-b-md xl:rounded-r-md lg:w-auto md:w-full xl:rounded-b-none mt-2 xl:mt-0"
          onClick={handleSubscribe}
        >
          Subscribe
        </button>
      </div>
      {subscribed && (
        <div className="text-green-600 mt-2">
          You are now subscribed to the newsletter.
        </div>
      )}
    </div>
              <div className="flex w-full h-full">
                <div className="flex w-full md:w-10/12 my-3 h-full items-center">
                  <img
                    src={Fb}
                    className="flex mx-2"
                    width={30}
                    alt="facebook"
                  />
                  <img
                    src={Tw}
                    className="flex mx-2"
                    width={30}
                    alt="twitter"
                  />
                  <img
                    src={lk}
                    className="flex mx-2"
                    width={30}
                    alt="linkedin"
                  />
                  <img
                    src={is}
                    className="flex mx-2"
                    width={30}
                    alt="instagram"
                  />
                </div>
              </div>
              <div className="FooterOtherElements">
                <a href="#" className="text-white">.</a>
              </div>
            </div>
          </div>

          <div className="flex flex-wrap min-h-[5vh] px-4 sm:px-10 border-t border-[#76AEFF] contact-info">
            <div className="company-name">© Humrah Travels & Tourism LLC</div>
            <div className="contact-details flex flex-wrap">
              <div className="detail flex items-center w-full sm:w-auto">
                <i className="material-icons">
                  <img src={location} alt="location" width={25} />
                </i>
                <span className="ml-2">
                  3rd Floor, Building 1, Main Street, City
                </span>
              </div>
              <div className="detail flex items-center w-full sm:w-auto mt-2 sm:mt-0 sm:ml-4">
                <i className="material-icons">
                  <img src={emaill} alt="email" width={25} />
                </i>
                <a
                  href="mailto:support@evouchr.org"
                  rel="noreferrer"
                  target="_blank"
                >
                  {" "}
                  <span className="flex py-5 min-h-[3vh]">
                    azad@humrahtravel.com
                  </span>
                </a>
              </div>
              <div className="detail flex items-center w-full sm:w-auto mt-2 sm:mt-0 sm:ml-4">
                <i className="material-icons">
                  <img src={call} alt="call" width={25} />
                </i>
                <span className="ml-2">+971 58 173 2681</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
