import React from "react";
import './WhatYouWillComponent.css';
import { HomeSectionImageThree, NumberOne, NumberThree, NumberTwo } from "../../Images/Images";


const WhatYouWillComponent = () => {
    return(
        <>
           <div className="MainBestExperinceContainer">
                <div className="TitleExperinceTxtSecThree">
                    <p className="text-2xl font-medium mb-4 text-[#2D2D2D]">What you will get with us</p>
                    {/* <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,  quis nostrud exercitatio
                    </p> */}
                </div>
                {/* <div className="TitleExperinceButton">
                    <div>
                        <button className="ContactUsHomeButtonFilled">Contact Us</button>
                    </div>
                </div> */}
            </div>

            <div className="MainWhatToContainer">
                <div className="LeftSideTextContainer">

                    <div className="WhatYouGetInfoPointOne">
                        <div className="NumberIocnNdText">
                            <div><img src={NumberOne} alt="NumberIconFirst" className="NumberImgIcon"/></div>
                            <p>Expert travel advice</p>
                        </div>
                        <div className="WhatToDoDescTxt">
                            <p>
                                A reputable travel agency should have experienced and knowledgeable staff who can provide expert advice on destinations, activities, accommodation, and transportation.
                            </p>
                        </div>
                    </div>

                    <div className="WhatYouGetInfoPointOne">
                        <div className="NumberIocnNdText">
                            <div><img src={NumberTwo} alt="NumberIconFirst" className="NumberImgIcon"/></div>
                            <p>Competitive pricing</p>
                        </div>
                        <div className="WhatToDoDescTxt">
                            <p>
                                A reputable travel agency will negotiate with travel suppliers on your behalf to get you the best possible prices.
                            </p>
                        </div>
                    </div>

                    <div className="WhatYouGetInfoPointOne">
                        <div className="NumberIocnNdText">
                            <div><img src={NumberThree} alt="NumberIconFirst" className="NumberImgIcon"/></div>
                            <p>Customized itineraries</p>
                        </div>
                        <div className="WhatToDoDescTxt">
                            <p>
                                A good travel agency will work with you to create a personalized itinerary that suits your preferences, budget, and schedule.
                            </p>
                        </div>
                    </div>

                </div>
                <div className="RightSideImageContainer">
                    <img src={HomeSectionImageThree} alt="WhatYouGetImage" className="WhatYouGetImage"/>
                </div>
            </div>
        </>
    )
}

export default WhatYouWillComponent;