import React, { useEffect, useState } from "react";
import "./HeaderMenu.css";
import { SampleImage } from "../Images/Images";
import SidebarComponent from "../SidebarComponent/SidebarComponent";
import { Link, useLocation } from "react-router-dom";
import DetailedBox from "./DetailedBox";
import { humrahLogoImage } from "../Images/homepage";
import cruise from "../Images/icons/cruiseico.png";
import yacht from "../Images/icons/yachtico.svg";
import activity from "../Images/icons/activityPark.svg";
import safari from "../Images/icons/safariico.svg";
import phone from "../Images/icons/Phone.svg";
import {
  ObservationActivites,
  SightseeingActivites,
  ThemeParkActivites,
  WaterParkActivites,
  activities,
  creekCruises,
  cruises,
  marinaCruises,
  safaris,
  yachts,
} from "../cruiseData";

function HeaderMenu() {
  const location = useLocation();
  const isHomePage = location.pathname === "/";
  const [hoveredItem, setHoveredItem] = useState(false);
  const [category, setCategory] = useState("activities");
  const [data, setData] = useState("activities");
  const acitivityData = [
    {
      title: "Observation Deck",
      data: ObservationActivites,
      navigate: "ObservationActivites",
    },
    {
      title: "SightSeeing Tours",
      data: SightseeingActivites,
      navigate: "SightseeingActivites",
    },
    {
      title: "ThemePark Tickets",
      data: ThemeParkActivites,
      navigate: "ThemeParkActivites",
    },
    {
      title: "WaterPark Tickets",
      data: WaterParkActivites,
      navigate: "WaterParkActivites",
    },
  ];

  const handleMouseEnter = (category) => {
    setHoveredItem(true);
    setCategory(category);
    switch (category) {
      case "cruises":
        setData([...marinaCruises, ...creekCruises]);
        break;
      case "yachts":
        setData(yachts);
        break;
      case "safaris":
        setData(safaris);
        break;
      case "activities":
        setData(acitivityData);
        break;
      default:
        setData([]);
    }
  };

  const handleMouseLeave = () => {
    setHoveredItem(false);
  };

  useEffect(() => {
    switch (category) {
      case "cruises":
        setData([...marinaCruises, ...creekCruises]);
        break;
      case "yachts":
        setData(yachts);
        break;
      case "safaris":
        setData(safaris);
        break;
      case "activities":
        setData(acitivityData);
        break;
      default:
        setData([]);
    }
  }, [category]);

  const handleScroll = () => {
    if (hoveredItem) {
      setHoveredItem(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [hoveredItem]);

  const handlePhoneClick = () => {
    const phoneNumber = "+971581732681";
    if (window.innerWidth <= 768) {
      window.location.href = `tel:${phoneNumber}`;
    } else {
      window.location.href = `https://web.whatsapp.com/send?phone=${phoneNumber}`;
    }
  };

  return (
    <>
      <div className={isHomePage ? "MainNavclass" : "MainNavclass"}>
        <div className="NavContainer relative">
          <div className="LogoContainer">
            <Link to="/" className="headerMenuLink">
              <img src={humrahLogoImage} className="HeaderLogo" />
            </Link>
          </div>

          <div className="headerMenuMainContainer relative">
            <ul className="headerMenu flex">
              <li
                className="flex p-2 rounded-full px-5 justify-center min-h-[2vh] hover:bg-[#009BDE] hover:text-white bg-[#A8E5FF] text-[#36404E] relative -mb-20"
                onMouseEnter={() => handleMouseEnter("activities")}
                onMouseLeave={handleMouseLeave}
              >
                <Link
                  to="/activities"
                  className="headerMenuLink flex items-center"
                >
                  <div className="icon mx-1"></div>
                  Activities
                </Link>
              </li>

              <li
                className="flex p-2 rounded-full px-5 justify-center min-h-[2vh] hover:bg-[#009BDE] hover:text-white bg-[#A8E5FF] text-[#36404E] relative -mb-20"
                onMouseEnter={() => handleMouseEnter("safaris")}
                onMouseLeave={handleMouseLeave}
              >
                <Link
                  to="/safaris"
                  className="headerMenuLink flex items-center whitespace-nowrap overflow-hidden text-ellipsis"
                >
                  <div className="icon1 mx-1"></div>
                  Desert Safari
                </Link>
              </li>
              <li
                className="flex p-2 rounded-full px-5 justify-center min-h-[2vh] hover:bg-[#009BDE] hover:text-white bg-[#A8E5FF] text-[#36404E] relative -mb-20"
                onMouseEnter={() => handleMouseEnter("cruises")}
                onMouseLeave={handleMouseLeave}
              >
                <Link
                  to="/cruises"
                  className="headerMenuLink flex items-center"
                >
                  <div className="icon2 mx-1"></div>
                  Cruise
                </Link>
              </li>
              <li
                className="flex p-2 rounded-full px-5 justify-center min-h-[2vh] hover:bg-[#009BDE] hover:text-white bg-[#A8E5FF] text-[#36404E] relative -mb-20"
                onMouseEnter={() => handleMouseEnter("yachts")}
                onMouseLeave={handleMouseLeave}
              >
                <Link to="/yachts" className="headerMenuLink flex items-center">
                  <div className="icon3 mx-1"></div>
                  Yacht
                </Link>
              </li>

              {/* <li
                className="flex p-2 rounded-full px-5 justify-center min-h-[2vh] hover:bg-[#009BDE] hover:text-white bg-[#A8E5FF] text-[#36404E] relative -mb-20"
               
              >
                <Link to="/yachts" className="headerMenuLink flex items-center">
                  <div className="icon3 mx-1"></div>
                  Visa
                </Link>
              </li> */}
            </ul>
          </div>

          <div className="flex content-center">
            <div
              className="HeaderMenuBtn cursor-pointer flex content-center"
              onClick={handlePhoneClick}
            >
              <img
                className="flex mx-1 h-7 w-7 items-center text-[#1A2E49]"
                src={phone}
                alt="phone"
              />
              +971 58 173 2681
            </div>
          </div>
        </div>

        <div className="MobViewFlex">
          <div className="LogoContainer MobNavContainer">
            <Link to="/" className="headerMenuLink flex items-center">
              <img src={humrahLogoImage} className="HeaderLogo" />
            </Link>
          </div>

          <div className="MobNavContainer">
            <SidebarComponent />
          </div>
        </div>
      </div>
      <div className="detailedBoxContainer">
        <DetailedBox
          isVisible={hoveredItem}
          handleMouseEnter={handleMouseEnter}
          handleMouseLeave={handleMouseLeave}
          category={category}
          data={data}
        />
      </div>
    </>
  );
}

export default HeaderMenu;
