import { useEffect, useState } from "react";
import MapComponent from "../../../components/MapComponent/MapComponent.jsx";
import { FaCheckCircle, FaInfoCircle, FaTimesCircle } from "react-icons/fa";
import {
  popularCruises,
  popularYachts,
  popularActivites,
} from "../../../cruiseData.js";
import LandingBanner from "../../Yachts/cruiseLanding/LandingBanner/LandingBaner.jsx";
import "swiper/css";
import "swiper/css/effect-cards";
import Booking from "../../../components/BookingSection/Booking.jsx";
import { LuTimer } from "react-icons/lu";
import { MdOutlineFreeCancellation } from "react-icons/md";
import { FaPeopleGroup } from "react-icons/fa6";
import { FaLanguage } from "react-icons/fa";
import "swiper/css";
import "swiper/css/navigation";
import { safaris } from "../../../cruiseData.js";
import { Link, useParams } from "react-router-dom";
import Post2 from "../../../Images/safariImg/Image2.png";
import Post3 from "../../../Images/safariImg/Image3.png";
import Post4 from "../../../Images/safariImg/Image4.png";
import Post5 from "../../../Images/safariImg/Image5.png";
import Highlight from "../../../Images/Highlightimg.png";
import Star from "../../../Images/Star.svg";
import "./Safari.css";
import SafariFAQComponent from "../../../components/SafariComponent/SafariFAQComponent.jsx";
import Arrow from "../../../Images/safariImg/ArrowCircleUpRight.svg";
import NewSafari from "../../../Images/safaridetailmg.png";
import Dinner from "../../../Images/Dinerrrblock.png";
import check from "../../../Images/icons/greencheck.svg";
import notcheck from "../../../Images/icons/redno.svg";
import info from "../../../Images/icons/yellowinfo.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import emailjs from "@emailjs/browser";
import RecomendedSlider from "../../../components/RecomendedSlider/RecomendedSlider.jsx";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

const SafariDetails = () => {
  const { productName } = useParams();
  const [activityData, setActivityData] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [SafariData, setSafariData] = useState([]);
  const [guestNumber, setGuestNumber] = useState(1);
  const [checkInTime, setCheckInTime] = useState("");
  const [numberOfTickets, setNumberOfTickets] = useState(1);
  const [email, setEmail] = useState("");
  const now = new Date();
  const defaultDate = now.toISOString().split("T")[0]; // format date as yyyy-mm-dd
  const [checkInDate, setCheckInDate] = useState(defaultDate);
  const defaultTime = now.toTimeString().split(" ")[0].slice(0, 5); // format time as hh:mm
  const [mobileNumber, setMobileNumber] = useState("");
  const [calculatedPrice, setCalculatedPrice] = useState(
    SafariData?.price || 0
  );

  const [phone, setPhone] = useState("");
  const [countryCode, setCountryCode] = useState({
    value: "+91",
    label: (
      <img
        src="https://flagcdn.com/in.svg"
        alt="India"
        style={{ width: "20px", marginRight: "8px" }}
      />
    ),
  });
  const [phoneError, setPhoneError] = useState("");

  useEffect(() => {
    const cruise = safaris.find(
      (item) => item.productName.replace(/ /g, "-") === productName
    );
    setSafariData(cruise);
  }, [productName]);

  const formatproductName = (productName) => {
    return productName.replace(/ /g, "-");
  };

  useEffect(() => {
    if (SafariData?.price) {
      setTotalPrice(SafariData?.price * guestNumber);
      setCalculatedPrice(SafariData?.price * guestNumber);
    }
  }, [SafariData?.price, guestNumber]);

  const highlights = [
    "Cruise along Deira Creek and Bur Dubai Creek for breathtaking city views",
    "Red carpet arrival with a welcome non-alcoholic drink",
    "Traditional Arabian wooden dhow with two tiers: an air-conditioned lower deck and an open-air upper deck",
    "Savor an international buffet dinner with vegetarian and non-vegetarian options",
    "Enjoy soft drinks, juice, mineral water, tea, and coffee",
    "Live entertainment including Tanoura dance show",
    "Optional pick-up and drop-off services available",
  ];

  const handleGuestNumberChange = (e) => {
    const value = parseInt(e.target.value);
    if (!isNaN(value) && value >= 1) {
      setGuestNumber(value);
      setCalculatedPrice(SafariData?.price * value);
    } else if (e.target.value === "") {
      setGuestNumber("");
      setCalculatedPrice(SafariData?.price);
    } else {
      setGuestNumber(1);
      setCalculatedPrice(SafariData?.price);
    }
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    if (name === "checkInDate") {
      setCheckInDate(value);
    }
  };

  const handleTimeChange = (e) => {
    setCheckInTime(e.target.value);
  };

  const handleTicketChange = (e) => {
    setNumberOfTickets(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleMobileNumberChange = (e) => {
    setMobileNumber(e.target.value);
  };

  const handlePhoneChange = (value) => {
    setPhone(value);
  };

  const [guestError, setGuestError] = useState("");
  const [phoneeError, setPhoneeError] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    // Reset errors
    setGuestError("");
    setPhoneeError("");

    // Check if phone number is provided
    if (!phone) {
      setPhoneeError("Please enter your phone number");
      console.error("Phone number is missing");
    }

    // Check if the number of guests is valid
    if (guestNumber <= 0 || isNaN(guestNumber)) {
      setGuestError("Please enter a valid number of guests.");
      console.error("Please enter a valid number of guests.");
    }

    // If there are errors, prevent submission
    if (guestError || phoneeError) {
      return;
    }

    // Proceed with form submission
    const templateParams = {
      guestNumber,
      checkInDate,
      numberOfTickets,
      email:email,
      phonenumber: phone,
      message: "To Enquire ",
      activityName: SafariData.productName,
      price: calculatedPrice,
    };

    emailjs
      .send(
        "service_lwtlh2h",
        "template_9606ai3",
        templateParams,
        "upvgjVmpU7y0ctsks"
      )
      .then((response) => {
        console.log("Email sent successfully!");
        alert("Email sent successfully!");
      })
      .catch((err) => {
        console.error("Failed to send email:", err);
        alert("Failed to send email. Please try again later.");
      });
  };

  const populardata = [...popularYachts, ...popularCruises];

  const getTodayDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, "0"); // Months start at 0!
    const dd = String(today.getDate()).padStart(2, "0");

    return `${yyyy}-${mm}-${dd}`;
  };

  console.log(SafariData);
  return (
    <div className="flex w-full flex-col 2xl:justify-center 2xl:items-center">
      <div className="flex h-full w-full 2xl:mx-0 2xl:w-10/12 flex-col ">
        <div className="CruisesCarousel">
          <LandingBanner data={SafariData} />
        </div>

        <div className="vacation-info mt-2 text-center p-4">
          <h1 className="title md:text-2xl mb-4 text-[#171717] font-semibold justify-center">
            {SafariData?.productName}
          </h1>
          <p className="description text-sm md:text-base text-[#3B474B]">
            {SafariData?.overview}
          </p>
        </div>

        <div className="flex w-full h-full flex-col">
          <div className="max-w-full flex mx-3 p-4">
            <div className="w-full flex flex-col rounded-lg overflow-hidden">
              {Object.keys(SafariData).length > 0 && (
                <>
                  <img
                    src={SafariData?.imageUrl[1]}
                    alt="Desert Safari"
                    className="w-full h-64 object-cover"
                  />
                </>
              )}

              <form onSubmit={handleSubmit}>
                <div className="flex flex-col lg:flex-row w-full border-2 -mt-6 shadow-md bg-white lg:rounded-full overflow-hidden items-center">
                  <div className="flex flex-1 flex-col lg:flex-row gap-2 items-center p-2 lg:pl-6 lg:p-2 w-full">
                    <div className="flex-1 flex flex-col border-b lg:border-r-2 lg:border-b-0 justify-center items-start w-full lg:min-w-[120px]">
                      <label className="flex px-2 text-[#222222]">Date</label>
                      <input
                        type="date"
                        id="bookingDate"
                        value={checkInDate || defaultDate}
                        onChange={handleDateChange}
                        placeholder="All Dates"
                        name="bookingDate"
                        min={getTodayDate()}
                        className="mt-1 px-2 block w-full py-2 bg-white rounded-md sm:text-sm border-b border-transparent focus:border-gray-300 focus:outline-none"
                      />
                    </div>

                    <div className="flex-1 w-full lg:min-w-[100px] border-b lg:border-r-2 lg:border-b-0">
                      <label className="block px-1 text-gray-700">
                        No of guests
                      </label>
                      <input
                        type="number"
                        value={guestNumber}
                        onChange={handleGuestNumberChange}
                        placeholder="Add guests"
                        min="0"
                        className="mt-1 px-2 block w-full py-2 bg-white rounded-md sm:text-sm border-b border-transparent focus:border-gray-300 focus:outline-none"
                      />
                      {guestError && (
                        <p className="text-gray-500 text-xs mt-1">
                          {guestError}
                        </p>
                      )}
                    </div>

                    <div className="flex-1 w-full lg:min-w-[100px] border-b lg:border-r-2 lg:border-b-0">
                      <label className="block px-1 text-gray-700">
                        Email
                      </label>
                      <input
                        type="email"
                        onChange={handleEmailChange}
                        placeholder="Enter your Email Address"
                        className="mt-1 px-1 block w-full py-2 bg-white rounded-md sm:text-sm border-b border-transparent focus:border-gray-300 focus:outline-none"
                      />
                    </div>

                    <div className="flex-1 w-full lg:min-w-[100px] border-b lg:border-r-2 lg:border-b-0">
                      <label className="block text-gray-700 px-1">Mobile</label>
                      <PhoneInput
                        placeholder="Enter your mobile number"
                        value={phone}
                        onChange={handlePhoneChange}
                        className="mt-1 px-2 block w-full py-2 bg-white rounded-md sm:text-sm border-b border-transparent focus:border-gray-300 focus:outline-none"
                      />
                      {phoneeError && (
                        <p className="text-gray-500 text-xs mt-1">
                          {phoneeError}
                        </p>
                      )}
                    </div>

                    <div className="flex items-center text-lg px-2 font-semibold text-[#000000] w-full lg:w-auto justify-center lg:justify-start">
                      {calculatedPrice} AED
                    </div>
                  </div>

                  <div className="px-4 mb-5 lg:mb-0 w-full lg:w-auto flex justify-center lg:justify-start mt-4 lg:mt-0">
                    <button
                      type="submit"
                      disabled={
                        guestNumber <= 0 || isNaN(guestNumber) || !phone
                      }
                      className={`bg-[#009BDE] text-white lg:py-5 lg:rounded-r-full py-2 px-4 rounded w-full lg:w-auto ${
                        guestNumber <= 0 || isNaN(guestNumber) || !phone
                          ? "bg-gray-300 text-gray-600 cursor-not-allowed"
                          : "bg-[#009BDE] text-white"
                      }`}
                    >
                      Book Now
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="CruisesTitleSection p-12">
          <div className="CruisesTitleDescriptiveSection">
            <div className="CruisesTitleBookContainer">
              <div className="flex w-full min-h-[450px] h-[450px] lg:h-[510px] lg:min-h-[510px]">
                {Object.keys(SafariData).length > 0 && (
                  <>
                    <img
                      className="w-full object-cover rounded-lg"
                      src={SafariData?.imageUrl[2]}
                      alt="highlight"
                    />
                  </>
                )}
              </div>
            </div>
            <div className="md:CruisesTitleDescriptionContainer">
              <div className="flex flex-col w-full h-full">
                <div className="flex flex-1">
                  <div className="max-w-2xl mx-auto p-2">
                    <h2 className="md:text-2xl font-bold mb-4 mt-3 md:mt-1">
                      Highlights
                    </h2>
                    <ul className="space-y-2">
                      {Object.keys(SafariData).length > 0 &&
                        SafariData.highlights.map((highlight, index) => (
                          <li key={index} className="flex items-start py-2">
                            <span className="text-yellow-500 min-w-8 min-h-8 2xl:text-xl text-lg mr-2">
                              <img
                                className="-mt-1 mr-2"
                                src={Star}
                                alt="star"
                              />
                            </span>
                            <p className="text-gray-700">{highlight}</p>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="CruisesTitleSectionn p-12">
          <div className="CruisesTitleDescriptiveSection">
            <div className="md:CruisesTitleDescriptionContainer">
              <div className="flex flex-col w-full h-full">
                <div className="flex flex-1">
                  <div className="max-w-2xl mx-auto p-2">
                    <h2 className="md:text-2xl font-bold mb-4 mt-3 md:mt-1">
                      Safari Details
                    </h2>
                    <p className="flex w-full text-sm md:text-base flex-wrap mb-4 md:mb-1">
                      Embark on an exhilarating journey through Dubai’s majestic
                      deserts with our exclusive safari. Ideal for groups and
                      families, our safari offers the perfect blend of adventure
                      and comfort. Traverse the towering dunes in a luxurious
                      4x4, witness breathtaking sunsets, and immerse yourself in
                      the rich cultural heritage of the desert. With expert
                      guides, modern amenities, and thrilling activities, your
                      safari promises to be an unforgettable experience.
                      Discover the true essence of Dubai’s desert landscape with
                      our ultimate safari adventure.
                    </p>

                    <ul className="space-y-2 mt-5 lg:mt-10">
                      {SafariData?.activities
                        ?.slice(0, 6)
                        .map((highlight, index) => (
                          <li key={index} className="flex items-start py-1">
                            <span className="text-yellow-500 min-w-8 min-h-8 2xl:text-xl text-lg mr-2">
                              <img
                                className="-mt-1 mr-2"
                                src={Star}
                                alt="star"
                              />
                            </span>
                            <p className="text-gray-700">{highlight}</p>
                          </li>
                        ))}
                    </ul>
                    {/* <ul className="space-y-2 mt-6">
                      {dinner.map((dinner, index) => (
                        <li key={index} className="flex items-start py-1">
                          <span className="text-yellow-500 2xl:text-2xl text-xl mr-2">
                            ★
                          </span>
                          <p className="text-gray-700">{dinner}</p>
                        </li>
                      ))}
                    </ul> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="CruisesTitleBookContainer">
              <div className="flex w-full min-h-[450px] h-[450px] lg:h-[510px] lg:min-h-[510px]">
                {Object.keys(SafariData).length > 0 && (
                  <>
                    <img
                      className="w-full rounded-lg object-cover"
                      src={SafariData?.imageUrl[3]}
                      alt="highlight"
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        {Object.keys(SafariData).length > 0 &&
          (SafariData.camp ? (
            <>
              <div className="image-collage">
                <div className="holder1">
                  <div className="structure1">
                    {Object.keys(SafariData).length > 0 && (
                      <>
                        <img
                          src={SafariData?.campImages[0]}
                          alt="pic"
                          className="pic-horizontal"
                        />
                      </>
                    )}
                  </div>
                  <div className="structure2">
                    {Object.keys(SafariData).length > 0 && (
                      <>
                        <img
                          src={SafariData.campImages[1]}
                          alt="pic"
                          className="p"
                        />
                      </>
                    )}
                    {Object.keys(SafariData).length > 0 && (
                      <>
                        <img
                          src={SafariData.campImages[2]}
                          alt="pic"
                          className="pi"
                        />
                      </>
                    )}
                  </div>
                </div>

                <div className="holder2">
                  <div className="structure3">
                    {Object.keys(SafariData).length > 0 && (
                      <>
                        <img
                          src={SafariData.campImages[3]}
                          alt="pic"
                          className="picture"
                        />
                      </>
                    )}
                  </div>
                  <div className="structure4">
                    {Object.keys(SafariData).length > 0 && (
                      <>
                        <img
                          src={SafariData.campImages[4]}
                          alt="pic"
                          className="pp"
                        />
                      </>
                    )}
                    {Object.keys(SafariData).length > 0 && (
                      <>
                        <img
                          src={SafariData.campImages[5]}
                          alt="pic"
                          className="pip"
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>

              <div className="dinner-cruise">
                <div className="dinner-cruise-content">
                  <h2>
                    Safari Serenity: Immerse Yourself in Nature at Our Luxury
                    Safari Camps
                  </h2>
                  <p>{SafariData.campDetails}</p>
                  <ul className="space-y-2">
                    {Object.keys(SafariData).length > 0 &&
                      SafariData.campactivities
                        .slice(0, 6)
                        .map((highlight, index) => (
                          <li key={index} className="flex items-start py-2">
                            <span className="text-yellow-500 min-w-8 min-h-8 2xl:text-xl text-lg mr-2">
                              <img
                                className="-mt-1 mr-2"
                                src={Star}
                                alt="star"
                              />
                            </span>
                            <p className="text-gray-700">{highlight}</p>
                          </li>
                        ))}
                  </ul>
                  {/* <div className="rating">3.5</div> */}
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="CruisesTitleSection p-12">
                <div className="CruisesTitleDescriptiveSection">
                  <div className="CruisesTitleBookContainer">
                    <div className="flex w-full min-h-[450px] h-[450px] lg:h-[510px] lg:min-h-[510px]">
                      {Object.keys(SafariData).length > 0 && (
                        <>
                          <img
                            className="w-full object-cover rounded-lg"
                            src={SafariData?.imageUrl[4]}
                            alt="highlight"
                          />
                        </>
                      )}
                    </div>
                  </div>
                  <div className="md:CruisesTitleDescriptionContainer">
                    <div className="flex flex-col w-full h-full">
                      <div className="flex flex-1">
                        <div className="max-w-2xl mx-auto p-2">
                          <h2 className="md:text-2xl font-bold mb-4 mt-3 md:mt-1">
                            Look Ahead to...
                          </h2>
                          <p className="flex w-full text-sm md:text-base flex-wrap mb-4 md:mb-1">
                            {SafariData.safariBrief}
                          </p>
                          <ul className="space-y-2">
                            {Object.keys(SafariData).length > 0 &&
                              SafariData.safariBreifBullets
                                .slice(0, 6)
                                .map((highlight, index) => (
                                  <li
                                    key={index}
                                    className="flex items-start py-2"
                                  >
                                    <span className="text-yellow-500 min-w-8 min-h-8 2xl:text-xl text-lg mr-2">
                                      <img
                                        className="-mt-1 mr-2"
                                        src={Star}
                                        alt="star"
                                      />
                                    </span>
                                    <p className="text-gray-700">{highlight}</p>
                                  </li>
                                ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ))}

        <div className="MoreDetailsSection">
          <div className="card-container">
            <p className="px-3">Additional Information</p>
            <div className="text-sm px-3 md:text-base flex w-full">
              Get excited! We are thrilled to offer you the best values for your
              travel journey, ensuring your trip is memorable and filled with
              unforgettable moments.
            </div>
            <div className="card-hold">
              <div className="card">
                <p className="flex bg-[#189E6E] rounded-t-md text-white max-h-[48px] py-3 items-center">
                  <img src={check} alt="check" className="px-2" />
                  Included
                </p>
                <div className="flex flex-1 flex-col text-xs md:text-sm py-3">
                  <ul className="">
                    {SafariData?.included?.slice(0, 7).map((item, idx) => (
                      <li key={idx}>
                        <span className="px-2">•</span>
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <div className="card">
                <p className="flex bg-[#E84A4A] rounded-t-md text-white max-h-[48px] py-3 items-center">
                  <img src={notcheck} alt="check" className="px-2" />
                  Excluded
                </p>{" "}
                <div className="features text-xs md:text-sm py-3">
                  <ul>
                    {SafariData?.excluded?.map((item, idx) => (
                      <li key={idx}>
                        <span className="px-2">•</span>
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <div className="card">
                <p className="flex bg-[#E2A632] rounded-t-md text-white max-h-[48px] py-3 items-center">
                  <img src={info} alt="check" className="px-2" />
                  Vital Information
                </p>{" "}
                <div className="features text-xs md:text-sm py-3">
                  <ul>
                    {SafariData?.vitalInfo?.map((item, idx) => (
                      <li key={idx}>
                        <span className="px-2">•</span>
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="ActivityMapsection">
          <div className="MapContainer">
            {SafariData?.location && (
              <>
                <MapComponent location={SafariData.location} />
              </>
            )}
          </div>
        </div> */}

        <div className="FAQSection">
          <SafariFAQComponent />
        </div>

        <div className="tour-title">Popular Tours</div>
        <div className="tours-details-section px-4">
          <RecomendedSlider populardata={populardata} />
        </div>
        <div className="activity-container px-2">
          <div className="tour-titl px-2 text-[#282B2C]">
            <h2>Create best experience for your trip to Dubai</h2>
          </div>
          <div className="desc-cont px-2">
            <p>
              Create the best experience for your trip to Dubai with our
              exceptional services, tailored to ensure every moment of your
              journey is unforgettable.
            </p>
          </div>
          <div className="menu-cont px-2">
            {popularActivites.map((item, index) => (
              <div
                key={index}
                className="min-w-full mb-5 md:mb-1  border-2 p-2 cursor-pointer  bg-[#FFFFFF] sm:min-w-[calc(50%-1rem)] md:min-w-[calc(33.333%-1rem)] lg:min-w-[calc(25%-1rem)] xl:min-w-[20%] rounded-lg overflow-hidden"
              >
                <Link key={index} to={`/activities`} className="CardImageOne">
                  <div
                    className="h-48 bg-normal1 rounded-md bg-cover bg-no-repeat relative overflow-hidden"
                    style={{ backgroundImage: `url(${item?.imageUrl[0]})` }}
                  >
                    <LazyLoadImage
                      alt={"cardimage"}
                      effect="blur"
                      src={item?.imageUrl[0]}
                      className="hidden"
                    />
                  </div>
                  <div className="py-4 px-1">
                    <div className="flex w-full justify-between ">
                      <h3 className="md:text-lg font-semibold overflow-hidden whitespace-nowrap text-ellipsis">
                        {item.productName}
                      </h3>
                    </div>
                    <div className="flex items-center text-sm overflow-hidden whitespace-nowrap text-ellipsis">
                      <span className="text-yellow-500">&#9733;</span>
                      <span className="ml-2 pr-3">{item.ratings}(21.1k)</span>
                    </div>
                    <p className="mt-2 text-xs md:text-sm text-[#2D2D2D]">
                      Starting From <b>{item.price}</b> AED
                    </p>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SafariDetails;
