import {
  BestInCurseImg2,
  BestInCurseImg3,
  BestInCurseImg4,
  BestInCurseImg5,
} from "./Images/Images";

export const yachts = [
  {
    productName: "33 Ft Luxury Yacht Dubai",
    price: 400,
    type: "yachts",
    currency: "AED",
    source: "yachts",
    ratings: "",
    imageUrl: [
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720520779/WhatsApp_Image_2022-07-18_at_8.45.52_PM_gqprgz.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720520765/WhatsApp_Image_2022-07-18_at_8.45.54_PM_jnk2d5.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720520738/WhatsApp_Image_2022-07-18_at_8.45.53_PM_lwrod7.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720520762/WhatsApp_Image_2022-07-18_at_8.45.54_PM_1_jb5i19.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720520736/WhatsApp_Image_2022-07-18_at_8.45.52_PM_1_tlvkov.jpg",
    ],
    location: {
      lat: 25.276987,
      lng: 55.296249,
    },
    overview: `Experience luxury with our 36 ft yacht rental in Dubai, a stunning vessel ideal for private cruising around the city's iconic sights. This yacht, available at Arabian Safari Dubai, blends elegance and performance, allowing you to explore the Gulf Sea's blue waters in style. Its sleek design and robust build ensure a lasting impression, while the spacious deck is perfect for fishing, sunbathing, and leisure activities. Inside, the cozy cabin offers a peaceful retreat. Whether you’re into water sports like jet skiing or prefer a tranquil trip, our 36 ft yacht promises a remarkable aquatic adventure filled with unforgettable moments. `,
    highlights: [
      {
        Duration: "4 hours",
      },
      {
        Desciption:
          "Luxury Yacht Rental Dubai features bulbous exterior lines. Enjoy diverse water activities with high-class services to escape on the Arabian Gulf Sea.",
      },
      {
        Activities:
          "Enjoy yachting trips that take you to iconic locations like the Marina Canal, the Lagoon, Dubai Bay, the Burj al Arab, Atlantis the Palm, and around the Palm. However, it can be customized according to the time and willingness of tourists.",
      },
      {
        Cuisine:
          "You can enjoy the welcome drinks. While the BBQ and additional dinner services can be enjoyed on the additional charges. You can also enjoy the special delicate steak while floating through attractive waters on a luxury yacht rental.",
      },
      {
        Service:
          "Avail convenient pick-up and drop-off services in Dubai Upon request.",
      },
      {
        Safety: "Covid-19 protocol is being followed onboard",
      },
      {
        Location: "Dubai Marina",
      },
      {
        OperationalDays: "Daily, subject to availability of the yacht",
      },
    ],
    Activities: [],
    included: [
      "Soft drinks (unlimited Coke and Sprite), water, ice",
      "Paper napkins, disposable plates and cups, cutlery",
      "Fresh towels, E–Grill (upon request)",
      "Music stereo system (via Bluetooth or AUX), AC",
      "Fishing equipment and water sports equipment (upon request)",
      "Jacuzzi on deck",
    ],
    excluded: [
      "Jet skiing",
      "Yacht Catering",
      "Yacht Decorations",
      "Photography",
    ],
    vitalInfo: [
      "The dress code for yacht rental is casual. Personalized clothing is acceptable, with a preference for cotton attire in normal weather and warmer clothes during winter. Don’t forget your beachwear if you plan to swim.",
      "Carry your Passport and ID Card as mandated by the Dubai Coast Guard.",
      "Dubai Desert Safari reserves the right to postpone or cancel yacht charter trips due to factors beyond our control, such as equipment issues, government policies, weather conditions, or force majeure.",
      "In the event of a trip cancellation by us due to uncontrollable circumstances you will receive a refund. Alternatively, you can choose to reschedule the trip to another day.",
    ],
    duration: "4 hours",
    groupSize: "Unlimited",
    languages: "English",
    cancellation: "0 days",
  },
  {
    productName: "41 Ft Luxury Yacht Dubai",
    price: 400,
    currency: "AED",
    ratings: "",
    type: "yachts",
    source: "yachts",
    imageUrl: [
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720520868/WhatsApp_Image_2022-06-08_at_8.28.55_PM_1_psbt4f.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720520860/WhatsApp_Image_2022-06-08_at_8.28.54_PM_su34yp.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720520877/WhatsApp_Image_2022-06-08_at_8.28.55_PM_ncvcyv.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720520872/WhatsApp_Image_2022-06-08_at_8.28.54_PM_1_zhrvdc.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720520867/WhatsApp_Image_2022-06-08_at_8.28.53_PM_1_elywef.jpg",
    ],
    location: {
      lat: 25.276987,
      lng: 55.296249,
    },
    overview:
      "Experience luxury with our 41 ft yacht rental in Dubai, a stunning vessel ideal for private cruising around the city's iconic sights. This yacht, available at Arabian Safari Dubai, blends elegance and performance, allowing you to explore the Gulf Sea's blue waters in style. Its sleek design and robust build ensure a lasting impression, while the spacious deck is perfect for fishing, sunbathing, and leisure activities. Inside, the cozy cabin offers a peaceful retreat. Whether you’re into water sports like jet skiing or prefer a tranquil trip, our 42 ft yacht promises a remarkable aquatic adventure filled with unforgettable moments.",
    highlights: [
      {
        duration: "4 hours",
      },
      {
        description:
          "Luxury Yacht Rental Dubai features stylish exterior lines. Enjoy diverse water activities with high-class services on the Arabian Gulf Sea.",
      },
      {
        activities:
          "Yachting trips to iconic locations like the Marina Canal, the Lagoon, Dubai Bay, the Burj al Arab, Atlantis the Palm, and around the Palm. Customizable according to tourists' preferences.",
      },
      {
        cuisine:
          "Welcome drinks included. BBQ and additional dinner services available at extra charges. Special delicate steak available while cruising.",
      },
      {
        service:
          "Convenient pick-up and drop-off services in Dubai upon request.",
      },
      {
        safety: "Covid-19 protocol is followed onboard.",
      },
      {
        location: "Dubai Marina",
      },
      {
        operationalDays: "Daily, subject to yacht availability.",
      },
    ],
    activities: [],
    included: [
      "Unlimited soft drinks (Coke and Sprite), water, ice",
      "Paper napkins, disposable plates and cups, cutlery",
      "Fresh towels, E–Grill (upon request)",
      "Music stereo system (via Bluetooth or AUX), AC",
      "Fishing equipment and water sports equipment (upon request)",
    ],
    excluded: [
      "Jet skiing",
      "Yacht catering",
      "Yacht decorations",
      "Photography",
    ],
    vitalInfo: [
      "Dress code: Casual, with personalized clothing preferred. Bring warmer clothes in winter and beachwear for swimming.",
      "Carry your Passport and ID Card as mandated by the Dubai Coast Guard.",
      "Trips may be postponed or canceled due to uncontrollable factors. Refund or rescheduling options available.",
    ],
    location: {
      lat: 25.276987,
      lng: 55.296249,
    },
    duration: "4 hours",
    groupSize: "15 guests",
    languages: "English",
    cancellation: "0 days",
  },
  {
    productName: "Columbus Yacht dubai",
    price: 475,
    currency: "AED",
    ratings: "",
    type: "yachts",
    source: "yachts",
    imageUrl: [
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720521174/WhatsApp_Image_2022-08-14_at_4.51.36_PM_dgrewt.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1719760812/Humrah%20Travels/columnbus_yacht/ekhbi0wuebawnadmepl1.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720521167/WhatsApp_Image_2022-08-14_at_4.51.35_PM_ejl8qq.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1719760812/Humrah%20Travels/columnbus_yacht/oymeram6zhec0vpuzshc.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720521212/WhatsApp_Image_2022-08-14_at_4.51.38_PM_1_z2qavn.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1719760813/Humrah%20Travels/columnbus_yacht/axh4f1oz2dgjjpelemza.jpg",
    ],
    location: {
      lat: 25.276987,
      lng: 55.296249,
    },
    overview: `Experience luxury with our 36 ft yacht rental in Dubai, a stunning vessel ideal for private cruising around the city's iconic sights. This yacht, available at Arabian Safari Dubai, blends elegance and performance, allowing you to explore the Gulf Sea's blue waters in style. Its sleek design and robust build ensure a lasting impression, while the spacious deck is perfect for fishing, sunbathing, and leisure activities. Inside, the cozy cabin offers a peaceful retreat. Whether you’re into water sports like jet skiing or prefer a tranquil trip, our 36 ft yacht promises a remarkable aquatic adventure filled with unforgettable moments. `,
    highlights: [
      {
        Duration: "4 hours",
      },
      {
        Desciption:
          "Luxury Yacht Rental Dubai features bulbous exterior lines. Enjoy diverse water activities with high-class services to escape on the Arabian Gulf Sea.",
      },
      {
        Activities:
          "Enjoy yachting trips that take you to iconic locations like the Marina Canal, the Lagoon, Dubai Bay, the Burj al Arab, Atlantis the Palm, and around the Palm. However, it can be customized according to the time and willingness of tourists.",
      },
      {
        Cuisine:
          "You can enjoy the welcome drinks. While the BBQ and additional dinner services can be enjoyed on the additional charges. You can also enjoy the special delicate steak while floating through attractive waters on a luxury yacht rental.",
      },
      {
        Service:
          "Avail convenient pick-up and drop-off services in Dubai Upon request.",
      },
      {
        Safety: "Covid-19 protocol is being followed onboard",
      },
      {
        Location: "Dubai Marina",
      },
      {
        OperationalDays: "Daily, subject to availability of the yacht",
      },
    ],
    Activities: [],
    included: [
      "Soft drinks (unlimited Coke and Sprite), water, ice",
      "Paper napkins, disposable plates and cups, cutlery",
      "Fresh towels, E–Grill (upon request)",
      "Music stereo system (via Bluetooth or AUX), AC",
      "Fishing equipment and water sports equipment (upon request)",
      "Jacuzzi on deck",
    ],
    excluded: [
      "Jet skiing",
      "Yacht Catering",
      "Yacht Decorations",
      "Photography",
    ],
    vitalInfo: [
      "The dress code for yacht rental is casual. Personalized clothing is acceptable, with a preference for cotton attire in normal weather and warmer clothes during winter. Don’t forget your beachwear if you plan to swim.",
      "Carry your Passport and ID Card as mandated by the Dubai Coast Guard.",
      "Dubai Desert Safari reserves the right to postpone or cancel yacht charter trips due to factors beyond our control, such as equipment issues, government policies, weather conditions, or force majeure.",
      "In the event of a trip cancellation by us due to uncontrollable circumstances you will receive a refund. Alternatively, you can choose to reschedule the trip to another day.",
    ],
    location: {
      lat: 25.276987,
      lng: 55.296249,
    },
    duration: "4 hours",
    groupSize: "10 People",
    languages: "English",
    cancellation: "0 days",
  },
  {
    productName: "48 Ft Luxury Yacht Dubai",
    price: 800,
    currency: "AED",
    ratings: "",
    type: "yachts",
    source: "yachts",
    imageUrl: [
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720520845/WhatsApp_Image_2022-07-09_at_8.11.30_PM_1_jxcavp.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720520850/WhatsApp_Image_2022-07-09_at_8.11.30_PM_btzk29.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720520847/WhatsApp_Image_2022-07-09_at_8.11.33_PM_1_j1oxge.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720520843/WhatsApp_Image_2022-07-09_at_8.11.34_PM_1_zprsyi.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720520854/WhatsApp_Image_2022-07-09_at_8.11.34_PM_jm87ul.jpg",
    ],
    location: {
      lat: 25.276987,
      lng: 55.296249,
    },
    overview:
      "Welcome to an extraordinary journey aboard the 48 Ft Luxury Yacht rental in Dubai. This yacht is elegantly designed to offer a remarkable and tailored experience for you and your guests. The yacht accommodates up to 20 people with a cushioned bow sunbathing area, BBQ grills, and a swimming ladder. Perfect for sunset or evening relaxation, the spacious sun deck enhances your charter experience. Our luxury yacht rental includes 3 private cabins: 1 master, 1 double, and 1 twin room with 2 heads. Enjoy delicious cuisines from our catering services and engage in water sports like sea bob, efoil board, wake surfing, and wakeboarding. Arabian Safari Dubai offers an unparalleled luxury yachting experience in Dubai Marina.",
    highlights: [
      {
        duration: "4 hours",
      },
      {
        description:
          "Luxury Yacht Rental Dubai features stylish exterior lines. Enjoy diverse water activities with high-class services on the Arabian Gulf Sea.",
      },
      {
        activities:
          "Yachting trips to iconic locations like the Marina Canal, the Lagoon, Dubai Bay, the Burj al Arab, Atlantis the Palm, and around the Palm. Customizable according to tourists' preferences.",
      },
      {
        cuisine:
          "Welcome drinks included. BBQ and additional dinner services available at extra charges. Special delicate steak available while cruising.",
      },
      {
        service:
          "Convenient pick-up and drop-off services in Dubai upon request.",
      },
      {
        safety: "Covid-19 protocol is followed onboard.",
      },
      {
        location: "Dubai Marina",
      },
      {
        operationalDays: "Daily, subject to yacht availability.",
      },
    ],
    activities: [],
    included: [
      "Unlimited soft drinks (Coke and Sprite), water, ice",
      "Paper napkins, disposable plates and cups, cutlery",
      "Fresh towels, E–Grill (upon request)",
      "Music stereo system (via Bluetooth or AUX), AC",
      "Fishing equipment and water sports equipment (upon request)",
    ],
    excluded: [
      "Jet skiing",
      "Yacht catering",
      "Yacht decorations",
      "Photography",
    ],
    vitalInfo: [
      "Dress code: Casual, with personalized clothing preferred. Bring warmer clothes in winter and beachwear for swimming.",
      "Carry your Passport and ID Card as mandated by the Dubai Coast Guard.",
      "Trips may be postponed or canceled due to uncontrollable factors. Refund or rescheduling options available.",
    ],
    locationCoordinates: {
      lat: 25.276987,
      lng: 55.296249,
    },
    duration: "4 hours",
    groupSize: "20 People",
    languages: "English",
    cancellation: "Up to 1 day",
  },
  {
    productName: "Polina Yacht",
    price: 650,
    currency: "AED",
    ratings: "",
    type: "yachts",
    source: "yachts",
    imageUrl: [
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720521348/WhatsApp_Image_2022-09-27_at_10.35.01_AM_l0mesx.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720521266/WhatsApp_Image_2022-09-27_at_10.35.00_AM_1_m0uibq.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720521253/WhatsApp_Image_2022-09-27_at_10.34.59_AM_ythmvx.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720521255/WhatsApp_Image_2022-09-27_at_10.34.59_AM_1_xi5hfu.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720521348/WhatsApp_Image_2022-09-27_at_10.35.01_AM_l0mesx.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1719760071/Humrah%20Travels/POLINA%20%28%20COLOMBUS%202%29/uqghts3mzl8wm4hdlxlz.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1719760072/Humrah%20Travels/POLINA%20%28%20COLOMBUS%202%29/uc98zzdz3qseow5kul4k.jpg",
    ],
    location: {
      lat: 25.276987,
      lng: 55.296249,
    },
    overview:
      "Enjoy a day out or celebrate a special occasion on our 50 ft luxury yacht in Dubai, accommodating up to 18 guests. Experience the cool breeze and relaxing trip around Dubai Marina, passing by iconic landmarks like Atlantis The Palm and Palm Island. This sleek and stylish yacht features spacious cabins, a fully equipped kitchen, and entertainment options. Enjoy bottled water, snacks, and non-alcoholic drinks on board. With Arabian Safari Dubai, explore luxury yachting in style with personalized service from the captain and crew.",
    highlights: [
      {
        duration: "4 hours",
      },
      {
        description:
          "Luxury Yacht Rental Dubai features sleek exterior lines. Enjoy diverse water activities with high-class services on the Arabian Gulf Sea.",
      },
      {
        activities:
          "Yachting trips to iconic locations like the Marina Canal, the Lagoon, Dubai Bay, the Burj al Arab, Atlantis the Palm, and around the Palm. Customizable according to tourists' preferences.",
      },
      {
        cuisine:
          "Welcome drinks included. BBQ and additional dinner services available at extra charges. Special delicate steak available while cruising.",
      },
      {
        service:
          "Convenient pick-up and drop-off services in Dubai upon request.",
      },
      {
        safety: "Covid-19 protocol is followed onboard.",
      },
      {
        location: "Dubai Marina",
      },
      {
        operationalDays: "Daily, subject to yacht availability.",
      },
    ],
    activities: [],
    included: [
      "Unlimited soft drinks (Coke and Sprite), water, ice",
      "Paper napkins, disposable plates and cups, cutlery",
      "Fresh towels, E–Grill (upon request)",
      "Music stereo system (via Bluetooth or AUX), AC",
      "Fishing equipment and water sports equipment (upon request)",
    ],
    excluded: [
      "Jet skiing",
      "Yacht catering",
      "Yacht decorations",
      "Photography",
    ],
    vitalInfo: [
      "Dress code: Casual, with personalized clothing preferred. Bring warmer clothes in winter and beachwear for swimming.",
      "Carry your Passport and ID Card as mandated by the Dubai Coast Guard.",
      "Trips may be postponed or canceled due to uncontrollable factors. Refund or rescheduling options available.",
    ],
    locationCoordinates: {
      lat: 25.276987,
      lng: 55.296249,
    },
    duration: "4 hours",
    groupSize: "18 People",
    languages: "English",
    cancellation: "Up to 1 day",
  },
  {
    productName: "Azimut Luxury Yacht",
    price: 400,
    currency: "AED",
    ratings: "",
    type: "yachts",
    source: "yachts",
    imageUrl: [
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720521246/WhatsApp_Image_2022-08-14_at_4.51.43_PM_gc3p8y.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720521369/WhatsApp_Image_2022-09-27_at_10.35.03_AM_iqqeyw.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720521118/WhatsApp_Image_2022-07-27_at_2.14.47_PM_wnem2l.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720521112/WhatsApp_Image_2022-07-27_at_2.14.51_PM_ywvkga.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720521114/WhatsApp_Image_2022-07-27_at_2.14.50_PM_bmadpa.jpg",
    ],
    location: {
      lat: 25.276987,
      lng: 55.296249,
    },
    overview:
      "Board a 58 ft luxury yacht in Dubai for an extraordinary experience. Departing from Dubai Marina, explore Burj Al Arab, Atlantis Hotel, Jumeirah Beach Residence, Ain Wheel, and more. Avoid crowded boat cruises and enjoy private luxury. Catch views of royal palaces, Palm Lagoon, and Dubai Marina. The yacht features a large living area, comfortable seating, and a separate dining area, catering for up to 25 people with 3 cabins. Ideal for groups to relax together. Book now!",
    highlights: [
      {
        Duration: "4 hours",
      },
      {
        Description:
          "Luxurious yacht with modern amenities and open living spaces for an elite experience on the Arabian Gulf.",
      },
      {
        Activities:
          "Customizable trips to iconic locations like Marina Canal, Dubai Bay, Burj al Arab, Atlantis the Palm, and around the Palm.",
      },
      {
        Cuisine:
          "Welcome drinks included. Optional BBQ and dinner services available at an additional charge, featuring special steak dishes.",
      },
      {
        Service:
          "Convenient pick-up and drop-off services in Dubai available upon request.",
      },
      {
        Safety: "Covid-19 safety protocols are followed on board.",
      },
      {
        Location: "Dubai Marina",
      },
      {
        OperationalDays: "Daily, subject to yacht availability.",
      },
    ],
    activities: [],
    included: [
      "Unlimited soft drinks (Coke and Sprite), water, ice",
      "Paper napkins, disposable plates and cups, cutlery",
      "Fresh towels, E–Grill (on request)",
      "Music stereo system (via Bluetooth or AUX), AC",
      "Fishing equipment and water sports equipment (on request)",
      "Jacuzzi on deck",
    ],
    excluded: [
      "Jet skiing",
      "Yacht catering",
      "Yacht decorations",
      "Photography",
    ],
    vitalInfo: [
      "Casual dress code recommended, with cotton attire in normal weather and warmer clothes in winter. Bring beachwear if swimming.",
      "Carry your Passport and ID Card as required by the Dubai Coast Guard.",
      "Trips may be postponed or canceled due to uncontrollable factors like equipment issues, government policies, weather, or force majeure.",
      "In case of cancellation due to these factors, you will receive a refund or can reschedule the trip.",
    ],
    duration: "4 hours",
    groupSize: "Up to 25 People",
    languages: "English",
    cancellation: "Up to 1 day",
  },
  {
    productName: "Luxury Yacht Dubai",
    price: 400,
    currency: "AED",
    ratings: "",
    type: "yachts",
    source: "yachts",
    imageUrl: [
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720521383/WhatsApp_Image_2022-08-14_at_4.56.28_PM_vswogd.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720521379/WhatsApp_Image_2022-08-14_at_4.56.36_PM_1_qapk18.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720521374/WhatsApp_Image_2022-08-14_at_4.56.27_PM_uvor72.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720521383/WhatsApp_Image_2022-08-14_at_4.56.28_PM_vswogd.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720521217/WhatsApp_Image_2022-08-14_at_4.51.43_PM_1_ree1fa.jpg",
    ],
    location: {
      lat: 25.276987,
      lng: 55.296249,
    },
    overview:
      "Experience a luxurious cruise on our 80 ft yacht in Dubai. This beautifully designed vessel offers the comfort of a 5-star dinner cruise and is perfect for family and friends. Relax in the spacious flybridge entertainment area, enjoy fishing, and take in the stunning views of Dubai. Book now for an unforgettable journey!",
    highlights: [
      {
        Duration: "4 hours",
      },
      {
        Description:
          "Sleek design with luxurious amenities, perfect for high-class water activities on the Arabian Gulf Sea.",
      },
      {
        Activities:
          "Customizable yachting trips to iconic spots such as Marina Canal, Dubai Bay, Burj al Arab, Atlantis the Palm, and around the Palm.",
      },
      {
        Cuisine:
          "Welcome drinks included, with optional BBQ and dinner services for an additional charge. Special steak dishes available.",
      },
      {
        Service:
          "Convenient pick-up and drop-off services in Dubai available upon request.",
      },
      {
        Safety: "Covid-19 protocol is being followed onboard.",
      },
      {
        Location: "Dubai Marina",
      },
      {
        OperationalDays: "Daily, subject to availability of the yacht.",
      },
    ],
    Activities: [],
    included: [
      "Soft drinks (unlimited Coke and Sprite), water, ice",
      "Paper napkins, disposable plates and cups, cutlery",
      "Fresh towels, E–Grill (upon request)",
      "Music stereo system (via Bluetooth or AUX), AC",
      "Fishing equipment and water sports equipment (upon request)",
      "Jacuzzi on deck",
    ],
    excluded: [
      "Jet skiing",
      "Yacht Catering",
      "Yacht Decorations",
      "Photography",
    ],
    vitalInfo: [
      "Casual dress code recommended, with cotton attire in normal weather and warmer clothes during winter. Beachwear needed if swimming.",
      "Carry your Passport and ID Card as mandated by the Dubai Coast Guard.",
      "Trips may be postponed or canceled due to uncontrollable factors such as equipment issues, government policies, weather conditions, or force majeure.",
      "In the event of a cancellation due to uncontrollable circumstances, you will receive a refund or can reschedule the trip.",
    ],
    location: {
      lat: 25.276987,
      lng: 55.296249,
    },
    duration: "4 hours",
    groupSize: "Up to 35 People",
    languages: "English",
    cancellation: "1 day",
  },
];

export const cruises = [
  {
    productName: "Dhow Cruise Al Seef",
    price: 49,
    currency: "AED",
    ratings: "",
    imageUrl: [
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720521444/josiah-weiss-tthKs4A7XMY-unsplash_zvrj2v.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720521428/jamie-morrison-dMqmsZ8u-D0-unsplash_d4lvar.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720521423/artak-petrosyan-BQ2L9n0eHwk-unsplash_hw7ipc.jpg",
    ],
    location: {
      lat: 25.276987,
      lng: 55.296249,
    },
    overview:
      "Explore Dubai Creek on a traditional dhow cruise, blending old-world charm with modern luxury. Enjoy stunning views of illuminated cityscapes, iconic landmarks, and vibrant street life. This 2-hour cruise offers a buffet-style dining experience with local and international cuisines, live entertainment, and exceptional service.",
    highlights: [
      "Cruise along Deira Creek and Bur Dubai Creek for breathtaking city views.",
      "Red carpet arrival with a welcome non-alcoholic drink.",
      "Traditional Arabian wooden dhow with two tiers: an air-conditioned lower deck and an open-air upper deck.",
      "Savor an international buffet dinner with vegetarian and non-vegetarian options.",
      "Enjoy soft drinks, juice, mineral water, tea, and coffee.",
      "Live entertainment including Tanoura dance show.",
      "Optional pick-up and drop-off services available.",
    ],
    menuImages:[

    ],
    activities: [],
    included: [
      "Red carpet welcome",
      "Non-alcoholic welcome drink",
      "Two-tiered traditional dhow cruise",
      "Two hours of cruising",
      "Air-conditioned lower deck",
      "Open-air upper deck",
      "International buffet dinner",
      "Soft drinks, juice, mineral water, tea, and coffee",
    ],
    excluded: ["Gratuities (optional)", "Additional services"],
    vitalInfo: [
      "Duration: 2 hours (multiple time slots available)",
      "Operating days: Daily",
      "Children under 4 years enter free",
      "Minimum participants: 2",
      "Cruising time: 8:30 pm to 10:30 pm",
      "Boarding time: 8:00 pm",
      "Booking confirmation at the time of reservation",
      "Cancellation: At least 1 day before the trip",
    ],
    safetyMeasure:
      "Experienced captains and crew, navigation equipment, fire extinguishers, first aid kits, life jackets.",
    voyage:
      "Embark on a 2-hour journey along Dubai Creek, witnessing the city's illuminated skyline and cultural landmarks. The cruise passes iconic spots like the Gold Souk, Spice Souk, Hyatt Regency, Al Maktoum Bridge, and vibrant street markets. Enjoy the mesmerizing contrast between the old-world charm of traditional dhows and the modern skyline. Capture memorable photos and immerse yourself in Dubai's rich cultural tapestry.",
    dinnerOptions:
      "Experience a 3-star buffet-style dining adventure on the Dubai Deira Creek Cruise, offering a variety of international cuisines including aromatic Arabian dishes prepared by experienced chefs. The buffet caters to all palates with both vegetarian and non-vegetarian options. Enjoy a delightful culinary experience in an elegant ambiance. Hassle-free transportation from multiple pick-up locations in central Dubai is available, ensuring a seamless start to your evening.",
    duration: "2 hours",
    groupSize: "Unlimited",
    languages: "English, Arabic, Hindi",
    cancellation: "Up to 1 day",
  },
  {
    productName: "Floating Restaurant Dinner Cruise",
    price: 129,
    currency: "AED",
    ratings: "",
    imageUrl: [
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720521455/anthony-ketland-rbzdPhwMXq8-unsplash_junr2g.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720521451/anthony-ketland-ZnyIunCZSso-unsplash_zxpvax.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720521456/alonso-reyes-haZNHEV2WXQ-unsplash_oiflou.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720521460/alonso-reyes-LWFdBz4d6nE-unsplash_dgv7xp.jpg",
    ],
    location: {
      lat: 25.0804,
      lng: 55.1403,
    },
    overview:
      "Experience an exquisite afternoon on the 5-Star Mega Yacht Brunch Cruise in Dubai Marina. Indulge in luxury and breathtaking views of iconic landmarks while enjoying a lavish brunch onboard. Perfect for special occasions, corporate events, or simply exploring Dubai's beauty, this cruise promises an unforgettable and sophisticated experience.",
    highlights: [
      "Cruising for 2 hours and 30 minutes",
      "Panoramic views starting from Dubai Marina, passing Jumeirah Beach Residences, Bluewaters Island, and Atlantis The Palm",
      "Welcome drinks, tea, coffee, and mineral water",
      "5-star quality International Buffet Multi-dishes by Hotel The Westin Dubai Mina Seyahi",
      "Live cooking stations for barbeque, sushi, and salads",
      "Impressive Entertainment Decks – The chill lounge, children cinema, Rooftop Walkway, Dining Deck, Sun Deck",
      "Access to fully air-conditioned lower deck and open-air upper deck",
      "An indoor swimming pool with a Jacuzzi",
      "Entertainment Shows (except during Ramadan)",
      "Bar (alcoholic beverages available for an extra charge)",
      "Well-sanitized washroom facilities for men and women",
      "Smoking area allowed on the upper deck only",
    ],
    landmarks: [
      "Dubai Skyline",
      "JBR",
      "Blue Waters Island",
      "Palm Islands",
      "Dubai Eye",
    ],
    activities: [
      "Live DJ",
      "Swimming in the indoor pool with Jacuzzi",
      "Live music and dancing",
      "Background Music in Arabic, Hindu, and English",
    ],
    included: [
      "Welcome drinks, tea, coffee, and mineral water",
      "2 hours and 30 minutes cruise along Dubai Marina and landmarks",
      "5-star quality International Buffet Multi-dishes by Hotel The Westin Dubai Mina Seyahi",
      "Live cooking stations for barbeque, sushi, and salads",
      "Impressive Entertainment Decks",
      "Entertainment Shows (except during Ramadan)",
      "Bar (alcoholic beverages available for an extra charge)",
      "Well-sanitized washroom facilities for men and women",
      "Smoking area on the upper deck only",
    ],
    excluded: [
      "Transportation (optional/additional cost)",
      "Gratuities (optional)",
    ],
    vitalInfo: [
      "Duration: 2 hours and 30 minutes",
      "Operating days: Daily",
      "Suitable for all age groups",
      "Location: Dubai Marina",
      "Dress code: Casual in summer, light jacket in winter",
      "Licensed professional crew",
      "Safety equipment provided: First-aid kits, navigation equipment, life jackets",
      "Clean and well-maintained cruises",
      "Respect local customs and guidelines",
      "Cancellation policy in effect",
    ],
    safetyMeasure:
      "Experienced captains and crew, navigation equipment, fire extinguishers, first aid kits, life jackets.",
    voyage:
      "Embark on a 2 hour and 30 minutes cruise from Dubai Marina, taking in panoramic views of Jumeirah Beach Residences, Bluewaters Island, and Atlantis The Palm. Enjoy a lavish brunch, live cooking stations, and an indoor swimming pool with a Jacuzzi while cruising through the waters of Dubai.",
    dinnerOptions:
      "Enjoy a 5-star International Buffet Multi-dishes by Hotel The Westin Dubai Mina Seyahi, featuring live cooking stations for barbeque, sushi, and salads. Relish unlimited soft drinks, tea, coffee, refreshing juice, and mineral water. Experience exceptional service and a delightful dining atmosphere onboard.",
    duration: "2 hours and 30 minutes",
    groupSize: "Unlimited",
    languages: "English, Arabic, Hindu",
    cancellation: "Up to 1 day",
  },
  {
    productName: "Royal Dinner Marina Dhow Cruise",
    price: 119,
    currency: "AED",
    ratings: "",
    imageUrl: [BestInCurseImg2],
    location: {
      lat: 25.076,
      lng: 55.141,
    },
    overview:
      "Embark on a luxurious 2-hour Dhow Cruise in Dubai Marina, where you'll enjoy a delightful evening surrounded by stunning skyscrapers and beautiful canal views. Indulge in an International Buffet Dinner, sip on unlimited soft beverages, and be entertained by music and shows in a captivating ambiance.",
    highlights: [
      "2 hours cruising through Dubai Marina",
      "Welcome drinks",
      "International Buffet Dinner",
      "Unlimited soft beverages",
      "Music & Entertainment shows",
      "Beautiful ambiance",
      "Separate amenities for men & women",
    ],
    landmarks: [
      "Marina skyscrapers",
      "Marina Canal including Marina Mall Pier 7",
      "Twisted Tower inside the Canal and outside Dubai Eye",
      "Jumeirah Beach",
    ],
    activities: [],
    included: [
      "2-hours cruising",
      "Welcome drinks",
      "International Buffet Dinner",
      "Unlimited soft beverages",
      "Music & Entertainment shows",
      "Separate amenities for men & women",
    ],
    excluded: [
      "Transportation (optional/additional cost)",
      "Gratuities (optional)",
    ],
    vitalInfo: [
      "Duration: 2 hours",
      "Operating days: Daily",
      "Suitable for all age groups",
      "Location: Dubai Marina",
      "Boarding Time: 8:00 PM",
      "Cruise Timing: 8:30 PM – 10:30 PM",
      "Dress code: Casual",
      "Licensed professional crew",
      "Safety equipment provided: Life jackets",
      "Clean and well-maintained dhow",
      "Respect local customs and guidelines",
      "Cancellation policy in effect",
    ],
    safetyMeasure:
      "Licensed professional crew, life jackets, clean and well-maintained dhow, respect for local customs and guidelines.",
    voyage:
      "Embark on a luxurious 2-hour Dhow Cruise in Dubai Marina, starting from Marina Mall Pier 7. Enjoy panoramic views of Marina skyscrapers, Twisted Tower, Dubai Eye, and Jumeirah Beach while dining on an International Buffet Dinner and sipping on unlimited soft beverages.",
    dinnerOptions:
      "Experience an International Buffet Dinner with a variety of dishes while cruising through Dubai Marina. Enjoy unlimited soft beverages and entertainment shows in a beautiful ambiance.",
    duration: "2 hours",
    groupSize: "Unlimited",
    languages: "English, Arabic",
    cancellation: "Up to 1 day",
  },
  {
    productName: "Premium Dhow Cruise Deira",
    price: 59,
    currency: "AED",
    ratings: "",
    imageUrl: [BestInCurseImg2],
    location: {
      lat: 25.0865,
      lng: 55.1465,
    },
    overview:
      "Experience an exquisite evening aboard the 5 Star Mega Dhow Dinner Cruise in Dubai Marina, offering spectacular views of the Marina skyline and iconic landmarks.",
    highlights: [
      "Luxurious dhow cruise experience in Dubai Marina",
      "Panoramic views of Marina skyscrapers and iconic landmarks",
      "Delicious international buffet dinner",
      "Live music and entertainment",
      "Relaxing ambiance onboard",
    ],
    landmarks: [
      "Marina skyscrapers",
      "Marina Canal including Marina Mall Pier 7",
      "Twisted Tower inside the Canal and outside Dubai Eye",
      "Jumeirah Beach and more",
    ],
    activities: [
      "2-hours cruising",
      "Welcome drinks",
      "International buffet dinner",
      "Unlimited soft beverages",
      "Live music & entertainment shows",
    ],
    included: [
      "2-hours cruising",
      "Welcome drinks",
      "International buffet dinner",
      "Unlimited soft beverages",
      "Music & Entertainment shows",
      "Beautiful ambiance",
      "Separate amenities for men & women",
    ],
    excluded: [],
    vitalInfo: ["Boarding Time: 8:00 PM", "Timing: 8:30 PM – 10:30 PM"],
    safetyMeasure:
      "Details not provided, please contact the provider for safety measures.",
    voyage:
      "Embark on a luxurious evening aboard the 5 Star Mega Dhow Dinner Cruise in Dubai Marina. Enjoy delectable dining, live entertainment, and breathtaking views of Dubai's iconic landmarks.",
    dinnerOptions: "",
    duration: "",
    groupSize: "Unlimited",
    languages: "",
    cancellation: "Cancellation up to 1 day before the cruise.",
  },
  // New Uploaded By Rohit
  {
    productName: "Canal Catamaran Dinner Cruise",
    price: 49,
    currency: "AED",
    ratings: "",
    imageUrl: [
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720963071/Humrah%20Travels%20Version%202/Cruises/Canal%20Catamaran%20Dinner%20Cruise/rq9lpvzo6zoq3ldh5agd.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720963071/Humrah%20Travels%20Version%202/Cruises/Canal%20Catamaran%20Dinner%20Cruise/jyrbhkhlksgcermr4kpf.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720963070/Humrah%20Travels%20Version%202/Cruises/Canal%20Catamaran%20Dinner%20Cruise/im8plfcuxxa2x6vh3m09.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720963071/Humrah%20Travels%20Version%202/Cruises/Canal%20Catamaran%20Dinner%20Cruise/rq9lpvzo6zoq3ldh5agd.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720963070/Humrah%20Travels%20Version%202/Cruises/Canal%20Catamaran%20Dinner%20Cruise/xwl19q6knex4egmammry.jpg",
    ],
    location: {
      lat: 25.276987,
      lng: 55.296249,
    },
    overview:
      "Explore Dubai Creek on a traditional dhow cruise, blending old-world charm with modern luxury. Enjoy stunning views of illuminated cityscapes, iconic landmarks, and vibrant street life. This 2-hour cruise offers a buffet-style dining experience with local and international cuisines, live entertainment, and exceptional service.",
    highlights: [
      "Cruise along Deira Creek and Bur Dubai Creek for breathtaking city views.",
      "Red carpet arrival with a welcome non-alcoholic drink.",
      "Traditional Arabian wooden dhow with two tiers: an air-conditioned lower deck and an open-air upper deck.",
      "Savor an international buffet dinner with vegetarian and non-vegetarian options.",
      "Enjoy soft drinks, juice, mineral water, tea, and coffee.",
      "Live entertainment including Tanoura dance show.",
      "Optional pick-up and drop-off services available.",
    ],
    activities: [],
    included: [
      "Red carpet welcome",
      "Non-alcoholic welcome drink",
      "Two-tiered traditional dhow cruise",
      "Two hours of cruising",
      "Air-conditioned lower deck",
      "Open-air upper deck",
      "International buffet dinner",
      "Soft drinks, juice, mineral water, tea, and coffee",
    ],
    excluded: ["Gratuities (optional)", "Additional services"],
    vitalInfo: [
      "Duration: 2 hours (multiple time slots available)",
      "Operating days: Daily",
      "Children under 4 years enter free",
      "Minimum participants: 2",
      "Cruising time: 8:30 pm to 10:30 pm",
      "Boarding time: 8:00 pm",
      "Booking confirmation at the time of reservation",
      "Cancellation: At least 1 day before the trip",
    ],
    safetyMeasure:
      "Experienced captains and crew, navigation equipment, fire extinguishers, first aid kits, life jackets.",
    voyage:
      "Embark on a 2-hour journey along Dubai Creek, witnessing the city's illuminated skyline and cultural landmarks. The cruise passes iconic spots like the Gold Souk, Spice Souk, Hyatt Regency, Al Maktoum Bridge, and vibrant street markets. Enjoy the mesmerizing contrast between the old-world charm of traditional dhows and the modern skyline. Capture memorable photos and immerse yourself in Dubai's rich cultural tapestry.",
    dinnerOptions:
      "Experience a 3-star buffet-style dining adventure on the Dubai Deira Creek Cruise, offering a variety of international cuisines including aromatic Arabian dishes prepared by experienced chefs. The buffet caters to all palates with both vegetarian and non-vegetarian options. Enjoy a delightful culinary experience in an elegant ambiance. Hassle-free transportation from multiple pick-up locations in central Dubai is available, ensuring a seamless start to your evening.",
    duration: "2 hours",
    groupSize: "Unlimited",
    languages: "English, Arabic, Hindi",
    cancellation: "Up to 1 day",
  },
];

export const safaris = [
  {
    productName: "Evening Desert Safari Dubai",
    price: 100,
    currency: "AED",
    ratings: "4.6",
    camp: true,
    imageUrl: [
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720454668/daniel-jankovic-8CUChfnEovI-unsplash_xvd3i8.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720454659/daniel-jankovic-mzBtcVkoP6I-unsplash_olufd0.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720454649/jakub-david-n1zpE7dsNyA-unsplash_tcotcu.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720456021/alex-azabache-XT3Kk0GelbE-unsplash_wprt7t.jpg",
    ],
    campImages: [
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721239960/Humrah%20Travels%20Version%202/Safaris/Lama%20Premium%20Camp/lqztb4vw5rhjybkzqaxs.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721241454/Humrah%20Travels%20Version%202/Safaris/Noble%20Camp/a1dypuhtahlv0ntz5ont.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721241455/Humrah%20Travels%20Version%202/Safaris/Noble%20Camp/rrn33eqddiakg7cubwt9.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721241454/Humrah%20Travels%20Version%202/Safaris/Noble%20Camp/m9acsbukf3swfbjplqsm.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721241457/Humrah%20Travels%20Version%202/Safaris/Noble%20Camp/v64nb7kyjouxdbbxgpbr.jpg",
      'https://res.cloudinary.com/dj9xbypxz/image/upload/v1721241457/Humrah%20Travels%20Version%202/Safaris/Noble%20Camp/gjxlvo4il9wqwf0laejy.jpg'
    ],
    location: {
      lat: 24.9056,
      lng: 55.1864,
    },
    campDetails: `As the sun sets over the Arabian desert, immerse yourself in an evening filled with comfort, fun, and excitement.
     Travelers from around the globe choose us for our comprehensive services and budget-friendly packages.
      Located just minutes from Dubai, our desert camp offers breathtaking views and thrilling activities.
      From dune bashing to a traditional Bedouin camp experience, we manage every detail to ensure your evening is unforgettable.
      Join us for an unparalleled adventure in the heart of the desert.
      Our priority is to provide an exceptional experience, making your time with us both entertaining and hassle-free. 
      Our evening desert safari camp is designed to exceed your expectations and create lasting memories.`,
    campactivities: [
      "Belly Dance",
      "Tanoura Dance",
      "Fire Show",
      "Music (Arabian, English & Hindi Songs)",
      "Dune Bashing",
      "Camel Ride",
      "Sandboarding",
      "ATV/Quad Biking",
      "Henna Tattoo Painting",
    ],
    safariBrief: `Experience the ultimate adventure with our Evening Desert Safari! Dubai's vibrant evenings are best enjoyed with our safari, designed to offer comfort, fun, and excitement. 
        Customers worldwide choose us for our comprehensive services and budget-friendly approach.
        Just minutes from Dubai, the Arabian desert offers breathtaking views and thrilling dune bashing.
        We manage every aspect of your trip to ensure a memorable evening. Join us for an unforgettable tourist experience.
        Our priority is to provide an exceptional experience, making your time with us entertaining and hassle-free.
        Our evening desert safari tour is designed to fulfill your dreams.`,
    safariBreifBullets: [],
    overview:
      "Experience the ultimate evening adventure with our Evening Desert Safari in Dubai. Enjoy thrilling activities like dune bashing, camel riding, and sandboarding, followed by a delightful international buffet dinner and captivating entertainment.",
    highlights: [
      "6-hour evening desert safari in Dubai",
      "Dune bashing and desert drive in a 4x4 vehicle",
      "Camel riding and sandboarding",
      "International buffet dinner with BBQ",
      "Live entertainment including Belly Dance, Tanoura Dance, and Fire Dance",
      "Arabic coffee, tea, and unlimited soft drinks and mineral water",
    ],
    activities: [
      "Dune bashing and desert drive in a 4x4 vehicle",
      "Camel riding",
      "Sandboarding",
      "Henna painting (tattoo)",
      "International buffet dinner with BBQ",
      "Live entertainment including Belly Dance, Tanoura Dance, and Fire Dance",
      "Arabic coffee, tea, and unlimited soft drinks and mineral water",
    ],
    included: [
      "Pick-up and drop-off",
      "6-hour desert safari",
      "Dune bashing and desert drive in a 4x4 vehicle",
      "Sandboarding",
      "Viewing Dubai Desert Sunset",
      "Camel riding",
      "Henna painting (tattoo)",
      "Welcome Arabic coffee and tea",
      "International buffet dinner with BBQ",
      "Unlimited soft drinks and mineral water",
      "Tanoura Dance Show",
      "Belly Dance Show and Fire Dance Show",
      "Fragrant Sheesha (Arabian Waterpipe)",
      "Arabic costumes for photographs",
      "VIP seating area",
      "Traditional Arabian Bedouin style camp",
      "Prayer room",
      "Well-maintained washrooms",
    ],
    excluded: [
      "Falcon photographs (additional cost)",
      "Quad Bikes (ATV) (additional cost)",
      "Alcoholic beverages (additional cost)",
    ],
    vitalInfo: [
      "Trip duration: 6 hours",
      "Group size: Unlimited",
      "Cancellation: Up to 1 day",
    ],
    duration: "6 hours",
    groupSize: "Unlimited",
    cancellation: "Cancellation policy: Up to 1 day before the safari.",
  },
  {
    productName: "Morning Desert Safari Dubai",
    price: 125,
    currency: "AED",
    ratings: "4.5",
    camp: false,
    imageUrl: [
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720463392/stephane-hurbe-BPTwvhCoE18-unsplash_cjzda0.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720463318/daniel-jankovic-Q6UWamHFEAg-unsplash_eskife.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720463292/ajit-sandhu-RzuBdh2OEoE-unsplash_k6xqas.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720463340/dmitry-novikov-LUEVYhhFX5E-unsplash_vk05f0.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720463318/daniel-jankovic-Q6UWamHFEAg-unsplash_eskife.jpg",
    ],
    location: {
      lat: 24.9056,
      lng: 55.1864,
    },
    safariBrief: `Experience a unique and adventurous Morning Desert Safari with Arabian Safari Dubai. 
    Our professional driver will guide you through towering peaks, engage in dune bashing, sandboarding, and camel riding, creating unforgettable memories.
    Safety and exemplary service are our top priorities, ensuring your comfort and enjoyment throughout the tour. 
    With a cost of approximately 150 AED per person (minimum of 4 persons) or 700 AED for a private tour up to 6 persons, this 4-hour expedition includes pick-up at 8:00 am and drop-off at noon or customized timing for your convenience.
    The morning desert safari promises an unforgettable exploration of Dubai's magical desert landscape.`,
    safariBreifBullets: [
      "Pick-up at 8:00 am from your location in Dubai",
      "Cooler morning temperatures for a comfortable experience",
      "Dune bashing with a professional driver",
      "Sandboarding on pristine dunes",
      "Camel ride in the serene morning desert",
      "Discover Bedouin culture and native desert life",
      "4-hour tour with customizable timing",
      "150 AED per person (minimum of 4 persons) or 700 AED for a private tour up to 6 persons",
      "Safety and exemplary service for your comfort",
    ],
    overview:
      "Experience the tranquility and adventure of the Dubai desert with our Morning Desert Safari. Enjoy activities like dune bashing, camel riding, and sandboarding in the cool morning hours.",
    highlights: [
      "4-hour morning desert safari in Dubai",
      "Dune bashing and desert drive in a 4x4 vehicle",
      "Camel riding and sandboarding",
      "Pick-up and drop-off from your hotel or residence",
    ],
    activities: [
      "Dune bashing and desert drive in a 4x4 vehicle",
      "Camel riding",
      "Sandboarding",
      "ATV/Quad Bikes (additional cost)",
    ],
    included: [
      "Pick-up and drop-off from your hotel or residence",
      "4-hour morning desert safari",
      "Dune bashing and desert drive in a 4x4 vehicle",
      "Sandboarding",
      "Camel riding",
    ],
    excluded: ["ATV/Quad Bikes (additional cost)"],
    vitalInfo: [
      "Duration: 4 hours",
      "Group size: Unlimited",
      "Cancellation: Up to 1 day",
    ],
    duration: "4 hours",
    groupSize: "Unlimited",
    cancellation: "Cancellation policy: Up to 1 day before the safari.",
    costAndTime: {
      cost: {
        perPerson: "Approximately 150 AED per person (minimum 4 persons)",
        totalPrivateTour: "Approximately 700 AED for up to 6 persons",
      },
      time: {
        pickUp: "Between 8:00-8:30 AM",
        dropOff: "Between 12:00-12:30 PM",
      },
    },
  },
  {
    productName: "Jeep Wrangler Desert Safari Dubai",
    duration: "6 hours",
    price: 200,
    currency: "AED",
    ratings: "4",
    imageUrl: [
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720464766/bandan-mohammed-0ePWPXBYx3E-unsplash_r3pktb.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721241452/Humrah%20Travels%20Version%202/Safaris/Noble%20Camp/htxj74somqszmqh3qu2e.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721241451/Humrah%20Travels%20Version%202/Safaris/Noble%20Camp/jzk9pmuihstaqtfd4chm.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721241458/Humrah%20Travels%20Version%202/Safaris/Noble%20Camp/ebuxiufulpguhx4uxp53.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721241459/Humrah%20Travels%20Version%202/Safaris/Noble%20Camp/olj65zlmgu3gxy9bg616.jpg",
    ],
    camp: true,
    campImages: [
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721239960/Humrah%20Travels%20Version%202/Safaris/Lama%20Premium%20Camp/lqztb4vw5rhjybkzqaxs.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721239960/Humrah%20Travels%20Version%202/Safaris/Lama%20Premium%20Camp/f3wsgn9nrzt9hs3u0vyx.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721239961/Humrah%20Travels%20Version%202/Safaris/Lama%20Premium%20Camp/zknv1ri5swvydong33gv.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721239960/Humrah%20Travels%20Version%202/Safaris/Lama%20Premium%20Camp/fqunhb6etuxsuadgkfu2.jpg",
      'https://res.cloudinary.com/dj9xbypxz/image/upload/v1721239961/Humrah%20Travels%20Version%202/Safaris/Lama%20Premium%20Camp/kfwcu3b70sf7xzzha0mz.jpg',
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721239961/Humrah%20Travels%20Version%202/Safaris/Lama%20Premium%20Camp/c5iyk58nh89l9wrumpkv.jpg",
    ],
    campDetails: `Discover the thrill of our Jeep Wrangler Desert Safari with Arabian Safari Dubai. 
    This adventure takes you deep into the Dubai desert, offering an unforgettable experience. 
    Start with a 45-minute dune ride over the dramatic Red Dunes, followed by adrenaline-pumping sandboarding. 
    Capture stunning desert views, relax, and create memorable moments with friends and family. 
    We ensure a seamless experience with pick-up from your hotel or residence in Dubai and Sharjah, and return transport included. 
    Our licensed guides prioritize safety and provide exceptional service.`,
    campactivities: [
      "45-minute Dune Ride on Red Dunes",
      "Adrenaline-pumping Sandboarding",
      "Photo opportunities with stunning desert views",
      "Pick-up and return transport from Dubai or Sharjah",
      "Licensed and trained guides",
      "Camel Ride",
      "Quad Biking (additional fee)",
      "Traditional Bedouin camp experience",
      "BBQ Dinner (vegetarian and non-vegetarian options)",
      "Welcome with Gaymat (sweets), Gahwa (Arabic coffee), and dates",
      "Unlimited soft drinks",
      "Henna Painting, Falcon expert meet, and Shisha",
    ],
    location: {
      lat: 24.9056,
      lng: 55.1864,
    },
    groupSize: "4 people",
    cancellation: "Up to 1 day",
    overview:
      "Embark on an exhilarating Jeep Wrangler Desert Safari in Dubai to explore the desert's beauty and enjoy thrilling activities like dune bashing, camel riding, and sandboarding. Experience a memorable adventure away from the city.",
    highlights: [
      "6-hour Jeep Wrangler Desert Safari in Dubai",
      "Dune bashing and desert drive in a 4x4 Jeep Wrangler",
      "Camel riding and sandboarding",
      "International buffet dinner with BBQ",
      "Live entertainment including Belly Dance, Tanoura Dance, and Fire Dance",
      "Transportation included from Dubai and Sharjah",
    ],
    activities: [
      "Dune bashing and desert drive in a 4x4 Jeep Wrangler",
      "Camel riding",
      "Sandboarding",
      "BBQ dinner with international buffet",
      "Live entertainment (Belly Dance, Tanoura Dance, Fire Dance)",
      "Henna painting (tattoo)",
      "Unlimited soft drinks and mineral water",
      "Optional activities: Falcon photographs, Quad Bikes (ATV), Alcohol Beverages (additional cost)",
    ],
    included: [
      "Transportation from Dubai and Sharjah",
      "6-hour Jeep Wrangler Desert Safari",
      "Dune bashing and desert drive in a 4x4 Jeep Wrangler",
      "Sandboarding",
      "Camel riding",
      "International buffet dinner with BBQ",
      "Live entertainment (Belly Dance, Tanoura Dance, Fire Dance)",
      "Welcome Arabic Coffee & Tea",
      "Unlimited soft drinks and mineral water",
      "Fragrant Sheesha (Arabian Waterpipe)",
      "Arabic costumes for photographs",
      "VIP Sitting area and food service",
      "Traditional Arabian Bedouin style camp",
      "Prayer Room",
      "Well-maintained washrooms",
    ],
    excluded: [
      "Optional activities: Falcon photographs, Quad Bikes (ATV), Alcohol Beverages (additional cost)",
    ],
    vitalInfo: [
      "Trip duration: 6 hours",
      "Group size: 4 people",
      "Cancellation policy: Up to 1 day before the safari",
    ],
  },
  {
    productName: "Overnight Dubai Desert Safari",
    duration: "15 hours",
    price: 270,
    currency: "AED",
    ratings: "5",
    imageUrl: [
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720462941/abed-ismail-SVzfrZjq1L8-unsplash_zimnrs.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720462933/finding-dan-dan-grinwis-lXvycA58ZfQ-unsplash_kagqhr.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720462941/artur-aldyrkhanov-Z-wHvKtkRAs-unsplash_heri3t.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720462933/mounir-abdi-XkwVbHZvEfc-unsplash_sku9ox.jpg",
    ],
    camp: true,
    campImages: [
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721150193/Humrah%20Travels%20Version%202/Safaris/Desert%20Spot%20Camp/qq3zksyv8mrpu0sponzf.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721150192/Humrah%20Travels%20Version%202/Safaris/Desert%20Spot%20Camp/fnm3koblhmo7s3srjluz.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721150191/Humrah%20Travels%20Version%202/Safaris/Desert%20Spot%20Camp/hv5fs5q31vcctawuycov.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721150191/Humrah%20Travels%20Version%202/Safaris/Desert%20Spot%20Camp/sii70jsuwdkz3j4t3tya.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721150191/Humrah%20Travels%20Version%202/Safaris/Desert%20Spot%20Camp/yi59jcoigq43k8n1zp1n.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721150190/Humrah%20Travels%20Version%202/Safaris/Desert%20Spot%20Camp/qtah5wpq5mxoebgpo9c6.jpg",
    ],
    campDetails: `Experience the unparalleled beauty of the desert with our top-rated Overnight Desert Safari Dubai tour.
     From the moment we pick you up in a 4x4 SUV from your hotel or preferred location, our goal is to ensure you enjoy every moment.
      Located less than an hour’s drive from Dubai, our tour offers breathtaking sand dunes, an international buffet, and breakfast to keep you energized.
      Our affordable packages put you in control of your adventure. Expect top-notch entertainment and activities that exceed your expectations, leaving you with unforgettable memories.`,
      campactivities: [
      "Belly Dance",
      "Tanoura Dance",
      "Fire Show",
      "Background Music (Arabian, Bollywood & Hollywood)",
      "Henna Tattooing",
      "Shisha Corner",
      "Dune Bashing",
      "Camel Riding",
      "Sandboarding",
      "Quad Biking",
    ],
    location: {
      lat: 24.9056,
      lng: 55.1864,
    },
    groupSize: "Unlimited",
    cancellation: "Up to 1 day",
    overview:
      "Embark on an unforgettable Overnight Dubai Desert Safari with Arabian Safari Dubai. Experience dune bashing, camel riding, sandboarding, and thrilling entertainment shows. Stay overnight in a tent amidst the serene Arabian desert, and wake up to a scenic sunrise and breakfast in the camp.",
    highlights: [
      "15-hour Overnight Dubai Desert Safari",
      "Dune bashing and desert drive in a 4x4 vehicle",
      "Sandboarding",
      "Camel riding",
      "International buffet dinner with BBQ",
      "Live entertainment including Belly Dance, Tanoura Dance, and Fire Dance",
      "Overnight stay in a tent with mattress and pillows",
      "Scenic sunrise view and breakfast in the desert camp",
      "Transportation included from Dubai and Sharjah",
    ],
    activities: [
      "Dune bashing and desert drive in a 4x4 vehicle",
      "Camel riding",
      "Sandboarding",
      "BBQ dinner with international buffet",
      "Live entertainment (Belly Dance, Tanoura Dance, Fire Dance)",
      "Henna painting (temporary tattoo)",
      "Welcome Arabic Coffee & Tea",
      "Unlimited soft drinks and mineral water",
      "Fragrant Sheesha (Arabian Waterpipe)",
      "Arabic costumes for photographs",
      "VIP Sitting area and food service (additional cost)",
      "Stay in tent with mattress and pillows",
      "Scenic sunrise view and breakfast in camp",
      "Optional activities: Falcon photographs, Quad Bikes (ATV), Alcohol Beverages (additional cost)",
    ],
    included: [
      "Transportation from Dubai and Sharjah",
      "15-hour Overnight Dubai Desert Safari",
      "Dune bashing and desert drive in a 4x4 vehicle",
      "Sandboarding",
      "Camel riding",
      "International buffet dinner with BBQ",
      "Live entertainment (Belly Dance, Tanoura Dance, Fire Dance)",
      "Welcome Arabic Coffee & Tea",
      "Unlimited soft drinks and mineral water",
      "Fragrant Sheesha (Arabian Waterpipe)",
      "Arabic costumes for photographs",
      "Stay in tent with mattress and pillows",
      "Scenic sunrise view and breakfast in camp",
      "Prayer Room",
      "Well-maintained washrooms",
    ],
    excluded: [
      "Optional activities: Falcon photographs, Quad Bikes (ATV), Alcohol Beverages, VIP Sitting area, Food to be served on the table (additional cost)",
    ],
    vitalInfo: [
      "Trip duration: 15 hours",
      "Group size: Unlimited",
      "Cancellation policy: Up to 1 day before the safari",
    ],
  },
  {
    productName: "Hatta Mountain Safari",
    duration: "8 hours",
    groupSize: "Unlimited",
    cancellation: "Up to 1 day",
    price: 125,
    currency: "AED",
    ratings: "4.6",
    camp: false,
    safariBrief: `Discover the natural beauty of Hatta Mountain with a blend of rugged mountains and tranquil desert 
    landscapes. The Hatta Mountain Safari, provided by Arabian Safari Dubai, is just 1 hour and 30 minutes from Dubai. 
     With its temperate weather, Hatta is perfect for picnics, barbeques, and various activities that promise a memorable trip.
     Embark on the Hatta Wadi Tour, a half-day adventure through the 200-year-old Hatta Village.
     `,
    safariBreifBullets: [
      "Scenic drive through Hajar mountains",
      "Dune bashing experience",
      "Carpet Market visit",
      "Hatta Heritage Village",
      "Hatta Hill Park",
      "Hatta Wadi Hub",
      "Hatta Dam",
      "Hatta Fort Hotel",
      "Hatta Mountain Photography Point",
    ],
    imageUrl: [
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720464193/ziad-al-halabi-NGLO-Gu82M4-unsplash_lj661k.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720464183/azher-zee-7zXqPO7MgZI-unsplash_qlld4c.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720464176/datingscout-b_BRaORHCY4-unsplash_tmmopg.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1720464170/mahmoud-sherif-Em67nbVKBGc-unsplash_omgi1i.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720464168/malek-irshaidat-aQXU0PUfQJo-unsplash_gi32oa.jpg",
    ],
    location: {
      lat: 24.9056,
      lng: 55.1864,
    },
    overview:
      "Explore the natural beauty and cultural heritage of Hatta Mountain with Arabian Safari Dubai. Experience dune bashing, scenic drives, visits to Hatta Heritage Village, Hatta Dam, and more amidst the stunning Hajar Mountains landscape.",
    highlights: [
      "8-hour Hatta Mountain Safari",
      "Scenic drive through Hajar mountainous area",
      "Visit to Hatta Dam and opportunities for activities like kayaking (extra cost)",
      "Exploration of Hatta Heritage Village and Hatta Hill Park",
      "Dune bashing and wadi bashing (available on private booking)",
      "Transportation included with pick-up and drop-off from Dubai hotels or residences",
    ],
    activities: [
      "Scenic drive through Hajar mountainous area",
      "Visit to Hatta Dam (opportunity for kayaking at additional cost)",
      "Photography of dramatic landscape",
      "Exciting Hatta wadi bashing (available on private booking)",
      "Exploration of Hatta Heritage Village",
      "Visit to Hatta Hill Park and Hatta Fort Hotel",
      "AC-equipped vehicles for comfort",
    ],
    included: [
      "Transportation with pick-up and drop-off from Dubai hotels or residences",
      "Scenic drive through Hajar mountainous area",
      "Visit to Hatta Dam",
      "Exploration of Hatta Heritage Village",
      "Visit to Hatta Hill Park and Hatta Fort Hotel",
    ],
    excluded: [
      "Optional activities like kayaking at Hatta Dam (additional cost)",
      "Private booking for exclusive activities like dune bashing and wadi bashing",
    ],
    vitalInfo: [
      "Trip duration: 8 hours",
      "Group size: Unlimited",
      "Cancellation policy: Up to 1 day before the safari",
    ],
  },
  {
    productName: "Desert Safari Dubai VIP by Hummer",
    duration: "6 hours",
    groupSize: "Unlimited",
    cancellation: "Up to 1 day",
    price: 150,
    currency: "AED",
    camp: false,
    ratings: "4.8",
    imageUrl: [
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720463877/jakub-david-n1zpE7dsNyA-unsplash_tbwc7j.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720463854/muhammed-shazin-ADO6E1NBBsA-unsplash_f2sxgu.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720463658/traveling-with-off-road-car_ds3uwo.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720463500/andrew-hunt-7K9mbwbOGl8-unsplash_nwkik2.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720463854/muhammed-shazin-ADO6E1NBBsA-unsplash_f2sxgu.jpg",

    ],
    location: {
      lat: 24.9056,
      lng: 55.1864,
    },
    safariBrief: `Indulge in ultimate luxury and adventure with our VIP Hummer Desert Safari.
     Experience the thrill of off-roading in Hummer H2 vehicles, perfect for couples, families, and groups.
      Enjoy seamless pick-up and drop-off from your hotel, and expect top-notch dune bashing and exclusive entertainment. 
      Our comprehensive package includes an international buffet, welcoming drinks, and a night filled with traditional entertainment, ensuring an unforgettable experience in the Arabian Desert.`,
      safariBreifBullets: [
      "VIP Dune Bashing in Hummer H2",
      "Camel Riding",
      "Quad Biking",
      "Sandboarding",
      "Henna Painting",
      "International Buffet Dinner (BBQ)",
      "Welcome Drinks",
      "Arabian Coffee with Dates",
      "Soft Drinks, Tea, and Mineral Water",
      "Arabian Belly Dance",
      "Turkish Tanoura Dance",
      "Fire Dance Show",
      "Music (Arabian, Hindu, and English)",
    ],
    overview:
      "Experience the ultimate luxury and adventure with Desert Safari VIP by Hummer in the Arabian Desert. Enjoy dune bashing, camel riding, quad biking, sandboarding, and traditional entertainment shows like Belly Dance, Tanoura Dance, and Fire Dance.",
    highlights: [
      "6-hour VIP Desert Safari by Hummer",
      "Transportation by Hummer included, pick-up and drop-off from Dubai or Sharjah",
      "Dune bashing and desert drive in Hummer H2",
      "Sandboarding on the desert dunes",
      "Camel riding experience",
      "Henna painting (temporary tattoo)",
      "Welcome Arabic Coffee & Tea",
      "International Buffet Dinner with BBQ (Vegetarian & Non-vegetarian dishes)",
      "Unlimited soft drinks and mineral water",
      "Enjoy Fragrant Sheesha (Arabian Waterpipe)",
      "Arabic costumes for photographs",
      "Entertainment with Tanoura Dance, Belly Dance Show, and Fire Dance Show",
    ],
    activities: [
      "Dune bashing and desert drive in Hummer H2",
      "Sandboarding",
      "Viewing Dubai Desert Sunset",
      "Camel riding",
      "Henna painting (temporary tattoo)",
      "Welcome Arabic Coffee & Tea",
      "International Buffet Dinner with BBQ (Vegetarian & Non-vegetarian dishes)",
      "Unlimited soft drinks and mineral water",
      "Fragrant Sheesha (Arabian Waterpipe)",
      "Arabic costumes for photographs",
      "Entertainment with Tanoura Dance, Belly Dance Show, and Fire Dance Show",
    ],
    included: [
      "Transportation by Hummer included",
      "Trip duration: 6 hours",
      "Dune bashing and desert drive in a Hummer H2",
      "Sandboarding",
      "Viewing Dubai Desert Sunset",
      "Camel riding",
      "Henna painting (temporary tattoo)",
      "Welcome Arabic Coffee & Tea",
      "International Buffet Dinner with BBQ",
      "Unlimited soft drinks and mineral water",
      "Fragrant Sheesha (Arabian Waterpipe)",
      "Arabic costumes for photographs",
      "Tanoura Dance Show",
      "Belly Dance Show",
      "Fire Dance Show",
      "Prayer Room",
      "Restrooms Available",
    ],
    excluded: [
      "Falcon photographs (additional cost)",
      "Quad Bikes (ATV) (additional cost)",
      "Alcohol beverages (additional cost)",
    ],
    additionalServices: [],
    vitalInfo: [
      "Group size: Unlimited",
      "Cancellation policy: Up to 1 day before the safari",
    ],
  },
];

export const activities = [
  {
    productName: "Museum of the Future Ticket in Dubai",
    duration: "1 day",
    price: 100,
    currency: "AED",
    ratings: "",
    imageUrl: [BestInCurseImg2],
    location: {
      lat: 25.276987,
      lng: 55.296249,
    },
    groupSize: "Unlimited",
    cancellation: "No Cancellation",
    overview:
      "Experience an innovative journey into tomorrow with the Museum of the Future Ticket in Dubai. This extraordinary institution redefines perceptions of the future through immersive scenarios blending science, technology, and spirituality. The museum's distinctive ring-shaped architecture symbolizes limitless possibilities for mankind, offering transformative and empowering experiences.",
    highlights: [
      "Empowering vision of the future",
      "Innovative galleries and exhibitions",
      "Ring-shaped architecture symbolizing boundless potential",
      "Merge of science, technology, and spirituality",
    ],
    vitalInfo: [
      "Prohibited items: Dangerous materials such as bottles, firecrackers, lasers",
      "Pets: Not allowed except for service animals with valid identification",
      "Admission includes access to all galleries and exhibitions",
    ],
    additionalInfo: {
      Included: [
        "Complimentary WiFi",
        "Audio guide",
        "Photography (additional fees may apply)",
      ],
      Excluded: ["Personal expenses", "Other services not mentioned"],
    },
  },
  {
    productName: "Louvre Abu Dhabi Ticket",
    duration: "1 day",
    groupSize: "Unlimited",
    cancellation: "No Cancellation",
    price: 0,
    currency: "AED",
    ratings: "4",
    imageUrl: [
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248833/Humrah%20Travels%20Version%202/Activities/Louvre%20Abu%20Dhabi%20Ticket/uifksyfpy1wtfk2ny97i.avif",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248832/Humrah%20Travels%20Version%202/Activities/Louvre%20Abu%20Dhabi%20Ticket/cdfiv3t8p6xcuciatdxp.avif",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248832/Humrah%20Travels%20Version%202/Activities/Louvre%20Abu%20Dhabi%20Ticket/g8pg7lrhpapa9lxetzxx.avif",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248832/Humrah%20Travels%20Version%202/Activities/Louvre%20Abu%20Dhabi%20Ticket/cngvkesrxk2cp24zwssy.avif",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248833/Humrah%20Travels%20Version%202/Activities/Louvre%20Abu%20Dhabi%20Ticket/afu3fpuakjhh20hhfhfb.avif",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248833/Humrah%20Travels%20Version%202/Activities/Louvre%20Abu%20Dhabi%20Ticket/muvmnmyyhilyjeeg9q8m.avif",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248834/Humrah%20Travels%20Version%202/Activities/Louvre%20Abu%20Dhabi%20Ticket/st9qemzbehl65bxtjrot.avif",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248834/Humrah%20Travels%20Version%202/Activities/Louvre%20Abu%20Dhabi%20Ticket/wufebydxlm9pftgfo0cg.avif",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248835/Humrah%20Travels%20Version%202/Activities/Louvre%20Abu%20Dhabi%20Ticket/h3tyxeagcdadetsc2jep.avif",
    ],
    location: {
      lat: 24.531,
      lng: 54.398,
    },
    overview:
      "The Louvre Abu Dhabi Ticket offers a journey through centuries of art in the modern cultural landmark of the capital of the United Arab Emirates. Visitors can explore 12 galleries organized chronologically, showcasing art pieces transcending national and thematic boundaries. The museum's unique honeycomb-style 'floating' dome and cuboid buildings house remarkable artworks from the Louvre Paris and other renowned French museums.",
    highlights: [
      "Unique honeycomb-style 'floating' dome and cuboid buildings",
      "Remarkable artworks on loan from Louvre Paris and other French museums",
      "Chronological arrangement of galleries highlightsing interconnectedness across civilizations",
      "Mesmerizing play of sunlight through the iconic dome",
    ],
    vitalInfo: [
      "Tickets subject to availability on a first come, first served basis",
      "Differently-abled guests must be accompanied by at least one paying companion",
      "Strollers and wheelchairs available for a nominal cost at the entrance",
      "Valid ID required for school and college teachers residing in UAE",
    ],
    additionalInfo: {
      Included: [
        "Access to Louvre Abu Dhabi Ticket",
        "Entry to all 12 galleries",
      ],
      Excluded: ["Transportation", "Personal expenses"],
    },
  },
  {
    productName: "Qasr Al Watan Presidential Palace Ticket in Abu Dhabi",
    duration: "1 day",
    groupSize: "Unlimited",
    cancellation: "No Cancellation",
    price: 0,
    currency: "AED",
    ratings: "5",
    imageUrl: [
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248886/Humrah%20Travels%20Version%202/Activities/Qasr%20Al%20Watan%20Presidential%20Palace/udm8q0uqdxrwnwb92mgc.avif",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248886/Humrah%20Travels%20Version%202/Activities/Qasr%20Al%20Watan%20Presidential%20Palace/r7kpvf4wyn4pc80ta9sg.avif",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248886/Humrah%20Travels%20Version%202/Activities/Qasr%20Al%20Watan%20Presidential%20Palace/enuqvccic1ljiks0q6sy.avif",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248887/Humrah%20Travels%20Version%202/Activities/Qasr%20Al%20Watan%20Presidential%20Palace/jzfghet8ntja0sgym2db.avif",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248887/Humrah%20Travels%20Version%202/Activities/Qasr%20Al%20Watan%20Presidential%20Palace/ruimlqdebqqb0otor3vn.avif",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248887/Humrah%20Travels%20Version%202/Activities/Qasr%20Al%20Watan%20Presidential%20Palace/yilhqdxakihdunt8wb9a.avif",
    ],
    location: {
      lat: 24.412,
      lng: 54.474,
    },
    overview:
      "Explore the heart of Arabic culture and architecture at Qasr Al Watan Presidential Palace in Abu Dhabi with this ticket. This architectural marvel within the Presidential Palace complex offers visitors an enchanting experience with its opulent design, beautiful gardens, and grand halls. Discover the rich history of the nation through comprehensive tours of palace zones, exhibits, lush gardens, and an informative visitor center. Experience the mesmerizing Palace in Motion show, narrating the palace's tale through captivating lights and sounds.",
    highlights: [
      "Access to Palace Zones and Exhibitions",
      "Enjoy the Palace in Motion Show",
    ],
    vitalInfo: [
      "Outside food and drinks not permitted inside the venue",
      "Pets not allowed, except for service animals with valid identification",
      "Confirmation received within minutes, contact customer support if needed",
    ],
    additionalInfo: {
      Included: [
        "Access to Qasr Al Watan Presidential Palace Zones and Exhibitions",
        "Palace in Motion Show",
      ],
      Excluded: ["Transportation", "Meals and Beverages", "Personal expenses"],
    },
  },
];

// Changes and Images added by Rohit
export const popularYachts = [
  {
    productName: "33 Ft Luxury Yacht Dubai",
    price: 400,
    currency: "AED",
    ratings: "",
    type: "yachts",
    imageUrl: [
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720520779/WhatsApp_Image_2022-07-18_at_8.45.52_PM_gqprgz.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720520765/WhatsApp_Image_2022-07-18_at_8.45.54_PM_jnk2d5.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720520762/WhatsApp_Image_2022-07-18_at_8.45.54_PM_1_jb5i19.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720520738/WhatsApp_Image_2022-07-18_at_8.45.53_PM_lwrod7.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720520736/WhatsApp_Image_2022-07-18_at_8.45.52_PM_1_tlvkov.jpg",
    ],
    source: "yachts",
  },
  {
    productName: "41 Ft Luxury Yacht Dubai",
    price: 400,
    currency: "AED",
    ratings: "",
    type: "yachts",
    imageUrl: [
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720520868/WhatsApp_Image_2022-06-08_at_8.28.55_PM_1_psbt4f.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720520877/WhatsApp_Image_2022-06-08_at_8.28.55_PM_ncvcyv.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720520873/WhatsApp_Image_2022-06-08_at_8.28.56_PM_pbxbda.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720520872/WhatsApp_Image_2022-06-08_at_8.28.54_PM_1_zhrvdc.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720520868/WhatsApp_Image_2022-06-08_at_8.28.55_PM_1_psbt4f.jpg",
    ],
    source: "yachts",
  },
  {
    productName: "48 Ft Luxury Yacht Dubai",
    price: 400,
    currency: "AED",
    type: "yachts",
    ratings: "",
    imageUrl: [
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720520850/WhatsApp_Image_2022-07-09_at_8.11.30_PM_btzk29.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720520845/WhatsApp_Image_2022-07-09_at_8.11.30_PM_1_jxcavp.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720520854/WhatsApp_Image_2022-07-09_at_8.11.34_PM_jm87ul.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720520852/WhatsApp_Image_2022-07-09_at_8.11.33_PM_bxnthk.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720520847/WhatsApp_Image_2022-07-09_at_8.11.33_PM_1_j1oxge.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720520843/WhatsApp_Image_2022-07-09_at_8.11.34_PM_1_zprsyi.jpg",
    ],
    source: "yachts",
  },
  {
    productName: "Azimuth Yacht",
    price: 475,
    currency: "AED",
    ratings: "",
    type: "yachts",
    imageUrl: [
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720521112/WhatsApp_Image_2022-07-27_at_2.14.51_PM_ywvkga.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720521118/WhatsApp_Image_2022-07-27_at_2.14.47_PM_wnem2l.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720521114/WhatsApp_Image_2022-07-27_at_2.14.50_PM_bmadpa.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720521108/WhatsApp_Image_2022-07-27_at_2.14.49_PM_uyt2wa.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720521106/WhatsApp_Image_2022-07-27_at_2.14.52_PM_vdi1ad.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720521097/WhatsApp_Image_2022-07-27_at_2.14.48_PM_oe3spr.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720521095/WhatsApp_Image_2022-07-27_at_2.14.48_PM_1_txpbxi.jpg",
    ],
    source: "yachts",
  },
  {
    productName: "Columbus Yacht dubai",
    price: 475,
    currency: "AED",
    type: "yachts",
    ratings: "",
    imageUrl: [
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720521174/WhatsApp_Image_2022-08-14_at_4.51.36_PM_dgrewt.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720521212/WhatsApp_Image_2022-08-14_at_4.51.38_PM_1_z2qavn.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720521200/WhatsApp_Image_2022-08-14_at_4.51.37_PM_cabcna.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720521177/WhatsApp_Image_2022-08-14_at_4.51.35_PM_1_fl6gso.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720521165/WhatsApp_Image_2022-08-14_at_4.51.38_PM_ilvcwq.jpg",
    ],
    source: "yachts",
  },
  {
    productName: "Polina Yacht",
    price: 650,
    currency: "AED",
    type: "yachts",
    ratings: "",
    imageUrl: [
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1719760071/Humrah%20Travels/POLINA%20%28%20COLOMBUS%202%29/el2m1fqtwidi7ol8phay.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1719760071/Humrah%20Travels/POLINA%20%28%20COLOMBUS%202%29/xrmqmba50qbavo7o4cd3.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1719760071/Humrah%20Travels/POLINA%20%28%20COLOMBUS%202%29/uqghts3mzl8wm4hdlxlz.jpg",
    ],
    location: {
      lat: 25.276987,
      lng: 55.296249,
    },
    source: "yachts",
  },
];

export const popularCruises = [
  {
    productName: "Dhow Cruise Al Seef",
    price: 49,
    currency: "AED",
    type: "cruises",
    ratings: "",
    imageUrl: [
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720679193/Ramee-3-scaled_htryob.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720679050/20240614_191744_fdgmof.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720679055/20240614_184132_jhfcao.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720679052/20240614_195352_krphnk.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720679055/20240614_184132_jhfcao.jpg",
    ],
    source: "cruises",
  },
  {
    productName: "Luxury Glass Cruise Catamaran Dubai Marina",
    price: 139,
    currency: "AED",
    type: "cruises",
    category: "Luxury",
    ratings: "",
    imageUrl: [
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720693988/DSC01681_copy_wjfolh.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720693990/DSC01924_copy_j9fyhf.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720693989/DSC01587_copy_m53rmq.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720693989/DSC01587_copy_m53rmq.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720694019/DSC01863_copy_fvbdev.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720694024/DSC02253_copy_xuuqud.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720694024/DSC02120_copy_ii0d49.jpg",
    ],
    source: "cruises",
  },
  {
    productName: "Lotus 5 Star Cruise marina",
    price: 249,
    currency: "AED",
    ratings: "",
    category: "Luxury",
    type: "cruises",
    imageUrl: [
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720694189/IMG-20240313-WA0017_x7f16b.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720694189/IMG-20240313-WA0022_yngrgo.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720694189/IMG-20240313-WA0018_cic1fe.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720694190/IMG-20240313-WA0023_vk44un.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720694190/IMG-20240313-WA0021_tkejx0.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720694192/IMG-20240331-WA0018_e7qzkj.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720694189/IMG-20240313-WA0016_szuzzs.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720694188/IMG-20240331-WA0019_u2fe45.jpg",
    ],
    source: "cruises",
  },
];

export const popularSafaris = [
  {
    productName: "Evening Desert Safari Dubai",
    price: 100,
    currency: "AED",
    ratings: "4.6 by 3 reviews",
    imageUrl: [
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720454668/daniel-jankovic-8CUChfnEovI-unsplash_xvd3i8.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720456021/alex-azabache-XT3Kk0GelbE-unsplash_wprt7t.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720454659/daniel-jankovic-mzBtcVkoP6I-unsplash_olufd0.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720454649/jakub-david-n1zpE7dsNyA-unsplash_tcotcu.jpg",
    ],
    source: "safaris",
  },
  {
    productName: "Morning Desert Safari Dubai",
    price: 125,
    currency: "AED",
    ratings: "",
    imageUrl: [
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720463392/stephane-hurbe-BPTwvhCoE18-unsplash_cjzda0.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720463340/dmitry-novikov-LUEVYhhFX5E-unsplash_vk05f0.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720463318/daniel-jankovic-Q6UWamHFEAg-unsplash_eskife.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720463296/daniele-colucci-gOQMwGlYe2I-unsplash_bzebnb.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720463292/ajit-sandhu-RzuBdh2OEoE-unsplash_k6xqas.jpg",
    ],
    source: "safaris",
  },
  {
    productName: "Jeep Wrangler Desert Safari Dubai",
    duration: "6 hours",
    price: 200,
    currency: "AED",
    ratings: "",
    imageUrl: [
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720464833/iszac-bale-t8-Nqq2ESiw-unsplash_emsmio.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720464824/air-wick-6JsB0N7vWN0-unsplash_n60d9r.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720464821/franco-debartolo-CQk7S0T14HY-unsplash_u18cix.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720464766/bandan-mohammed-0ePWPXBYx3E-unsplash_r3pktb.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720464497/taylor-keeran-Fn9VAkiyNLE-unsplash_zqvdje.jpg",
    ],
    source: "safaris",
  },
  {
    productName: "Overnight Dubai Desert Safari",
    duration: "6 hours",
    price: 200,
    currency: "AED",
    ratings: "",
    imageUrl: [
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720462945/kyle-brumm-AZBgS29j4wM-unsplash_k9r3a4.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720462941/artur-aldyrkhanov-Z-wHvKtkRAs-unsplash_heri3t.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720462941/abed-ismail-SVzfrZjq1L8-unsplash_zimnrs.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720462933/mounir-abdi-XkwVbHZvEfc-unsplash_sku9ox.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720462933/finding-dan-dan-grinwis-lXvycA58ZfQ-unsplash_kagqhr.jpg",
    ],
    source: "safaris",
  },
  {
    productName: "Desert Safari Dubai VIP by Hummer",
    duration: "6 hours",
    price: 200,
    currency: "AED",
    ratings: "",
    imageUrl: [
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720463854/muhammed-shazin-ADO6E1NBBsA-unsplash_f2sxgu.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720463877/jakub-david-n1zpE7dsNyA-unsplash_tbwc7j.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720463658/traveling-with-off-road-car_ds3uwo.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720463500/andrew-hunt-7K9mbwbOGl8-unsplash_nwkik2.jpg",
    ],
    source: "safaris",
  },
  {
    productName: "Hatta Mountain Safari",
    duration: "8 hours",
    groupSize: "Unlimited",
    cancellation: "Up to 1 day",
    price: 125,
    currency: "AED",
    ratings: "4.6",
    source: "safaris",
    imageUrl: [
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720464193/ziad-al-halabi-NGLO-Gu82M4-unsplash_lj661k.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720464183/azher-zee-7zXqPO7MgZI-unsplash_qlld4c.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720464176/datingscout-b_BRaORHCY4-unsplash_tmmopg.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720464170/mahmoud-sherif-Em67nbVKBGc-unsplash_omgi1i.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720464168/malek-irshaidat-aQXU0PUfQJo-unsplash_gi32oa.jpg",
    ],
  },
];

export const popularActivites = [
  {
    productName: "Observation Decks",
    duration: "1 day",
    price: 100,
    currency: "AED",
    ratings: "",
    imageUrl: [
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248509/Humrah%20Travels%20Version%202/Activities/Burj%20Khalifa/rc24sidebwm50lmlgjjf.jpg",
    ],
    subText: "Explore the wonders of the universe at the observatory....",
    subProduct: ["Dubai Frame", "Burj Khalifa"],
  },
  {
    productName: "Theme Parks",
    duration: "1 day",
    groupSize: "",
    cancellation: "Cancellation Policy on Request",
    price: 200,
    currency: "AED",
    ratings: "4",
    imageUrl: [
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248703/Humrah%20Travels%20Version%202/Activities/Ferrari%20World%20Tickets/ngf6xqwgmliscwqiwxmw.jpg",
    ],
    subText: "Discover the citys landmarks with our sightseeing tours....",
    subProduct: ["Dubai Garden", "Ferrari World"],
  },
  {
    productName: "Sightseeing Tour",
    duration: "1 day",
    groupSize: "Unlimited",
    cancellation: "No Cancellation",
    price: 150,
    currency: "AED",
    ratings: "4",
    imageUrl: [
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720600611/nabil-saleh-FCDucD6L8iw-unsplash_c83cuh.jpg",
    ],
    subText: "Experience the thrill of water activities like never before....",
    subProduct: ["Global Village", "Miracle Garden"],
  },
  {
    productName: "Water Activities",
    duration: "1 day",
    groupSize: "Unlimited",
    cancellation: "No Cancellation",
    price: 100,
    currency: "AED",
    ratings: "2",
    imageUrl: [
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248810/Humrah%20Travels%20Version%202/Activities/Laguna%20Waterpark%20Ticket/ynzuisxbwktuql1kbuup.jpg",
    ],
    subText: "Enjoy a fun-filled day at our exciting theme parks....",
    subProduct: ["Yas Island", "Wild Wadi "],
  },
];

export const RecommendedActivites = [
  {
    subProduct: ["Dubai Frame", "Burj Khalifa"],
    productName: "Burj Khalifa Deck Ticket",
    duration: "1 day",
    groupSize: "",
    cancellation: "Cancellation Policy on Request",
    price: 170,
    currency: "AED",
    ratings: "4",
    imageUrl: [
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248509/Humrah%20Travels%20Version%202/Activities/Burj%20Khalifa/rc24sidebwm50lmlgjjf.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248508/Humrah%20Travels%20Version%202/Activities/Burj%20Khalifa/fxlcctajj7anmhlaaa5w.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248508/Humrah%20Travels%20Version%202/Activities/Burj%20Khalifa/mkeijhesyt06k4kza29h.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248507/Humrah%20Travels%20Version%202/Activities/Burj%20Khalifa/gkw32m0xvxkzqruolgyk.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248507/Humrah%20Travels%20Version%202/Activities/Burj%20Khalifa/stdelfyivnwxm5kyvntm.avif",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248507/Humrah%20Travels%20Version%202/Activities/Burj%20Khalifa/xs9dewoth1kjjij5hcxl.avif",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248507/Humrah%20Travels%20Version%202/Activities/Burj%20Khalifa/sm8eouqcdc0vpjeupe47.avif",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248507/Humrah%20Travels%20Version%202/Activities/Burj%20Khalifa/djcqbye4r2lmruogmavg.avif",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720600384/alex-azabache-IkzKkFyMiak-unsplash_srkyzx.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720599020/damian-kamp-Kz5Nik_-4g4-unsplash_szvnuz.jpg",
    ],
    location: {
      lat: 25.1972,
      lng: 55.2744,
    },
    overview:
      "Explore the Burj Khalifa’s breathtaking 124th and 125th-floor observation decks with the Burj Khalifa At the Top Observation Deck Ticket in the UAE! Ensure a hassle-free experience by securing your tickets in advance, allowing you to skip the lines and ascend at a rapid 10 meters per second in the world’s fastest elevator. Revel in spectacular views of Dubai’s skyline, the desert, and the ocean through floor-to-ceiling glass walls with your Burj Khalifa Observation Deck Ticket in Dubai. Take advantage of the provided telescopes for up-close, real-time views of the surroundings.",
    highlights: [
      "Unforgettable views of Dubai from the observatory deck at the iconic Burj Khalifa.",
      "Cover the 124th and 125th floors in a mere 35 seconds using the world’s fastest elevator.",
      "Utilize telescopic views to spot renowned landmarks such as Dubai Marina, Palm Jumeirah, and more.",
      "Explore combo options, including Cafe Treats, Dubai Aquarium, and other exciting offerings!",
    ],
    vitalInfo: [
      "You’ll get a confirmation for Burj Khalifa Observation Deck Dubai within minutes. If not, reach out to our customer support.",
      "Opening Hours: Monday-Sunday, 08:00-00:00",
      "Please arrive 15 minutes before the show time to present your voucher and exchange it for a physical ticket",
    ],
    additionalInfo: {
      Included: ["Audio/Tour guide", "Hotel pick up and drop off"],
      Excluded: ["Meals and beverages", "Souvenirs (available for purchase)"],
    },
  },
  {
    productName: "Dubai Miracle Garden",
    duration: "1 day",
    groupSize: "Unlimited",
    cancellation: "No Cancellation",
    price: 85,
    currency: "AED",
    ratings: "5",
    imageUrl: [
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720600611/nabil-saleh-FCDucD6L8iw-unsplash_c83cuh.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720600550/ali-raoufian--LiPS85csDM-unsplash_iv1cl4.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720600575/yasara-hansani-7JPlM8Bis8A-unsplash_io27en.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720600619/paul-minami-YGnV3o1Kdkc-unsplash_luzux8.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248862/Humrah%20Travels%20Version%202/Activities/Dubai%20Miracle%20Garden/ofxypjej0ndf7zqhwbb1.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248863/Humrah%20Travels%20Version%202/Activities/Dubai%20Miracle%20Garden/efe0y5amd7kmqxaqmzca.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248863/Humrah%20Travels%20Version%202/Activities/Dubai%20Miracle%20Garden/eiyzhpsvqjfkqwe39ryr.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248863/Humrah%20Travels%20Version%202/Activities/Dubai%20Miracle%20Garden/yu8ojn9oludrfr60plwi.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248864/Humrah%20Travels%20Version%202/Activities/Dubai%20Miracle%20Garden/lju2rwng6cizckerc6rw.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248864/Humrah%20Travels%20Version%202/Activities/Dubai%20Miracle%20Garden/qmtmqosfis7knomjq1bx.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248864/Humrah%20Travels%20Version%202/Activities/Dubai%20Miracle%20Garden/boqy287zi0sicgsai7xg.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248865/Humrah%20Travels%20Version%202/Activities/Dubai%20Miracle%20Garden/pqf9gofjzedalnpufn1z.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248865/Humrah%20Travels%20Version%202/Activities/Dubai%20Miracle%20Garden/g1xyxecmzyswxp7uy6pm.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248866/Humrah%20Travels%20Version%202/Activities/Dubai%20Miracle%20Garden/a4lknbg6kqnd03kjnmyj.jpg",
    ],
    location: {
      lat: 25.0689,
      lng: 55.1915,
    },
    overview:
      "Discover the world's largest butterfly facility with the Dubai Butterfly Garden Ticket. Located in Dubai, this enchanting attraction houses thousands of butterflies from over 45 different species in a futuristic rainforest environment. The garden not only offers visual delight but also serves as an educational experience, showcasing the beauty and diversity of these delicate creatures.",
    highlights: [
      "Explore the breathtaking Butterfly Garden",
      "Experience the elegance of fluttering butterflies",
    ],
    vitalInfo: [
      "No re-entry after leaving the venue",
      "Strict prohibition on bringing food and drinks to avoid disturbing butterflies",
      "Confirmation received swiftly, with customer support available",
    ],
    additionalInfo: {
      Included: ["Hotel pick-up and drop-off"],
      Excluded: ["Personal expenses beyond the ticket"],
    },
  },
  {
    productName: "Ferrari World Tickets",
    duration: "1 day",
    groupSize: "Unlimited",
    cancellation: "No Cancellation",
    price: 329,
    currency: "AED",
    ratings: "5",
    imageUrl: [
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248703/Humrah%20Travels%20Version%202/Activities/Ferrari%20World%20Tickets/ngf6xqwgmliscwqiwxmw.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248703/Humrah%20Travels%20Version%202/Activities/Ferrari%20World%20Tickets/sbw4tiwjopjlwq62xll0.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248702/Humrah%20Travels%20Version%202/Activities/Ferrari%20World%20Tickets/widxszmmmzcjh8ezhuzx.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248702/Humrah%20Travels%20Version%202/Activities/Ferrari%20World%20Tickets/wcouyxgnnqczi844uqvl.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248702/Humrah%20Travels%20Version%202/Activities/Ferrari%20World%20Tickets/qxsywuiojoe6wakdbxkb.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248701/Humrah%20Travels%20Version%202/Activities/Ferrari%20World%20Tickets/rmr8mlbocyjbgj2ggjit.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248701/Humrah%20Travels%20Version%202/Activities/Ferrari%20World%20Tickets/l608p2xqrvpsa2mc9w1c.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248701/Humrah%20Travels%20Version%202/Activities/Ferrari%20World%20Tickets/pa3ondm8gstu4tnwn0pc.jpg",
    ],
    location: {
      lat: 24.4957,
      lng: 54.6033,
    },
    overview:
      "Ferrari World is a fun-filled park and a perfect place for adventurers who need excitement to revamp their busy routines. Arabian Safari Dubai offers its services for booking the Ferrari World Cheap ticket to spend a day full of excitement and adventure in Abu Dhabi. Ferrari World Yas Island promises great entertainment for the whole family. We make preparations so that you can enjoy access to the fast racing legends of Italian supercars connected with them.",
    highlights: [
      "Access to the world’s fastest roller coaster Formula Rossa",
      "44 exhilarating rides and family-friendly attractions",
      "Innovative simulators and live shows",
    ],
    vitalInfo: [
      "The location of Ferrari World is on the Yas Islands in Abu Dhabi",
      "The operating hours are from 12:00 pm to 8:00 pm, and on Wednesdays from 10:00 am to 8:00 pm",
      "The best time to visit Ferrari World is in the morning to avoid crowds",
      "Take a bus to Ibn Battuta Mall from Dubai Marina or a Metro from the airport Terminal 3. Taxi can be arranged with additional charges",
    ],
    additionalInfo: {
      Included: ["Access to all rides and attractions"],
      Excluded: ["Personal expenses beyond the ticket", "Food and beverages"],
    },
  },
  // {
  //   productName: "Laguna Waterpark Ticket ",
  //   duration: "1 day",
  //   groupSize: "Unlimited",
  //   cancellation: "No Cancellation",
  //   price: 100,
  //   currency: "AED",
  //   ratings: "5",
  //   imageUrl: [
  //     "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248768/Humrah%20Travels%20Version%202/Activities/Laguna%20Waterpark%20Ticket/uare15hp2q5a50hkm2ix.jpg",
  //     "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248768/Humrah%20Travels%20Version%202/Activities/Laguna%20Waterpark%20Ticket/bspxts5rhlgudiqkm3g2.jpg",
  //     "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248768/Humrah%20Travels%20Version%202/Activities/Laguna%20Waterpark%20Ticket/zdcmrjn3el7jvdgcgnwk.jpg",
  //     "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248769/Humrah%20Travels%20Version%202/Activities/Laguna%20Waterpark%20Ticket/nx3rmf881sqsvoq6q5hl.jpg",
  //     "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248809/Humrah%20Travels%20Version%202/Activities/Laguna%20Waterpark%20Ticket/oxlsrrlhte6rzbbmj37l.jpg",
  //     "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248809/Humrah%20Travels%20Version%202/Activities/Laguna%20Waterpark%20Ticket/hexojur6wvt8z1kxjyoh.jpg",
  //     "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248810/Humrah%20Travels%20Version%202/Activities/Laguna%20Waterpark%20Ticket/ynzuisxbwktuql1kbuup.jpg",
  //     "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248811/Humrah%20Travels%20Version%202/Activities/Laguna%20Waterpark%20Ticket/fxgpjxaey4mbfmlrr5xo.jpg",
  //     "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248811/Humrah%20Travels%20Version%202/Activities/Laguna%20Waterpark%20Ticket/jpd9ube4myjmzjgka5up.jpg",
  //   ],
  //   location: {
  //     lat: 25.1388,
  //     lng: 55.2272,
  //   },
  //   overview:
  //     "Dive into a world of aquatic adventures at Laguna Waterpark in Dubai. Located in the heart of La Mer, this vibrant waterpark offers a variety of thrilling rides, a relaxing lazy river, and an infinity pool with stunning views. Perfect for families and thrill-seekers alike, Laguna Waterpark promises a day of unforgettable fun.",
  //   highlights: [
  //     "Experience the thrill of water slides and rides",
  //     "Relax in the lazy river and infinity pool",
  //     "Enjoy stunning views from the waterpark",
  //   ],
  //   vitalInfo: [
  //     "No re-entry after leaving the venue",
  //     "Outside food and drinks are not allowed",
  //     "Instant confirmation with customer support available",
  //   ],
  //   additionalInfo: {
  //     Included: ["Access to all rides and attractions"],
  //     Excluded: ["Personal expenses beyond the ticket", "Food and beverages"],
  //   },
  // },
];

export const ObservationActivites = [
  {
    productName: "Burj Khalifa At the Top  Deck Ticket ",
    duration: "1 day",
    groupSize: "",
    category: "ObservationActivites",
    cancellation: "Cancellation Policy on Request",
    price: 170,
    currency: "AED",
    ratings: "4",
    imageUrl: [
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248509/Humrah%20Travels%20Version%202/Activities/Burj%20Khalifa/rc24sidebwm50lmlgjjf.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248508/Humrah%20Travels%20Version%202/Activities/Burj%20Khalifa/fxlcctajj7anmhlaaa5w.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248508/Humrah%20Travels%20Version%202/Activities/Burj%20Khalifa/mkeijhesyt06k4kza29h.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248507/Humrah%20Travels%20Version%202/Activities/Burj%20Khalifa/gkw32m0xvxkzqruolgyk.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248507/Humrah%20Travels%20Version%202/Activities/Burj%20Khalifa/stdelfyivnwxm5kyvntm.avif",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248507/Humrah%20Travels%20Version%202/Activities/Burj%20Khalifa/xs9dewoth1kjjij5hcxl.avif",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248507/Humrah%20Travels%20Version%202/Activities/Burj%20Khalifa/sm8eouqcdc0vpjeupe47.avif",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248507/Humrah%20Travels%20Version%202/Activities/Burj%20Khalifa/djcqbye4r2lmruogmavg.avif",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720600384/alex-azabache-IkzKkFyMiak-unsplash_srkyzx.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720599020/damian-kamp-Kz5Nik_-4g4-unsplash_szvnuz.jpg",
    ],
    location: {
      lat: 25.1972,
      lng: 55.2744,
    },
    overview:
      "Explore the Burj Khalifa’s breathtaking 124th and 125th-floor observation decks with the Burj Khalifa At the Top Observation Deck Ticket in the UAE! Ensure a hassle-free experience by securing your tickets in advance, allowing you to skip the lines and ascend at a rapid 10 meters per second in the world’s fastest elevator. Revel in spectacular views of Dubai’s skyline, the desert, and the ocean through floor-to-ceiling glass walls with your Burj Khalifa Observation Deck Ticket in Dubai. Take advantage of the provided telescopes for up-close, real-time views of the surroundings.",
    highlights: [
      "Unforgettable views of Dubai from the observatory deck at the iconic Burj Khalifa.",
      "Cover the 124th and 125th floors in a mere 35 seconds using the world’s fastest elevator.",
      "Utilize telescopic views to spot renowned landmarks such as Dubai Marina, Palm Jumeirah, and more.",
      "Explore combo options, including Cafe Treats, Dubai Aquarium, and other exciting offerings!",
    ],
    vitalInfo: [
      "You’ll get a confirmation for Burj Khalifa Observation Deck Dubai within minutes. If not, reach out to our customer support.",
      "Opening Hours: Monday-Sunday, 08:00-00:00",
      "Please arrive 15 minutes before the show time to present your voucher and exchange it for a physical ticket",
    ],
    additionalInfo: {
      Included: ["Audio/Tour guide", "Hotel pick up and drop off"],
      Excluded: ["Meals and beverages", "Souvenirs (available for purchase)"],
    },
  },
  {
    productName: "Dubai Frame Ticket",
    duration: "1 day",
    groupSize: "Unlimited",
    cancellation: "No Cancellation",
    price: 50,
    category: "ObservationActivites",
    currency: "AED",
    ratings: "2",
    imageUrl: [
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248561/Humrah%20Travels%20Version%202/Activities/Dubai%20Frame%20Ticket/yqieoognfj6gqfytnrg0.avif",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248561/Humrah%20Travels%20Version%202/Activities/Dubai%20Frame%20Ticket/ouyuzarnlecntbhxdz3v.avif",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720600474/shreyas-gupta-cx4M9d9yk-I-unsplash_e2wf6p.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720600470/ondrej-bocek-6hlab_UdM3A-unsplash_wsvxqt.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720600449/gopal-krishna-dFBuSVjEVZo-unsplash_bh0oc0.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248562/Humrah%20Travels%20Version%202/Activities/Dubai%20Frame%20Ticket/kmwlceqzqvhthabmwovx.avif",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248562/Humrah%20Travels%20Version%202/Activities/Dubai%20Frame%20Ticket/xesdlfvqzypa6gia5f3m.avif",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248562/Humrah%20Travels%20Version%202/Activities/Dubai%20Frame%20Ticket/nuqn3ory1zejlwhywj7g.avif",
    ],
    location: {
      lat: 25.2331,
      lng: 55.2972,
    },
    overview:
      "The Dubai Frame Ticket provides access to a renowned attraction in the UAE, offering visitors an extraordinary view of the city’s rich history, vibrant present, and promising future. Nestled in the heart of Dubai, this iconic landmark is crafted to mimic an immense picture frame, framing the city’s skyline with perfection. A Dubai Frame Ticket is essential for entry, with ticket prices varying based on the visitor’s age and the time of day. Children under the age of three enjoy complimentary access, while adults and children aged three and above are required to purchase an entrance ticket.",
    highlights: [
      "Visit the biggest famous Dubai Frame!",
      "Observe the towering structure connecting UAE’s past and its blooming present",
      "Experience the transformation from a humble fishing village to a modern metropolis",
      "Enjoy Sky Deck level and see the north to the south of Dubai",
    ],
    vitalInfo: [
      "Dress properly for sacred sites.",
      "Outside food and drinks are not allowed inside the venue",
      "Transportation: Not specified",
    ],
    additionalInfo: {
      Included: ["Admission to: Dubai Frame", "Transport"],
      Excluded: ["Other personal expenses"],
    },
  },
  {
    productName: "The View At The Palm Ticket in Dubai",
    duration: "1 day",
    groupSize: "Unlimited",
    cancellation: "No Cancellation",
    price: 100,
    category: "ObservationActivites",
    currency: "AED",
    ratings: "4",
    imageUrl: [
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248986/Humrah%20Travels%20Version%202/Activities/The%20View%20At%20The%20Palm%20Ticket/ygsn8kz8y1ucvh4xa1np.avif",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248986/Humrah%20Travels%20Version%202/Activities/The%20View%20At%20The%20Palm%20Ticket/ygsn8kz8y1ucvh4xa1np.avif",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248986/Humrah%20Travels%20Version%202/Activities/The%20View%20At%20The%20Palm%20Ticket/wa9w3xzdrlwdeoqqqkwi.avif",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248985/Humrah%20Travels%20Version%202/Activities/The%20View%20At%20The%20Palm%20Ticket/sgbipf9pwt0aftbzwfu2.avif",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248986/Humrah%20Travels%20Version%202/Activities/The%20View%20At%20The%20Palm%20Ticket/wa9w3xzdrlwdeoqqqkwi.avif",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248985/Humrah%20Travels%20Version%202/Activities/The%20View%20At%20The%20Palm%20Ticket/sgbipf9pwt0aftbzwfu2.avif",
    ],
    location: {
      lat: 25.1125,
      lng: 55.1389,
    },
    overview:
      "In Dubai, The View at The Palm offers tourists a renowned observation deck with spectacular panoramic views of the city’s skyline and the famous Palm Jumeirah. Situated on the 52nd floor of The Palm Tower, one of Palm Jumeirah’s tallest structures, The View At The Palm Ticket in Dubai provides a unique vantage point to admire Dubai’s architectural marvels. Visitors must purchase The View At The Palm Ticket Dubai for entry, with ticket prices varying based on age and time of day. Children under the age of four enjoy complimentary access.",
    highlights: [
      "Ascend to the 52nd-floor observation deck in 3 minutes",
      "Panoramic 360-degree views of Palm Jumeirah and surroundings",
      "Experience Dubai Skyline as backdrop for perfect photo opportunities",
    ],
    vitalInfo: [
      "No re-entry after leaving the venue",
      "Photo ID proof required for entry",
      "Admission includes access to an interactive theatre",
    ],
    additionalInfo: {
      Included: [
        "Admission to: The View Palm Jumeirah",
        "Access to Level 52",
        "Access to an interactive theatre",
        "Grand 360-degree display of Palm Jumeirah",
      ],
      Excluded: ["Transport", "Other personal expenses"],
    },
  },
  {
    productName: "Sky Views Observatory Ticket in Dubai",
    duration: "1 day",
    groupSize: "Unlimited",
    cancellation: "No Cancellation",
    price: 110,
    currency: "AED",
    category: "ObservationActivites",
    ratings: "4",
    imageUrl: [
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248935/Humrah%20Travels%20Version%202/Activities/Sky%20Views%20Observatory%20Ticket/eaw4f1aheii6dhvvb0ga.avif",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248936/Humrah%20Travels%20Version%202/Activities/Sky%20Views%20Observatory%20Ticket/twulw38wggmvgosnfzwl.avif",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248936/Humrah%20Travels%20Version%202/Activities/Sky%20Views%20Observatory%20Ticket/cknipsmkpwvdso79k1v7.avif",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248936/Humrah%20Travels%20Version%202/Activities/Sky%20Views%20Observatory%20Ticket/bz59vglsmr4t3afu9hia.avif",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248937/Humrah%20Travels%20Version%202/Activities/Sky%20Views%20Observatory%20Ticket/uiyfbclfuxttyxhyqsfq.avif",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248937/Humrah%20Travels%20Version%202/Activities/Sky%20Views%20Observatory%20Ticket/pdppby1oni5xqdn214a9.avif",
    ],
    location: {
      lat: 25.1972,
      lng: 55.2744,
    },
    overview:
      "The Sky Views Observatory Ticket in Dubai grants access to an exceptional observation deck located on the 148th and 125th floors of the iconic Burj Khalifa skyscraper. Positioned in the heart of Dubai, this observatory offers unparalleled views of the city's skyline, showcasing its architectural wonders, cultural landmarks, and vibrant cityscape. Guests can immerse themselves in the past, present, and future of Dubai, experiencing a blend of modern skyscrapers and historic treasures.",
    highlights: [
      "Experience UAE's most innovative destination for relaxation and adventure",
      "Futuristic design with twin 50-story elliptical towers",
      "Panoramic elevator and Panorama 52 restaurant",
      "Unique adrenaline-raising experiences including an outdoor glass slide",
    ],
    vitalInfo: [
      "Voucher valid only on specified date and time",
      "One complimentary glass slide per ticket",
      "Hotel pick up and drop off available",
      "Additional slides charged at AED 25 each",
    ],
    additionalInfo: {
      Included: [
        "Access to Sky Views Observatory Ticket in Dubai",
        "Use of panoramic elevator and Panorama 52 restaurant",
      ],
      Excluded: [
        "Transportation",
        "Additional glass slides (charged at AED 25 each)",
      ],
    },
  },
  {
    productName: "Louvre Abu Dhabi Ticket",
    duration: "1 day",
    groupSize: "Unlimited",
    cancellation: "No Cancellation",
    price: 75,
    category: "ObservationActivites",
    currency: "AED",
    ratings: "4",
    imageUrl: [
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248833/Humrah%20Travels%20Version%202/Activities/Louvre%20Abu%20Dhabi%20Ticket/uifksyfpy1wtfk2ny97i.avif",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248832/Humrah%20Travels%20Version%202/Activities/Louvre%20Abu%20Dhabi%20Ticket/cdfiv3t8p6xcuciatdxp.avif",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248832/Humrah%20Travels%20Version%202/Activities/Louvre%20Abu%20Dhabi%20Ticket/g8pg7lrhpapa9lxetzxx.avif",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248832/Humrah%20Travels%20Version%202/Activities/Louvre%20Abu%20Dhabi%20Ticket/cngvkesrxk2cp24zwssy.avif",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248833/Humrah%20Travels%20Version%202/Activities/Louvre%20Abu%20Dhabi%20Ticket/afu3fpuakjhh20hhfhfb.avif",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248833/Humrah%20Travels%20Version%202/Activities/Louvre%20Abu%20Dhabi%20Ticket/muvmnmyyhilyjeeg9q8m.avif",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248834/Humrah%20Travels%20Version%202/Activities/Louvre%20Abu%20Dhabi%20Ticket/st9qemzbehl65bxtjrot.avif",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248834/Humrah%20Travels%20Version%202/Activities/Louvre%20Abu%20Dhabi%20Ticket/wufebydxlm9pftgfo0cg.avif",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248835/Humrah%20Travels%20Version%202/Activities/Louvre%20Abu%20Dhabi%20Ticket/h3tyxeagcdadetsc2jep.avif",
    ],
    location: {
      lat: 24.531,
      lng: 54.398,
    },
    overview:
      "The Louvre Abu Dhabi Ticket offers a journey through centuries of art in the modern cultural landmark of the capital of the United Arab Emirates. Visitors can explore 12 galleries organized chronologically, showcasing art pieces transcending national and thematic boundaries. The museum's unique honeycomb-style 'floating' dome and cuboid buildings house remarkable artworks from the Louvre Paris and other renowned French museums.",
    highlights: [
      "Unique honeycomb-style 'floating' dome and cuboid buildings",
      "Remarkable artworks on loan from Louvre Paris and other French museums",
      "Chronological arrangement of galleries highlightsing interconnectedness across civilizations",
      "Mesmerizing play of sunlight through the iconic dome",
    ],
    vitalInfo: [
      "Tickets subject to availability on a first come, first served basis",
      "Differently-abled guests must be accompanied by at least one paying companion",
      "Strollers and wheelchairs available for a nominal cost at the entrance",
      "Valid ID required for school and college teachers residing in UAE",
    ],
    additionalInfo: {
      Included: [
        "Access to Louvre Abu Dhabi Ticket",
        "Entry to all 12 galleries",
      ],
      Excluded: ["Transportation", "Personal expenses"],
    },
  },
  {
    productName: "Qasr Al Watan Presidential Palace Ticket in Abu Dhabi",
    duration: "1 day",
    groupSize: "Unlimited",
    cancellation: "No Cancellation",
    price: 100,
    category: "ObservationActivites",
    currency: "AED",
    ratings: "5",
    imageUrl: [
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248886/Humrah%20Travels%20Version%202/Activities/Qasr%20Al%20Watan%20Presidential%20Palace/udm8q0uqdxrwnwb92mgc.avif",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248886/Humrah%20Travels%20Version%202/Activities/Qasr%20Al%20Watan%20Presidential%20Palace/r7kpvf4wyn4pc80ta9sg.avif",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248886/Humrah%20Travels%20Version%202/Activities/Qasr%20Al%20Watan%20Presidential%20Palace/enuqvccic1ljiks0q6sy.avif",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248887/Humrah%20Travels%20Version%202/Activities/Qasr%20Al%20Watan%20Presidential%20Palace/jzfghet8ntja0sgym2db.avif",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248887/Humrah%20Travels%20Version%202/Activities/Qasr%20Al%20Watan%20Presidential%20Palace/ruimlqdebqqb0otor3vn.avif",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248887/Humrah%20Travels%20Version%202/Activities/Qasr%20Al%20Watan%20Presidential%20Palace/yilhqdxakihdunt8wb9a.avif",
    ],
    location: {
      lat: 24.412,
      lng: 54.474,
    },
    overview:
      "Explore the heart of Arabic culture and architecture at Qasr Al Watan Presidential Palace in Abu Dhabi with this ticket. This architectural marvel within the Presidential Palace complex offers visitors an enchanting experience with its opulent design, beautiful gardens, and grand halls. Discover the rich history of the nation through comprehensive tours of palace zones, exhibits, lush gardens, and an informative visitor center. Experience the mesmerizing Palace in Motion show, narrating the palace's tale through captivating lights and sounds.",
    highlights: [
      "Access to Palace Zones and Exhibitions",
      "Enjoy the Palace in Motion Show",
    ],
    vitalInfo: [
      "Outside food and drinks not permitted inside the venue",
      "Pets not allowed, except for service animals with valid identification",
      "Confirmation received within minutes, contact customer support if needed",
    ],
    additionalInfo: {
      Included: [
        "Access to Qasr Al Watan Presidential Palace Zones and Exhibitions",
        "Palace in Motion Show",
      ],
      Excluded: ["Transportation", "Meals and Beverages", "Personal expenses"],
    },
  },
];

export const SightseeingActivites = [
  {
    productName: "Dubai Miracle Garden Ticket",
    duration: "1 day",
    groupSize: "Unlimited",
    cancellation: "No Cancellation",
    category: "SightseeingActivites",
    price: 85,
    currency: "AED",
    ratings: "5",
    imageUrl: [
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720600611/nabil-saleh-FCDucD6L8iw-unsplash_c83cuh.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720600550/ali-raoufian--LiPS85csDM-unsplash_iv1cl4.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720600575/yasara-hansani-7JPlM8Bis8A-unsplash_io27en.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720600619/paul-minami-YGnV3o1Kdkc-unsplash_luzux8.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248862/Humrah%20Travels%20Version%202/Activities/Dubai%20Miracle%20Garden/ofxypjej0ndf7zqhwbb1.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248863/Humrah%20Travels%20Version%202/Activities/Dubai%20Miracle%20Garden/efe0y5amd7kmqxaqmzca.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248863/Humrah%20Travels%20Version%202/Activities/Dubai%20Miracle%20Garden/eiyzhpsvqjfkqwe39ryr.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248863/Humrah%20Travels%20Version%202/Activities/Dubai%20Miracle%20Garden/yu8ojn9oludrfr60plwi.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248864/Humrah%20Travels%20Version%202/Activities/Dubai%20Miracle%20Garden/lju2rwng6cizckerc6rw.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248864/Humrah%20Travels%20Version%202/Activities/Dubai%20Miracle%20Garden/qmtmqosfis7knomjq1bx.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248864/Humrah%20Travels%20Version%202/Activities/Dubai%20Miracle%20Garden/boqy287zi0sicgsai7xg.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248865/Humrah%20Travels%20Version%202/Activities/Dubai%20Miracle%20Garden/pqf9gofjzedalnpufn1z.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248865/Humrah%20Travels%20Version%202/Activities/Dubai%20Miracle%20Garden/g1xyxecmzyswxp7uy6pm.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248866/Humrah%20Travels%20Version%202/Activities/Dubai%20Miracle%20Garden/a4lknbg6kqnd03kjnmyj.jpg",
    ],
    location: {
      lat: 25.0689,
      lng: 55.1915,
    },
    overview:
      "Discover the world's largest butterfly facility with the Dubai Butterfly Garden Ticket. Located in Dubai, this enchanting attraction houses thousands of butterflies from over 45 different species in a futuristic rainforest environment. The garden not only offers visual delight but also serves as an educational experience, showcasing the beauty and diversity of these delicate creatures.",
    highlights: [
      "Explore the breathtaking Butterfly Garden",
      "Experience the elegance of fluttering butterflies",
    ],
    vitalInfo: [
      "No re-entry after leaving the venue",
      "Strict prohibition on bringing food and drinks to avoid disturbing butterflies",
      "Confirmation received swiftly, with customer support available",
    ],
    additionalInfo: {
      Included: ["Hotel pick-up and drop-off"],
      Excluded: ["Personal expenses beyond the ticket"],
    },
  },
  {
    productName: "Abu Dhabi City Tour",
    duration: "1 day",
    groupSize: "Unlimited",
    cancellation: "Up to 48 hours",
    price: 150,
    category: "SightseeingActivites",
    currency: "AED",
    ratings: "4.33",
    imageUrl: [
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720598947/kel-avelino-yLW1yzCK2HY-unsplash_bw4nem.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720598958/larry-williams-ZHDvPvPYmOo-unsplash_h6ptdf.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720598867/farouq-al-Z89wy84pEyI-unsplash_euymo8.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720598837/devarya-ruparelia-idKM1NgoolI-unsplash_uk4fbx.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248253/Humrah%20Travels%20Version%202/Activities/Abu%20Dhabi%20City%20Tour/wu6izli4wevuiiz95kjr.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248253/Humrah%20Travels%20Version%202/Activities/Abu%20Dhabi%20City%20Tour/hlfgxornyhg9xsuhdpzg.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248253/Humrah%20Travels%20Version%202/Activities/Abu%20Dhabi%20City%20Tour/ckoi6nen6erghau2vgyt.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248253/Humrah%20Travels%20Version%202/Activities/Abu%20Dhabi%20City%20Tour/atectaeqcgh6munvxuuf.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248253/Humrah%20Travels%20Version%202/Activities/Abu%20Dhabi%20City%20Tour/mymyh7myy71schtwblav.jpg",
    ],
    location: {
      lat: 24.4539,
      lng: 54.3773,
    },
    overview:
      "Have you ever thought about visiting Abu Dhabi City Tour? It’s the capital city of the United Arab Emirates and a cultural hub full of rich heritage. Abu Dhabi is perfect whether you’re looking for a modern experience or want to see iconic landmarks. You’re sure to discover new things and have a vibrant experience on an Abu Dhabi City Tour UAE. If you love history and architecture, Abu Dhabi is a must-visit destination. You’ll have a thrilling adventure that you’ll remember forever. That’s why we recommend experiencing the Abu Dhabi City tour with us. As your helpful and fair assistant, we want to make your trip enjoyable and safe.",
    highlights: [
      "Pick up and drop off from your hotel or residence",
      "Customized tour, special and private trip available",
      "Tour for 9 hours (approximately)",
      "Sheikh Zayed Grand Mosque – About 45 minutes",
      "Art Museum – About 30 minutes",
      "Emirates Palace – 10-minute photo stop",
      "Market Date – 25 minutes stay",
      "Marina Mall – Stop for shopping or dining, 45 minutes",
      "Historical village – 25 minutes",
      "Driving through YAS Island",
      "“Ferrari World” – 40 minutes",
      "Complimentary water bottle",
      "Professional drivers cum guides",
      "Comfortable vehicles with A/C facilities",
    ],
    vitalInfo: [
      "No liability for places closed on certain days or weekends",
      "Cancellation of booking at least 1 day before the trip",
      "Opening Hours: Pick up time between 8:30am to 9:30am and drop back approximately 5:30pm on a sharing basis",
    ],
    additionalInfo: {
      Included: [
        "Round transportation",
        "Customized tour",
        "Tour for 9 hours",
        "Visit Sheikh Zayed Grand Mosque",
        "Art Gallery Museum",
        "Drive through Al Baten Presidential Palace",
        "Emirates Palace Hotel (photo stop)",
        "Marina Mall",
        "Drive through Saadiyat Island",
        "Drive through Corniche",
        "Heritage Village",
        "Date Market (Visit)",
        "Drive through the Yas Island",
        "Ferrari World (photo stop)",
        "Complimentary water bottle",
      ],
      Excluded: [
        "Food/services",
        "Porter/mule fee for carrying your personal bag",
        "Soft drinks",
        "Alcohol beverages",
        "Entry tickets",
      ],
    },
  },
  {
    productName: "Dubai City Sightseeing Bus Pass",
    duration: "1 day",
    groupSize: "Unlimited",
    cancellation: "No Cancellation",
    price: 80,
    category: "SightseeingActivites",
    currency: "AED",
    ratings: "4",
    imageUrl: [
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721395967/e5d8cefb-b0de-48c3-a2f2-88045b0be325-11513-Dubai-City-Sightseeing-Dubai-24-48-72Hrs-Standard-Hop-On-Hop-Off-Ticket-05_a0rj23.avif",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721395967/c856dc3a-3dc5-481f-88ca-47e3c3578e2e-11513-Dubai-City-Sightseeing-Dubai-24-48-72Hrs-Standard-Hop-On-Hop-Off-Ticket-07_kyuggs.avif",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721395966/d0cd4549-a00d-427b-8dab-4cf658b20ec9-11513-Dubai-City-Sightseeing-Dubai-24-48-72Hrs-Standard-Hop-On-Hop-Off-Ticket-08_xbclhw.avif",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721395966/c9_fbryfm.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721395967/f2_degkhq.jpg",
    ],
    location: {
      lat: 25.2048,
      lng: 55.2708,
    },
    overview:
      "Experience Dubai’s evolution from a pearl-hunting settlement to a global metropolis with the convenient and affordable Dubai City Sightseeing Bus Pass. This hop-on hop-off bus tour allows you to explore Dubai's multicultural melting pot at your own pace. Discover the city's iconic landmarks such as the old souks, Burj Khalifa, Mall of the Emirates, and Jumeirah Mosque. Enjoy unlimited travel on open-top buses with live commentary and audio guides, ensuring an informative and enjoyable sightseeing experience.",
    highlights: [
      "An open-top bus tour of the city with unlimited travel",
      "Hop on and off at your preferred sightseeing stops",
      "Visit Dubai’s must-see sights including the old souks, Burj Khalifa, Mall of the Emirates, Jumeirah Mosque, and more",
      "A full loop of all stops will take approximately 2 hours",
      "24-hour Hop-on hop-off bus ticket",
      "Audio guide and live commentary on board",
    ],
    vitalInfo: [
      "Confirmation received within minutes. Contact customer support if not received",
      "Tour stops, times, and frequency changes can occur; the operator will inform of any changes",
      "Outside food and drinks are not allowed",
      "Smoking and drinking are strictly prohibited on board",
    ],
    additionalInfo: {
      Included: [
        "24-hour Hop-on hop-off bus ticket",
        "Audio guide",
        "Live commentary on board",
        "Admission to attractions",
      ],
      Excluded: ["Other personal expenses", "Tips and gratuities"],
    },
  },
  {
    productName: "The Palm Monorail – Gateway to Atlantis",
    duration: "1 day",
    groupSize: "Unlimited",
    cancellation: "No Cancellation",
    price: 40,
    category: "SightseeingActivites",
    currency: "AED",
    ratings: "5",
    imageUrl: [
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248956/Humrah%20Travels%20Version%202/Activities/The%20Palm%20Monorail%20%E2%80%93%20Gateway%20to%20Atlantis/tieg1gujnc61yxfut9ub.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248956/Humrah%20Travels%20Version%202/Activities/The%20Palm%20Monorail%20%E2%80%93%20Gateway%20to%20Atlantis/wriahpofqnywphotravh.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248955/Humrah%20Travels%20Version%202/Activities/The%20Palm%20Monorail%20%E2%80%93%20Gateway%20to%20Atlantis/ntyorx9idjkksikfw62r.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248956/Humrah%20Travels%20Version%202/Activities/The%20Palm%20Monorail%20%E2%80%93%20Gateway%20to%20Atlantis/m6sdw0hl3najidslchd0.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248957/Humrah%20Travels%20Version%202/Activities/The%20Palm%20Monorail%20%E2%80%93%20Gateway%20to%20Atlantis/lqvghhmbwtgcg66fi6rz.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248957/Humrah%20Travels%20Version%202/Activities/The%20Palm%20Monorail%20%E2%80%93%20Gateway%20to%20Atlantis/b7vfbnb0yub31xdqud1r.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248958/Humrah%20Travels%20Version%202/Activities/The%20Palm%20Monorail%20%E2%80%93%20Gateway%20to%20Atlantis/dannbif9nssxibt2bmfq.jpg",
    ],
    location: {
      lat: 25.1097,
      lng: 55.1385,
    },
    overview:
      "Experience the convenience of The Palm Monorail, Dubai's state-of-the-art transportation system that provides easy access to the iconic Atlantis resort on Palm Jumeirah. This driverless monorail spans 5.4 kilometers and features six stations, including the Gateway station at the Atlantis entrance. Whether you choose a single-trip or round-trip journey, enjoy seamless travel and stunning views of Dubai's coastline.",
    highlights: [
      "Board the Middle East’s first monorail, the Palm Monorail",
      "Gateway station to the Nakheel Mall",
      "Choose between single-trip and round-trip journeys",
      "Confirmation received within minutes. Contact customer support if not received",
      "One-way transfer ticket",
    ],
    vitalInfo: [],
    additionalInfo: {
      Included: ["One-way transfer ticket"],
      Excluded: [],
    },
  },
];

export const ThemeParkActivites = [
  {
    productName: "Ferrari World Tickets",
    duration: "1 day",
    groupSize: "Unlimited",
    cancellation: "No Cancellation",
    price: 329,
    category: "ThemeParkActivites",
    currency: "AED",
    ratings: "5",
    imageUrl: [
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248703/Humrah%20Travels%20Version%202/Activities/Ferrari%20World%20Tickets/ngf6xqwgmliscwqiwxmw.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248703/Humrah%20Travels%20Version%202/Activities/Ferrari%20World%20Tickets/sbw4tiwjopjlwq62xll0.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248702/Humrah%20Travels%20Version%202/Activities/Ferrari%20World%20Tickets/widxszmmmzcjh8ezhuzx.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248702/Humrah%20Travels%20Version%202/Activities/Ferrari%20World%20Tickets/wcouyxgnnqczi844uqvl.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248702/Humrah%20Travels%20Version%202/Activities/Ferrari%20World%20Tickets/qxsywuiojoe6wakdbxkb.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248701/Humrah%20Travels%20Version%202/Activities/Ferrari%20World%20Tickets/rmr8mlbocyjbgj2ggjit.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248701/Humrah%20Travels%20Version%202/Activities/Ferrari%20World%20Tickets/l608p2xqrvpsa2mc9w1c.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248701/Humrah%20Travels%20Version%202/Activities/Ferrari%20World%20Tickets/pa3ondm8gstu4tnwn0pc.jpg",
    ],
    location: {
      lat: 24.4957,
      lng: 54.6033,
    },
    overview:
      "Ferrari World is a fun-filled park and a perfect place for adventurers who need excitement to revamp their busy routines. Arabian Safari Dubai offers its services for booking the Ferrari World Cheap ticket to spend a day full of excitement and adventure in Abu Dhabi. Ferrari World Yas Island promises great entertainment for the whole family. We make preparations so that you can enjoy access to the fast racing legends of Italian supercars connected with them.",
    highlights: [
      "Access to the world’s fastest roller coaster Formula Rossa",
      "44 exhilarating rides and family-friendly attractions",
      "Innovative simulators and live shows",
    ],
    vitalInfo: [
      "The location of Ferrari World is on the Yas Islands in Abu Dhabi",
      "The operating hours are from 12:00 pm to 8:00 pm, and on Wednesdays from 10:00 am to 8:00 pm",
      "The best time to visit Ferrari World is in the morning to avoid crowds",
      "Take a bus to Ibn Battuta Mall from Dubai Marina or a Metro from the airport Terminal 3. Taxi can be arranged with additional charges",
    ],
    additionalInfo: {
      Included: ["Access to all rides and attractions"],
      Excluded: ["Personal expenses beyond the ticket", "Food and beverages"],
    },
  },
  {
    productName: "Dubai Garden Glow Ticket",
    duration: "1 day",
    groupSize: "Unlimited",
    cancellation: "No Cancellation",
    price: 75,
    category: "ThemeParkActivites",
    currency: "AED",
    ratings: "5",
    imageUrl: [
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248683/Humrah%20Travels%20Version%202/Activities/Dubai%20Garden%20Glow%20Ticket/fp2gpww0cinlrkqroyqb.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248683/Humrah%20Travels%20Version%202/Activities/Dubai%20Garden%20Glow%20Ticket/tkulolgwd9wuklwhungd.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248683/Humrah%20Travels%20Version%202/Activities/Dubai%20Garden%20Glow%20Ticket/h9iq3uwgdxl7mvhyfrip.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248683/Humrah%20Travels%20Version%202/Activities/Dubai%20Garden%20Glow%20Ticket/njzkgjwzbwjsdam0vhja.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248683/Humrah%20Travels%20Version%202/Activities/Dubai%20Garden%20Glow%20Ticket/tkxrqa6wtc4vxtyy7uy9.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248683/Humrah%20Travels%20Version%202/Activities/Dubai%20Garden%20Glow%20Ticket/y9lu3f6heallpahyqstj.jpg",
    ],
    location: {
      lat: 25.2221,
      lng: 55.2803,
    },
    overview:
      "Book your Dubai Glow Park tickets to enjoy the astonishing attractions at an affordable price and secure the great available discounts. Dubai Glow Garden ticket allows you to roam through the 5 theme parks with the exquisite events and demonstrations taking place inside the garden. With Arabian Safari Dubai, you can book online in advance from our website, you can avail of other attractions and combo deals in Dubai Garden Glow Ticket.",
    highlights: [
      "Garden Glow Park Dubai is the world’s largest unique theme park with unusual never-before-seen concepts",
      "Enter the ‘largest glow-in-the-dark garden’ made of 10 million energy-saving bulbs with recycled luminous fabric",
      "Explore the ‘biggest Dinosaur park’ that displays more than 100 animatronic dinosaurs with interactive labs",
      "Children aged 0-3 can enter free of charge",
    ],
    vitalInfo: [
      "No cancellations, refunds, or changes can be made",
      "Opening Hours: Sunday-Friday: 16:00-23:00, Saturday / Public holidays: 16:00-00:00",
      "Unlimited Access to Glow Park, Art Park & Dinosaur Park",
      "Confirmation within minutes. Contact customer support if not received",
    ],
    additionalInfo: {
      Included: ["Access to all themed parks"],
      Excluded: ["Personal expenses beyond the ticket", "Food and beverages"],
    },
  },
  {
    productName: "IMG World Tickets",
    duration: "1 day",
    groupSize: "Unlimited",
    cancellation: "No Cancellation",
    price: 250,
    category: "ThemeParkActivites",
    currency: "AED",
    ratings: "5",
    imageUrl: [
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720600739/eyosias-g-smUsgdVxu68-unsplash_ym4btq.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248739/Humrah%20Travels%20Version%202/Activities/IMG%20World%20Tickets/ff18khancb9sn62vi4dy.avif",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248739/Humrah%20Travels%20Version%202/Activities/IMG%20World%20Tickets/bg9ci36o29ajyomgxtyy.avif",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720600809/deb-dowd-wcYW6CiXj0U-unsplash_ctpgfe.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248739/Humrah%20Travels%20Version%202/Activities/IMG%20World%20Tickets/auf3io7rl8bgffrccjlc.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248740/Humrah%20Travels%20Version%202/Activities/IMG%20World%20Tickets/vvn6rydiyfpygumymbqy.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248740/Humrah%20Travels%20Version%202/Activities/IMG%20World%20Tickets/cbcpnubfa7fdljtgydtx.avif",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248740/Humrah%20Travels%20Version%202/Activities/IMG%20World%20Tickets/ne0iudooymxukx2phhuc.jpg",
    ],
    location: {
      lat: 25.0802,
      lng: 55.3134,
    },
    overview:
      "Experience the ultimate theme park adventure at IMG World of Adventures in Dubai. This indoor amusement park offers a variety of thrilling rides, attractions, and entertainment zones based on popular cartoon and superhero characters. Perfect for families and adventure seekers, IMG World promises a day of fun and excitement.",
    highlights: [
      "Explore thrilling rides and attractions based on popular characters",
      "Enjoy live shows and entertainment",
      "Visit the themed zones for a unique experience",
    ],
    vitalInfo: [
      "No cancellations, refunds, or changes can be made",
      "Opening Hours: Sunday-Wednesday: 11:00-21:00, Thursday-Saturday: 11:00-22:00",
      "Unlimited access to all rides and attractions",
      "Confirmation within minutes. Contact customer support if not received",
    ],
    additionalInfo: {
      Included: ["Access to all rides and attractions"],
      Excluded: ["Personal expenses beyond the ticket", "Food and beverages"],
    },
  },
  {
    productName: "Ski Dubai Tickets Mall of Emirates",
    duration: "1 day",
    groupSize: "Unlimited",
    cancellation: "No Cancellation",
    price: 240,
    category: "ThemeParkActivites",
    currency: "AED",
    ratings: "5",
    imageUrl: [
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248910/Humrah%20Travels%20Version%202/Activities/Ski%20Dubai%20Tickets%20Mall%20of%20Emirates/gngopanwd8z3otm69ry2.avif",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248910/Humrah%20Travels%20Version%202/Activities/Ski%20Dubai%20Tickets%20Mall%20of%20Emirates/o2kmxbbh7d05qo6gwn3z.avif",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248910/Humrah%20Travels%20Version%202/Activities/Ski%20Dubai%20Tickets%20Mall%20of%20Emirates/mb2keux3gstpyjduqvpi.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248911/Humrah%20Travels%20Version%202/Activities/Ski%20Dubai%20Tickets%20Mall%20of%20Emirates/f71aljdwcuuf3kac9ko0.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248911/Humrah%20Travels%20Version%202/Activities/Ski%20Dubai%20Tickets%20Mall%20of%20Emirates/orozalajxn6rt4hcpwpn.avif",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248912/Humrah%20Travels%20Version%202/Activities/Ski%20Dubai%20Tickets%20Mall%20of%20Emirates/ybnltfnsyastgvihnce4.avif",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248912/Humrah%20Travels%20Version%202/Activities/Ski%20Dubai%20Tickets%20Mall%20of%20Emirates/rd358righbqy03vifo9r.avif",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248913/Humrah%20Travels%20Version%202/Activities/Ski%20Dubai%20Tickets%20Mall%20of%20Emirates/khugo6m4pvph1qb9apqo.jpg",
    ],
    location: {
      lat: 25.1183,
      lng: 55.2004,
    },
    overview:
      "Take an escape high above the sky with Arabian Safari Dubai and experience the magic of a chilly winter day right in the heart of the desert with Ski Dubai Tickets Mall of Emirates. It is a gateway to escape the heat! Feel the excitement as you spend your day in the world’s largest indoor snow park spanning 3,000 square meters of snowy wonder. Unlock a myriad of exciting activities with Ski Dubai Mall of Emirates Tickets including tobogganing, bobsledding, and the delightful penguin show. The Snow Pass ensures unlimited rides, guaranteeing endless enjoyment throughout the day. Your ticket covers all essential winter gear, including gloves and socks.",
    highlights: [
      "Full Day Ski Dubai Slope",
      "Ride a traditional toboggan down the snow hills or zoom down the twin-track bobsled",
      "Human hamster ball climbing",
      "Interactive games in the snow cavern and polish your snowballing skills",
      "Watch adorable penguins during the March of The Penguins",
    ],
    vitalInfo: [
      "No cancellations, refunds, or changes can be made",
      "Opening Hours: Sunday-Wednesday: 10:00-23:00, Thursday-Saturday: 10:00-00:00",
      "Unlimited access to Snow Park, Bob sledge, Tubing Run, and Bump Car",
      "Confirmation within minutes. Contact customer support if not received",
    ],
    additionalInfo: {
      Included: [
        "Full day and unlimited access to Snow Park, Bob sledge, Tubing Run, and Bump Car",
        "One-time Ride Chairlift and Ride Mountain Thriller",
        "Winter gear provided: jacket, trouser, etc.",
        "Free fleece gloves",
      ],
      Excluded: ["Personal expenses beyond the ticket", "Food and beverages"],
    },
  },
  {
    productName: "Global Village Ticket in Dubai",
    duration: "1 day",
    groupSize: "Unlimited",
    cancellation: "No Cancellation",
    price: 25,
    category: "ThemeParkActivites",
    currency: "AED",
    ratings: "4.07",
    imageUrl: [
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248719/Humrah%20Travels%20Version%202/Activities/Global%20Village%20Ticket/m6pzykenu675byrmxhxh.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248720/Humrah%20Travels%20Version%202/Activities/Global%20Village%20Ticket/q1wtaemu3b4hn0vxuiew.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248720/Humrah%20Travels%20Version%202/Activities/Global%20Village%20Ticket/krna98jqnhsdek550ulr.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248720/Humrah%20Travels%20Version%202/Activities/Global%20Village%20Ticket/pormnkdyuj7disj0kz2y.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248720/Humrah%20Travels%20Version%202/Activities/Global%20Village%20Ticket/fcve546mm6zut0jcfogm.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248721/Humrah%20Travels%20Version%202/Activities/Global%20Village%20Ticket/rew2puznhhuynwbmtep6.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248721/Humrah%20Travels%20Version%202/Activities/Global%20Village%20Ticket/sz5qqjvhrwfvk25ggjbb.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248721/Humrah%20Travels%20Version%202/Activities/Global%20Village%20Ticket/mknmqqdyyrxoswny04tq.jpg",
    ],
    location: {
      lat: 25.0677,
      lng: 55.3075,
    },
    overview:
      "Experience the world at Global Village Dubai, the largest multicultural festival park in Dubai. Explore replicas of famous landmarks, enjoy international cuisines, attend cultural performances, and thrill yourself with country-themed rides at the Carnival. Global Village offers a unique opportunity to immerse yourself in diverse cultures and entertainment from around the globe.",
    highlights: [
      "Explore Dubai’s biggest multicultural festival park, Global Village",
      "See spectacular replicas of world-renowned heritage sites such as the Colosseum, Taj Mahal, and more",
      "Enjoy world-class dining experiences from a collection of restaurants and cafes serving international delicacies",
      "Embark on country-themed rides at the Carnival and enjoy live shows",
      "Entry to Global Village with access to all the country pavilions and fountain show",
    ],
    vitalInfo: [],
    additionalInfo: {
      Included: [
        "Entry to Global Village",
        "Access to all the country pavilions",
        "Access to fountain show",
      ],
      Excluded: [],
    },
  },
  {
    productName: "Hatta Full Day Tour",
    duration: "1 day",
    groupSize: "Unlimited",
    cancellation: "No Cancellation",
    price: 149,
    category: "ThemeParkActivites",
    currency: "AED",
    ratings: "4",
    imageUrl: [
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720464193/ziad-al-halabi-NGLO-Gu82M4-unsplash_lj661k.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720464183/azher-zee-7zXqPO7MgZI-unsplash_qlld4c.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720464176/datingscout-b_BRaORHCY4-unsplash_tmmopg.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1720464170/mahmoud-sherif-Em67nbVKBGc-unsplash_omgi1i.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720464168/malek-irshaidat-aQXU0PUfQJo-unsplash_gi32oa.jpg",
    ],
    location: {
      lat: 24.7998,
      lng: 56.1279,
    },
    overview:
      "Embark on a full day adventure to Hatta, a picturesque town nestled in the Hajar Mountains of Dubai. Experience breathtaking landscapes, visit heritage sites like Hatta Heritage Village, enjoy outdoor activities such as kayaking in Hatta Dam, and explore the natural beauty of the region. This tour offers a perfect blend of culture, history, and adventure amidst stunning mountain scenery.",
    highlights: [
      "Explore Hatta, a scenic town in the Hajar Mountains",
      "Visit Hatta Heritage Village and learn about local culture",
      "Enjoy outdoor activities like kayaking at Hatta Dam",
      "Experience the natural beauty of Hatta's landscapes",
      "A full day tour with unlimited group size",
    ],
    vitalInfo: [],
    additionalInfo: {
      Included: [],
      Excluded: ["Personal expenses", "Meals (unless specified)"],
    },
  },
];

export const WaterParkActivites = [
  // {
  //   productName: "Laguna Waterpark Ticket in Dubai",
  //   duration: "1 day",
  //   groupSize: "Unlimited",
  //   cancellation: "No Cancellation",
  //   price: 100,
  //   currency: "AED",
  //   category: "WaterParkActivites",
  //   ratings: "5",
  //   imageUrl: [
  //     "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248810/Humrah%20Travels%20Version%202/Activities/Laguna%20Waterpark%20Ticket/ynzuisxbwktuql1kbuup.jpg",
  //     "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248811/Humrah%20Travels%20Version%202/Activities/Laguna%20Waterpark%20Ticket/fxgpjxaey4mbfmlrr5xo.jpg",
  //     "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248811/Humrah%20Travels%20Version%202/Activities/Laguna%20Waterpark%20Ticket/jpd9ube4myjmzjgka5up.jpg",
  //     "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248811/Humrah%20Travels%20Version%202/Activities/Laguna%20Waterpark%20Ticket/kjri5bawlen3c2uo6gac.jpg",
  //     "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248809/Humrah%20Travels%20Version%202/Activities/Laguna%20Waterpark%20Ticket/oxlsrrlhte6rzbbmj37l.jpg",
  //     "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248769/Humrah%20Travels%20Version%202/Activities/Laguna%20Waterpark%20Ticket/nx3rmf881sqsvoq6q5hl.jpg",
  //     "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248768/Humrah%20Travels%20Version%202/Activities/Laguna%20Waterpark%20Ticket/zdcmrjn3el7jvdgcgnwk.jpg",
  //     "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248768/Humrah%20Travels%20Version%202/Activities/Laguna%20Waterpark%20Ticket/bspxts5rhlgudiqkm3g2.jpg",
  //     "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248768/Humrah%20Travels%20Version%202/Activities/Laguna%20Waterpark%20Ticket/uare15hp2q5a50hkm2ix.jpg",
  //   ],
  //   location: {
  //     lat: 25.1388,
  //     lng: 55.2272,
  //   },
  //   overview:
  //     "Dive into a world of aquatic adventures at Laguna Waterpark in Dubai. Located in the heart of La Mer, this vibrant waterpark offers a variety of thrilling rides, a relaxing lazy river, and an infinity pool with stunning views. Perfect for families and thrill-seekers alike, Laguna Waterpark promises a day of unforgettable fun.",
  //   highlights: [
  //     "Experience the thrill of water slides and rides",
  //     "Relax in the lazy river and infinity pool",
  //     "Enjoy stunning views from the waterpark",
  //   ],
  //   vitalInfo: [
  //     "No re-entry after leaving the venue",
  //     "Outside food and drinks are not allowed",
  //     "Instant confirmation with customer support available",
  //   ],
  //   additionalInfo: {
  //     Included: ["Access to all rides and attractions"],
  //     Excluded: ["Personal expenses beyond the ticket", "Food and beverages"],
  //   },
  // },
  {
    productName: "Yas Waterworld Abu Dhabi Tickets",
    duration: "1 day",
    groupSize: "Unlimited",
    cancellation: "No Cancellation",
    price: 300,
    category: "WaterParkActivites",
    currency: "AED",
    ratings: "5",
    imageUrl: [
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721249019/Humrah%20Travels%20Version%202/Activities/Yas%20Waterworld%20Abu%20Dhabi/grb2hbequ0nubvpvadyp.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721249019/Humrah%20Travels%20Version%202/Activities/Yas%20Waterworld%20Abu%20Dhabi/ubyrotqljgs41njd0bcm.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721249018/Humrah%20Travels%20Version%202/Activities/Yas%20Waterworld%20Abu%20Dhabi/ywlndaio70ud6mxhofgp.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721249020/Humrah%20Travels%20Version%202/Activities/Yas%20Waterworld%20Abu%20Dhabi/v6io41rohvwkz8dr2k85.avif",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721249021/Humrah%20Travels%20Version%202/Activities/Yas%20Waterworld%20Abu%20Dhabi/sbop0n6abjjhco0fulhr.avif",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721249021/Humrah%20Travels%20Version%202/Activities/Yas%20Waterworld%20Abu%20Dhabi/ajz3qvgzpdgtz0ei7ll7.avif",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721249022/Humrah%20Travels%20Version%202/Activities/Yas%20Waterworld%20Abu%20Dhabi/mfrm7jxnm77nckgc2uw0.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721249022/Humrah%20Travels%20Version%202/Activities/Yas%20Waterworld%20Abu%20Dhabi/opteopinaza2m6tz2ydm.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721249023/Humrah%20Travels%20Version%202/Activities/Yas%20Waterworld%20Abu%20Dhabi/gdvfb2e53vlhz1ezsszz.jpg",
    ],
    location: {
      lat: 24.4957,
      lng: 54.6086,
    },
    overview:
      "Enjoy a day of excitement and adventure at Yas Waterworld Abu Dhabi. Located on Yas Island, this world-class waterpark offers over 40 exhilarating rides, slides, and attractions. Perfect for families and thrill-seekers, Yas Waterworld promises an unforgettable experience with something for everyone.",
    highlights: [
      "Experience over 40 rides, slides, and attractions",
      "Enjoy the world's first and largest hydromagnetic-powered tornado waterslide",
      "Relax in the wave pool or float along the lazy river",
    ],
    vitalInfo: [
      "No re-entry after leaving the venue",
      "Outside food and drinks are not allowed",
      "Instant confirmation with customer support available",
    ],
    additionalInfo: {
      Included: ["Access to all rides and attractions"],
      Excluded: ["Personal expenses beyond the ticket", "Food and beverages"],
    },
  },
  {
    productName: "Wild Wadi WaterPark Tickets",
    duration: "1 day",
    groupSize: "Unlimited",
    cancellation: "No Cancellation",
    price: 280,
    currency: "AED",
    category: "WaterParkActivites",
    ratings: "5",
    imageUrl: [
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721249002/Humrah%20Travels%20Version%202/Activities/Wild%20Wadi%20WaterPark/tp0efjnsto5kibo2l12j.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721249002/Humrah%20Travels%20Version%202/Activities/Wild%20Wadi%20WaterPark/vohnjivhdexifza1u1nw.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721249002/Humrah%20Travels%20Version%202/Activities/Wild%20Wadi%20WaterPark/eb3hxxshrt4ow0c7zyqd.avif",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721249003/Humrah%20Travels%20Version%202/Activities/Wild%20Wadi%20WaterPark/ldn6oadxlxxdtxf4fuoq.avif",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721249003/Humrah%20Travels%20Version%202/Activities/Wild%20Wadi%20WaterPark/erbypxuopjltfuznwoum.avif",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721249004/Humrah%20Travels%20Version%202/Activities/Wild%20Wadi%20WaterPark/cj8kh2wcynklyymft8qe.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721249004/Humrah%20Travels%20Version%202/Activities/Wild%20Wadi%20WaterPark/owwx9mbhyufhfxtsnuqi.avif",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721249005/Humrah%20Travels%20Version%202/Activities/Wild%20Wadi%20WaterPark/lpqyetyauhuhc1a5fcvz.jpg",
    ],
    location: {
      lat: 25.1412,
      lng: 55.1894,
    },
    overview:
      "Escape the heat and dive into fun at Wild Wadi WaterPark in Dubai. Located next to the iconic Burj Al Arab, Wild Wadi offers a variety of thrilling rides, wave pools, and relaxing river experiences. With something for all ages, Wild Wadi promises a day of excitement and adventure for families and thrill-seekers alike.",
    highlights: [
      "Experience thrilling rides and wave pools",
      "Relax on the lazy river and enjoy scenic views",
      "Located next to the iconic Burj Al Arab",
    ],
    vitalInfo: [
      "No re-entry after leaving the venue",
      "Outside food and drinks are not allowed",
      "Instant confirmation with customer support available",
    ],
    additionalInfo: {
      Included: ["Access to all rides and attractions"],
      Excluded: ["Personal expenses beyond the ticket", "Food and beverages"],
    },
  },
  {
    productName: "Atlantis Aquaventure WaterPark Tickets",
    duration: "1 day",
    groupSize: "Unlimited",
    cancellation: "No Cancellation",
    price: 310,
    currency: "AED",
    category: "WaterParkActivites",
    ratings: "5",
    imageUrl: [
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720872071/Humrah%20Travels%20Version%202/Activities/Atlantis%20Aquaventure%20WaterPark/i9yj0uem3wkwtsqxphhc.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720871987/Humrah%20Travels%20Version%202/Activities/Atlantis%20Aquaventure%20WaterPark/wg5ughmrjeyr7pwzha0l.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720872246/Humrah%20Travels%20Version%202/Activities/Atlantis%20Aquaventure%20WaterPark/zcsgr8o1noxpau0elykq.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248477/Humrah%20Travels%20Version%202/Activities/Atlantis%20Aquaventure%20WaterPark/qbxewhhaaeatbscnz416.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248477/Humrah%20Travels%20Version%202/Activities/Atlantis%20Aquaventure%20WaterPark/zrshi1fk30vyhyjmqnxq.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248477/Humrah%20Travels%20Version%202/Activities/Atlantis%20Aquaventure%20WaterPark/mi2u8on51nxrpizk5hor.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248477/Humrah%20Travels%20Version%202/Activities/Atlantis%20Aquaventure%20WaterPark/emdk4buqtfvmsdh0nibu.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248477/Humrah%20Travels%20Version%202/Activities/Atlantis%20Aquaventure%20WaterPark/zkcrw7ecttklzwzunagb.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248477/Humrah%20Travels%20Version%202/Activities/Atlantis%20Aquaventure%20WaterPark/tm4mff0praxvrpbwnygm.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248478/Humrah%20Travels%20Version%202/Activities/Atlantis%20Aquaventure%20WaterPark/w7ufixunth7qo3yupgvt.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248478/Humrah%20Travels%20Version%202/Activities/Atlantis%20Aquaventure%20WaterPark/xuwfhpvdzqiyoyvs8gt7.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1721248478/Humrah%20Travels%20Version%202/Activities/Atlantis%20Aquaventure%20WaterPark/wzag32jf4xm2ga69y4pb.jpg",
    ],
    location: {
      lat: 25.1304,
      lng: 55.1173,
    },
    overview:
      "Discover a world of adventure at Atlantis Aquaventure WaterPark in Dubai. Located on the iconic Palm Jumeirah, this expansive waterpark offers thrilling rides, a private beach, and close encounters with marine life. Perfect for families and adventure seekers, Aquaventure promises a day of fun and excitement.",
    highlights: [
      "Experience thrilling rides and slides",
      "Relax on a private beach",
      "Enjoy close encounters with marine life",
    ],
    vitalInfo: [
      "No re-entry after leaving the venue",
      "Outside food and drinks are not allowed",
      "Instant confirmation with customer support available",
    ],
    additionalInfo: {
      Included: ["Access to all rides and attractions"],
      Excluded: ["Personal expenses beyond the ticket", "Food and beverages"],
    },
  },
];

export const carouselActivites = [
  {
    productName: "Observation Deck",
  },
  {
    productName: "SightSeein Tours",
  },
  {
    productName: "Theme Parks",
  },
  {
    productName: "Water Activites",
  },
];

export const marinaCruises = [
  {
    productName: "Luxury Glass Cruise Catamaran Dubai Marina",
    price: 139,
    currency: "AED",
    category: "Luxury",
    type: "cruises",
    ratings: "",
    menuImages:[
      'https://res.cloudinary.com/dj9xbypxz/image/upload/v1720694003/DSC02066_copy_z51202.jpg',
      'https://res.cloudinary.com/dj9xbypxz/image/upload/v1720694007/DSC02194_copy_q3yjze.jpg',
      'https://res.cloudinary.com/dj9xbypxz/image/upload/v1720694010/DSC02183_copy_c4q68j.jpg',
      'https://res.cloudinary.com/dj9xbypxz/image/upload/v1720694015/DSC02073_copy_jxjfrn.jpg',
      'https://res.cloudinary.com/dj9xbypxz/image/upload/v1720694024/DSC02120_copy_ii0d49.jpg',
      'https://res.cloudinary.com/dj9xbypxz/image/upload/v1720694031/DSC02168_copy_rscs6d.jpg',
      'https://res.cloudinary.com/dj9xbypxz/image/upload/v1720694024/DSC02253_copy_xuuqud.jpg'
    ],
    imageUrl: [
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720693988/DSC01681_copy_wjfolh.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720693989/DSC01587_copy_m53rmq.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720693989/DSC01587_copy_m53rmq.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720694019/DSC01863_copy_fvbdev.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720694024/DSC02253_copy_xuuqud.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720693990/DSC01924_copy_j9fyhf.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720694024/DSC02120_copy_ii0d49.jpg",
    ],
    location: {
      lat: 25.0804,
      lng: 55.1403,
    },
    overview:
      "Experience the breathtaking views of Dubai Marina's skyline with the Luxury Glass Cruise. Enjoy the enchanting Tanoura Dance Show and soulful background music as you sail through the iconic waterfront. Capture unforgettable memories on this remarkable journey.",
    highlights: [
      // "2-hour cruise through Dubai Marina, Jumeirah Beach Residences, Blue Water Island, and The Dubai Eye",
      // "Welcome drinks and Kahwa with dates",
      // "Gourmet international buffet dinner with Arabic and Asian cuisines",
      // "Fully air-conditioned lower deck and open-air upper deck",
      // "Unlimited soft drinks, mineral water, tea, and coffee",
      // "Tanoura Dance Show and soft background music in English and Arabic",
      // "Children under four years old enjoy free entry",
      " Pick up and drop off from your location – Optional/ additional cost",
      "Cruise will start 8pm to 10pm",
      "2 hours cruising along Dubai Atlantis Hotel Dubai Marina, Jumeirah Beach Residences, Blue Water Island and The Dubai Eye",
      "International Buffet Dinner (Arabic & Asian) Varieties of Vegetarian and Non-vegetarian dishes",
      "Fully Air-conditioned lower deck and open-air upper deck",
      "Soft music entertainment (English and Arabic)",
      "Unlimited soft drinks, mineral water, tea and coffee",
      "Tanoura Dance Show",
      "Child below four years old is free",
    ],
    landmarks: [
      "Marina Mall Pier 7",
      "Twisted Tower",
      "Jumeirah Beach",
      "Marina Promenade",
    ],
    activities: [],
    included: [
      "Welcome drinks and Kahwa with dates",
      "2-hour cruise along Dubai Marina and landmarks",
      "International buffet dinner",
      "Air-conditioned lower deck and open-air upper deck",
      "Unlimited soft drinks, mineral water, tea, and coffee",
      "Tanoura Dance Show",
      "Soft background music in English and Arabic",
    ],
    excluded: [
      "Transportation (optional/additional cost)",
      "Gratuities (optional)",
    ],
    vitalInfo: [
      "Duration: 2 hours",
      "Operating days: Daily",
      "Suitable for all age groups",
      "Location: Dubai Marina",
      "Dress code: Casual in summer, light jacket in winter",
      "Licensed professional crew",
      "Safety equipment provided: First-aid kits, navigation equipment, life jackets",
      "Clean and well-maintained cruises",
      "Respect local customs and guidelines",
      "Cancellation policy in effect",
    ],
    safetyMeasure:
      "Experienced captains and crew, navigation equipment, fire extinguishers, first aid kits, life jackets.",
    voyage:
      "Enjoy a 2-hour cruise along Dubai Marina, Jumeirah Beach Residences, Blue Water Island, and The Dubai Eye. Marvel at the illuminated skyline and iconic landmarks through the glass walls, offering unparalleled views. Capture stunning photos and immerse yourself in the vibrant atmosphere of Dubai's waterfront.",
    dinnerOptions:
      "Savor a gourmet international buffet featuring Arabic and Asian dishes on the Luxury Glass Cruise Dubai Marina. Delight in a variety of vegetarian and non-vegetarian options prepared by expert chefs. Enjoy a range of beverages, including soft drinks, mineral water, tea, and coffee. Experience exceptional onboard service and a delightful dining atmosphere.",
    duration: "2 hours",
    groupSize: "Unlimited",
    languages: "English, Arabic",
    cancellation: "Up to 1 day",
  },
  {
    productName: "Lotus 5 Star Cruise marina",
    price: 249,
    currency: "AED",
    ratings: "",
    type: "cruises",
    menuImages:[
      'https://res.cloudinary.com/dj9xbypxz/image/upload/v1720694192/IMG-20240331-WA0018_e7qzkj.jpg',
      'https://res.cloudinary.com/dj9xbypxz/image/upload/v1720694189/IMG-20240313-WA0016_szuzzs.jpg',
      'https://res.cloudinary.com/dj9xbypxz/image/upload/v1720694189/IMG-20240313-WA0016_szuzzs.jpg',
      'https://res.cloudinary.com/dj9xbypxz/image/upload/v1720694188/IMG-20240331-WA0019_u2fe45.jpg',
      'https://res.cloudinary.com/dj9xbypxz/image/upload/v1720694190/IMG-20240313-WA0023_vk44un.jpg',
      'https://res.cloudinary.com/dj9xbypxz/image/upload/v1720694190/IMG-20240313-WA0020_ofotde.jpg',
      'https://res.cloudinary.com/dj9xbypxz/image/upload/v1720694191/IMG-20240313-WA0019_kpttgl.jpg'
    ],
    category: "Luxury",
    imageUrl: [
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720694189/IMG-20240313-WA0017_x7f16b.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720694189/IMG-20240313-WA0022_yngrgo.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720694189/IMG-20240313-WA0018_cic1fe.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720694190/IMG-20240313-WA0023_vk44un.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720694190/IMG-20240313-WA0021_tkejx0.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720694192/IMG-20240331-WA0018_e7qzkj.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720694189/IMG-20240313-WA0016_szuzzs.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720694188/IMG-20240331-WA0019_u2fe45.jpg",
    ],
    location: {
      lat: 25.0804,
      lng: 55.1403,
    },
    overview:
      "Experience an exceptional evening on the Arabian Gulf with the 5-Star Mega Yacht Dinner Cruise Marina. Enjoy breathtaking views of Dubai's stunning skyscrapers, premium services, and unforgettable moments with your loved ones. Capture every moment of this memorable journey and relish the unique onboard experience.",
    highlights: [
      "3-hour cruise starting from Dubai Marina, passing Jumeirah Beach Residences, Bluewaters Island, and Atlantis The Palm (subject to weather conditions)",
      "Welcome drinks, tea & coffee, and mineral water",
      "5-star International Multi-dishes Buffet by The Westin Dubai Mina Seyahi Hotel",
      "Live Cooking Stations of BBQ, Salad Bar, and more",
      "Impressive Entertainment Decks – Rooftop Walkway, Dining Deck, Sun Deck",
      "Entertainment Shows (except during Ramadan & days prohibited by government authorities)",
      "Bar (alcoholic beverages available for an extra charge)",
      "Well-maintained washroom facilities for men and women",
      "Smoking area allowed on the upper deck only",
    ],
    landmarks: [
      "Ain Dubai",
      "Dubai Marina Promenade",
      "Jumeirah Beach Residences",
      "Bluewater Island",
      "Atlantis The Palm (subject to weather conditions)",
    ],
    activities: [
      "Live DJ",
      "Aerial Ring",
      "Aerial Silk",
      "Show Ballet",
      "Go-go Dance",
      "Magician",
      "Saxophone Player",
      "Tanoura Dance",
      "Background Music in Arabic, Hindu, and English",
    ],
    included: [
      "Welcome drinks, tea & coffee, and mineral water",
      "3-hour cruise along Dubai Marina and landmarks",
      "5-star International Multi-dishes Buffet by The Westin Dubai Mina Seyahi Hotel",
      "Live Cooking Stations of BBQ, Salad Bar, and more",
      "Impressive Entertainment Decks",
      "Entertainment Shows (except during Ramadan & days prohibited by government authorities)",
      "Bar (alcoholic beverages available for an extra charge)",
      "Smoking area on the upper deck only",
    ],
    excluded: [
      "Transportation (optional/additional cost)",
      "Gratuities (optional)",
    ],
    vitalInfo: [
      "Duration: 3 hours",
      "Operating days: Daily",
      "Suitable for all age groups",
      "Location: Dubai Marina",
      "Dress code: Casual in summer, light jacket in winter",
      "Licensed professional crew",
      "Safety equipment provided: First-aid kits, navigation equipment, life jackets",
      "Clean and well-maintained cruises",
      "Respect local customs and guidelines",
      "Cancellation policy in effect",
    ],
    safetyMeasure:
      "Experienced captains and crew, navigation equipment, fire extinguishers, first aid kits, life jackets.",
    voyage:
      "Enjoy a 3-hour cruise from Dubai Marina, passing Jumeirah Beach Residences, Bluewaters Island, and Atlantis The Palm. Marvel at the illuminated skyline and iconic landmarks while savoring a gourmet international buffet with live cooking stations. Capture stunning photos and immerse yourself in the vibrant atmosphere of Dubai's waterfront.",
    dinnerOptions:
      "Savor a luxurious international buffet by The Westin Dubai Mina Seyahi Hotel on the 5-Star Mega Yacht Dinner Cruise Marina. Delight in live cooking stations of BBQ, salad bars, and more. Enjoy unlimited soft drinks, tea, coffee, refreshing juice, and mineral water. Experience exceptional onboard service and a delightful dining atmosphere.",
    duration: "3 hours",
    groupSize: "Unlimited",
    languages: "English, Arabic, Hindu",
    cancellation: "Up to 1 day",
  },
  {
    productName: "Floating Restaurant Dinner Cruise Marina",
    price: 129,
    currency: "AED",
    type: "cruises",
    category: "Dhow Cruises",
    menuImages:[
      'https://res.cloudinary.com/dj9xbypxz/image/upload/v1720675642/9958024d-cc53-49c1-96b3-e7f1595af2d8.png',
      'https://res.cloudinary.com/dj9xbypxz/image/upload/v1720675576/a67f3e2c-3adf-420b-b3ee-e7e39618f5f4.png',
      'https://res.cloudinary.com/dj9xbypxz/image/upload/v1720675551/fcd23336-fb53-4a19-95bb-ea5140fa78dd.png',
      'https://res.cloudinary.com/dj9xbypxz/image/upload/v1720675576/a67f3e2c-3adf-420b-b3ee-e7e39618f5f4.png',
      'https://res.cloudinary.com/dj9xbypxz/image/upload/v1720675614/30e24613-6873-4b60-861c-ab355cc25ecb.png',
      'https://res.cloudinary.com/dj9xbypxz/image/upload/v1720675614/30e24613-6873-4b60-861c-ab355cc25ecb.png',
      'https://res.cloudinary.com/dj9xbypxz/image/upload/v1720675551/fcd23336-fb53-4a19-95bb-ea5140fa78dd.png'
    ],
    ratings: "",
    imageUrl: [
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720675482/0566ead7-1d2f-4a1a-8edd-c94e155a6e59.png",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720675401/630fcae7-12ae-4d4e-82de-d528848fbaff.png",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720675515/1b8191e9-156c-4573-b987-c61e1cad6d8d.png",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720675614/30e24613-6873-4b60-861c-ab355cc25ecb.png",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720675551/fcd23336-fb53-4a19-95bb-ea5140fa78dd.png",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720675642/9958024d-cc53-49c1-96b3-e7f1595af2d8.png",
    ],
    location: {
      lat: 25.0804,
      lng: 55.1403,
    },
    overview:
      "Experience an exquisite afternoon on the Floating Restaurant Dinner Cruise in Dubai Marina. Indulge in luxury and breathtaking views of iconic landmarks while enjoying a lavish brunch onboard. Perfect for special occasions, corporate events, or simply exploring Dubai's beauty, this cruise promises an unforgettable and sophisticated experience.",
    highlights: [
      "Cruising for 2 hours and 30 minutes",
      "Panoramic views starting from Dubai Marina, passing Jumeirah Beach Residences, Bluewaters Island, and Atlantis The Palm",
      "Welcome drinks, tea, coffee, and mineral water",
      "5-star quality International Buffet Multi-dishes by Hotel The Westin Dubai Mina Seyahi",
      "Live cooking stations for barbeque, sushi, and salads",
      "Impressive Entertainment Decks – The chill lounge, children cinema, Rooftop Walkway, Dining Deck, Sun Deck",
      "Access to fully air-conditioned lower deck and open-air upper deck",
      "An indoor swimming pool with a Jacuzzi",
      "Entertainment Shows (except during Ramadan)",
      "Bar (alcoholic beverages available for an extra charge)",
      "Well-sanitized washroom facilities for men and women",
      "Smoking area allowed on the upper deck only",
    ],
    landmarks: [
      "Dubai Skyline",
      "JBR",
      "Blue Waters Island",
      "Palm Islands",
      "Dubai Eye",
    ],
    activities: [
      "Live DJ",
      "Swimming in the indoor pool with Jacuzzi",
      "Live music and dancing",
      "Background Music in Arabic, Hindu, and English",
    ],
    included: [
      "Welcome drinks, tea, coffee, and mineral water",
      "2 hours and 30 minutes cruise along Dubai Marina and landmarks",
      "5-star quality International Buffet Multi-dishes by Hotel The Westin Dubai Mina Seyahi",
      "Live cooking stations for barbeque, sushi, and salads",
      "Impressive Entertainment Decks",
      "Entertainment Shows (except during Ramadan)",
      "Bar (alcoholic beverages available for an extra charge)",
      "Well-sanitized washroom facilities for men and women",
      "Smoking area on the upper deck only",
    ],
    excluded: [
      "Transportation (optional/additional cost)",
      "Gratuities (optional)",
    ],
    vitalInfo: [
      "Duration: 2 hours and 30 minutes",
      "Operating days: Daily",
      "Suitable for all age groups",
      "Location: Dubai Marina",
      "Dress code: Casual in summer, light jacket in winter",
      "Licensed professional crew",
      "Safety equipment provided: First-aid kits, navigation equipment, life jackets",
      "Clean and well-maintained cruises",
      "Respect local customs and guidelines",
      "Cancellation policy in effect",
    ],
    safetyMeasure:
      "Experienced captains and crew, navigation equipment, fire extinguishers, first aid kits, life jackets.",
    voyage:
      "Embark on a 2 hour and 30 minutes cruise from Dubai Marina, taking in panoramic views of Jumeirah Beach Residences, Bluewaters Island, and Atlantis The Palm. Enjoy a lavish brunch, live cooking stations, and an indoor swimming pool with a Jacuzzi while cruising through the waters of Dubai.",
    dinnerOptions:
      "Enjoy a 5-star International Buffet Multi-dishes by Hotel The Westin Dubai Mina Seyahi, featuring live cooking stations for barbeque, sushi, and salads. Relish unlimited soft drinks, tea, coffee, refreshing juice, and mineral water. Experience exceptional service and a delightful dining atmosphere onboard.",
    duration: "2 hours and 30 minutes",
    groupSize: "Unlimited",
    languages: "English, Arabic, Hindu",
    cancellation: "Up to 1 day",
  },
];

export const creekCruises = [
  {
    productName: "Dhow Cruise Al Seef",
    price: 60,
    currency: "AED",
    category: "Dhow Cruise",
    type: "cruises",
    ratings: "",
    imageUrl: [
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720679193/Ramee-3-scaled_htryob.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720679050/20240614_191744_fdgmof.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720679055/20240614_184132_jhfcao.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720679052/20240614_195352_krphnk.jpg",
      "https://res.cloudinary.com/dj9xbypxz/image/upload/v1720679055/20240614_184132_jhfcao.jpg",
    ],
    location: {
      lat: 25.276987,
      lng: 55.296249,
    },
    overview:
      "Explore Dubai Creek on a traditional dhow cruise, blending old-world charm with modern luxury. Enjoy stunning views of illuminated cityscapes, iconic landmarks, and vibrant street life. This 2-hour cruise offers a buffet-style dining experience with local and international cuisines, live entertainment, and exceptional service.",
    highlights: [
      "Cruise along Deira Creek and Bur Dubai Creek for breathtaking city views.",
      "Red carpet arrival with a welcome non-alcoholic drink.",
      "Traditional Arabian wooden dhow with two tiers: an air-conditioned lower deck and an open-air upper deck.",
      "Savor an international buffet dinner with vegetarian and non-vegetarian options.",
      "Enjoy soft drinks, juice, mineral water, tea, and coffee.",
      "Live entertainment including Tanoura dance show.",
      "Optional pick-up and drop-off services available.",
    ],
    menuImages:[
      'https://res.cloudinary.com/dj9xbypxz/image/upload/v1720679057/20240614_184053_g5tqru.jpg',
      'https://res.cloudinary.com/dj9xbypxz/image/upload/v1720679055/20240614_184132_jhfcao.jpg',
      'https://res.cloudinary.com/dj9xbypxz/image/upload/v1720679055/20240614_184132_jhfcao.jpg',
      'https://res.cloudinary.com/dj9xbypxz/image/upload/v1720679055/20240614_184132_jhfcao.jpg',
      'https://res.cloudinary.com/dj9xbypxz/image/upload/v1720679054/20240614_193417_dtavjn.jpg',
      'https://res.cloudinary.com/dj9xbypxz/image/upload/v1720679049/20240614_184223_q9fkvo.jpg',
      'https://res.cloudinary.com/dj9xbypxz/image/upload/v1720679047/20240614_184214_mqwvkv.jpg'
    ],
    activities: [],
    included: [
      "Red carpet welcome",
      "Non-alcoholic welcome drink",
      "Two-tiered traditional dhow cruise",
      "Two hours of cruising",
      "Air-conditioned lower deck",
      "Open-air upper deck",
      "International buffet dinner",
      "Soft drinks, juice, mineral water, tea, and coffee",
    ],
    excluded: ["Gratuities (optional)", "Additional services"],
    vitalInfo: [
      "Duration: 2 hours (multiple time slots available)",
      "Operating days: Daily",
      "Children under 4 years enter free",
      "Minimum participants: 2",
      "Cruising time: 8:30 pm to 10:30 pm",
      "Boarding time: 8:00 pm",
      "Booking confirmation at the time of reservation",
      "Cancellation: At least 1 day before the trip",
    ],
    safetyMeasure:
      "Experienced captains and crew, navigation equipment, fire extinguishers, first aid kits, life jackets.",
    voyage:
      "Embark on a 2-hour journey along Dubai Creek, witnessing the city's illuminated skyline and cultural landmarks. The cruise passes iconic spots like the Gold Souk, Spice Souk, Hyatt Regency, Al Maktoum Bridge, and vibrant street markets. Enjoy the mesmerizing contrast between the old-world charm of traditional dhows and the modern skyline. Capture memorable photos and immerse yourself in Dubai's rich cultural tapestry.",
    dinnerOptions:
      "Experience a 3-star buffet-style dining adventure on the Dubai Deira Creek Cruise, offering a variety of international cuisines including aromatic Arabian dishes prepared by experienced chefs. The buffet caters to all palates with both vegetarian and non-vegetarian options. Enjoy a delightful culinary experience in an elegant ambiance. Hassle-free transportation from multiple pick-up locations in central Dubai is available, ensuring a seamless start to your evening.",
    duration: "2 hours",
    groupSize: "Unlimited",
    languages: "English, Arabic, Hindi",
    cancellation: "Up to 1 day",
  },
];
