import React, { useEffect, useRef, useState } from "react";
import "../YachtCarouselPage/YachtCarouselPage.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { cruiseBannerImage, luxuryYachtImage } from "../../../Images/carouselmages";
import CarouselComponent from "../../../components/CarouselComponent/CarouselComponent";
import RecomendedSlider from "../../../components/RecomendedSlider/RecomendedSlider";
import { IoMdStar } from "react-icons/io";
import { Link, useParams } from "react-router-dom";
import {
  creekCruises,
  cruises,
  marinaCruises,
  popularActivites,
  popularCruises,
  popularSafaris,
  popularYachts,
} from "../../../cruiseData";
import Poppular from "../Main/PopularCruise/Popular";
import Marina from "../Main/Marina/Marina";
import Greek from "../Main/Greek/Greek";
import Canal from "../Main/Canal/Canal";
import CruiseSlider from "../Main/CruiseSlider/CruiseSlider"
import NewSafariBannerImgOne from '../../../Images/Banner-Desert-Safari-Image-one.png';


const CruisesCarouselPage = () => {
  const { productName } = useParams();
  const [activeTab, setActiveTab] = useState("Desert Safari");
  const [SafariData, setSafariData] = useState([]);
  const [data, setData] = useState(cruises);

  // Create refs for each section
  const popularRef = useRef(null);
  const marinaRef = useRef(null);
  const greekRef = useRef(null);
  const canalRef = useRef(null);

  useEffect(() => {
    const cruise = cruises.find(
      (item) => item.productName.replace(/ /g, "-") === productName
    );
    setSafariData(cruise);

    setData([...marinaCruises, ...creekCruises]);
  }, [productName]);

  const handleScroll = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };


  const populardata = [...popularYachts,...popularSafaris]

  const formatproductName = (productName) => {
    return productName.replace(/ /g, "-");
  };

  return (
    <>
      <div className="flex w-full flex-col 2xl:justify-center 2xl:items-center">
      <div className="CruisesCarousel">
        <div className="carousel-image-placeholder">
          <LazyLoadImage
              alt={"review2"}
              effect="blur" 
              src={cruiseBannerImage}
              className="carousel-img"
            />
          <LazyLoadImage
              alt={"review2"}
              effect="blur" 
              src={'https://res.cloudinary.com/dj9xbypxz/image/upload/v1722536989/123_1_qn9gbz.png'}
              className="carousel-img-mobile"
            />
        </div>
      </div>
        <div className="flex h-full w-full 2xl:mx-0 2xl:w-11/12 flex-col">
          <div className="w-full min-h-[18vh] flex flex-col mx-auto px-4 py-8">
            <div className="sticky top-0 bg-white z-10 md:px-6  w-full">
              <div className="flex space-x-6 mb-6">
                <div
                  className="text-gray-800 text-sm md:text-base border-b-2 border-gray-800 pb-2 cursor-pointer"
                  onClick={() => handleScroll(popularRef)}
                >
                  Popular Options
                </div>
                <div
                  className="text-gray-600 hover:text-gray-800 text-sm md:text-base cursor-pointer"
                  onClick={() => handleScroll(marinaRef)}
                >
                  Marina
                </div>
                <div
                  className="text-gray-600 hover:text-gray-800 text-sm md:text-base cursor-pointer"
                  onClick={() => handleScroll(greekRef)}
                >
                  Creek
                </div>
                <div
                  className="text-gray-600 text-sm md:text-base hover:text-gray-800"
                  onClick={() => handleScroll(canalRef)}
                >
                  Canal
                </div>
              </div>
            </div>
            <div ref={popularRef} className="flex w-full h-full">
              <Poppular />
            </div>
            <div ref={marinaRef} className="flex w-full mt-8 md:mt-14 h-full">
              <Marina />
            </div>
            <div ref={greekRef} className="flex w-full mt-8 md:mt-14 h-full">
              <Greek />
            </div>
            <div ref={canalRef} className="flex mt-8 md:mt-14 w-full h-full">
              <Canal />
            </div>
          </div>
        </div>
        
        <div className="tour-title px-4">Popular Tours</div>
        <div className="tours-details-section px-[60px]">
          <RecomendedSlider populardata={populardata}/>
        </div>
        <div className="activity-container px-4">
          <div className="tour-titl px-4">
            <h2>Create best experience for your trip to Dubai</h2>
          </div>
          <div className="desc-cont px-4">
            <p>
            Create the best experience for your trip to Dubai with our exceptional services, tailored to ensure every moment of your journey is unforgettable.
            </p>
          </div>
          <div className="menu-cont px-4">
          {popularActivites.map((item, index) => (
            <>
              <div key={index} className="min-w-full  border-2 p-2 mb-5 md:mb-3 cursor-pointer  bg-[#FFFFFF] sm:min-w-[calc(50%-1rem)] md:min-w-[calc(33.333%-1rem)] lg:min-w-[calc(25%-1rem)] xl:min-w-[20%] rounded-lg overflow-hidden">
             <Link
                to={`/activities`}
                className="CardImageOne"
              >
              <div 
                className="h-48 bg-normal1 rounded-md bg-cover bg-no-repeat relative overflow-hidden"
                style={{ backgroundImage: `url(${item?.imageUrl[0]})` }}
                >
                <LazyLoadImage
                  alt={"cardimage"}
                  effect="blur"
                  src={item?.imageUrl[0]}
                  className="hidden"
                  />
              </div>
              <div className="py-4 px-1">
                <div className="flex w-full justify-between ">
                  <h3 className="md:text-lg font-semibold overflow-hidden whitespace-nowrap text-ellipsis">
                    {item.productName}
                  </h3>
                  <div className="flex items-center text-sm overflow-hidden whitespace-nowrap text-ellipsis">
                    <span className="text-yellow-500">&#9733;</span>
                    <span className="ml-2 pr-3">{item.ratings}(21.1k)</span>
                  </div>
                </div>
                <p className="mt-2 text-xs md:text-sm text-[#2D2D2D]">Starting From <b>{item.price}</b> AED</p>
              </div>
            </Link>
            </div>
             </>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default CruisesCarouselPage;