import React from "react";
import '../ImageSliderHomePageComponent/ImageSliderHomePageComponent.css';
// Import css filess
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { popularSafaris, yachts, cruises } from '../../cruiseData';
import { Link } from 'react-router-dom';

export default function ImageSliderHomePageComponent() {
    const settings = {
        dots: true,
        autoplay: true,
        autoplaySpeed: 3500,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    const formatproductName = (productName) => {
        return productName.replace(/ /g, "-");
      };

    return (
        <Slider {...settings}>
             <Link
                  to={"/cruises/Lotus-5-Star-Cruise-marina"}
                >
                <img 
                    src='https://res.cloudinary.com/dj9xbypxz/image/upload/v1721664371/Group_645_1_gbpzjl.png' 
                    className="HomeBannerImage" 
                    alt="Home Banner 1"
                />          
            </Link>
            <Link
                  to={"/safaris/Jeep-Wrangler-Desert-Safari-Dubai"}
                >
                <img 
                    src='https://res.cloudinary.com/dj9xbypxz/image/upload/v1721662528/Group_648_lrf1rf.jpg' 
                    className="HomeBannerImage" 
                    alt="Home Banner 2"
                />          
            </Link>
            <Link
                  to={"/yachts/Columbus-Yacht-dubai"}
                >
                <img 
                    src='https://res.cloudinary.com/dj9xbypxz/image/upload/v1721662517/Group_647_tukkwl.jpg' 
                    className="HomeBannerImage" 
                    alt="Home Banner 3"
                />          
            </Link>
        </Slider>
    );
}
