import React, { useRef } from 'react';
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from 'swiper/react';
import { safaris, yachts } from '../../../../cruiseData.js';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';

const SafariSection = () => {
  const sliderRef1 = useRef(null);

  const handleNext1 = () => {
    if (sliderRef1.current) {
      sliderRef1.current.swiper.slideNext();
    }
  };

  const handlePrev1 = () => {
    if (sliderRef1.current) {
      sliderRef1.current.swiper.slidePrev();
    }
  };

  const formatproductName = (productName) => {
    return productName.replace(/ /g, "-");
  };
  return (
    <div className="w-full min-h-[18vh] flex 2xl:px-12 px-5 flex-col">
      <div className="flex w-full justify-between min-h-[8vh] items-center">
        <h2 className="md:text-2xl font-bold mb-1 text-[#2D2D2D] px-2 md:px-5">Desert Safari</h2>
        <div className="flex items-center space-x-4">
          {/* Navigation buttons can be re-enabled here */}
        </div>
      </div>
      <div className="flex justify-between items-center mb-4">
        <div className="flex w-full text-sm md:text-base text-[#3B474B] md:-mt-2 flex-wrap justify-between mb-8 sm:mb-0 px-2 md:px-5">
        Embark on an exhilarating safari through Dubai's majestic desert landscapes in unparalleled comfort and style. 
        Savor gourmet cuisine under the stars, experience thrilling dune rides, and witness breathtaking sunsets.
         Book your safari adventure today and elevate your Dubai experience to new heights!
        </div>
      </div>

      <div className="overflow-hidden mt-3 grid gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
        {safaris.map((item, index) => (
          <div 
            key={index} 
            className="min-w-full bg-[#FFFFFF]  border-2 p-2 cursor-pointer rounded-lg overflow-hidden"
          >
            <Link
                  to={`/safaris/${formatproductName(
                    item?.productName
                  )}`}
                  >
            <div 
              className="h-48 bg-normal1 rounded-md bg-cover bg-no-repeat relative overflow-hidden"
              style={{ backgroundImage: `url(${item?.imageUrl[0]})` }}
            >
              <LazyLoadImage
                alt={"cardimage"}
                effect="blur"
                src={item?.imageUrl[0]}
                className="hidden"
              />
            </div>
            <div className="py-4 px-1">
              <div className="flex w-full justify-between ">
                <h3 className="md:text-lg text-[#2D2D2D] font-semibold overflow-hidden whitespace-nowrap text-ellipsis">
                  {item.productName}
                </h3>
              </div>
                <div className="flex items-center text-sm overflow-hidden whitespace-nowrap text-ellipsis">
                  <span className="text-yellow-500 text-xl pl-2">&#9733;</span>
                  <span className="ml-[1px] pr-3 overflow-hidden whitespace-nowrap text-ellipsis">{item.ratings}</span>
                </div>
              <p className="mt-2 text-xs md:text-sm text-[#2D2D2D]">Starting From <b>{item.price}</b> AED</p>
            </div>
            </Link>

          </div>
        ))}
      </div>
    </div>
  );
};

export default SafariSection;
