import React from "react";
import { yachts } from "../../../../cruiseData.js";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";

const ActivityBox = ({ data }) => {
  const formatproductName = (productName) => {
    return productName.replace(/ /g, "-");
  };

  return (
    <div className="w-full min-h-[18vh] flex flex-col 2xl:px-12">
      <div className="overflow-hidden mt-3 grid gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        {data.map((item, index) => (
          <div
            key={index}
            className="min-w-full p-3  cursor-pointer border-2 border-gray-300 bg-[#FFFFFF] rounded-lg overflow-hidden"
          >
            <Link to={`/activities/${item.category}/${formatproductName(item?.productName)}`}>
              <div
                className="h-48 bg-normal1 rounded-md bg-cover bg-no-repeat relative overflow-hidden"
                style={{ backgroundImage: `url(${item?.imageUrl[0]})` }}
              >
                <LazyLoadImage
                  alt={"cardimage"}
                  effect="blur"
                  src={item?.imageUrl[0]}
                  className="hidden"
                />
              </div>
              <div className="py-4 px-1">
                <div className="flex w-full justify-between ">
                  <h3 className="text-lg text-[#2D2D2D] font-semibold overflow-hidden whitespace-nowrap text-ellipsis">
                    {item.productName}
                  </h3>
                  <div className="flex items-center text-sm overflow-hidden whitespace-nowrap text-ellipsis">
                    <span className="text-yellow-500 text-xl pl-2">
                      &#9733;
                    </span>
                    <span className="ml-2 pr-3">{item.ratings}(21.1k)</span>
                  </div>
                </div>
                <p className="mt-2 text-sm text-[#2D2D2D]">
                  Starting From <b>{item.price}</b> AED
                </p>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ActivityBox;
