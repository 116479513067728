import luxuryYacht from '../Yatch-Cruise-banner-Img-One.jpg'
import InternationalVisa from './internationalvisa.jpg'
import dubaiVisa from './emirates.jpg'
import yachtBanner from './yatchbanner.png';
import cruiseBanner from './cruse.jpg';


export const luxuryYachtImage = luxuryYacht;
export const InternationalVisaImage = InternationalVisa;
export const dubaiVisaImage = dubaiVisa;
export const yachtbanner = yachtBanner;
export const cruiseBannerImage = cruiseBanner;