
import React from "react";
import '../ImageSliderHomePageComponent/ImageSliderHomePageComponent.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { popularSafaris, yachts, cruises } from '../../cruiseData';
import { Link } from 'react-router-dom';


const ImageSliderMobileHomeCmpt = () => {
    const settings = {
        dots: true,
        autoplay: true,
        autoplaySpeed: 3500,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    const formatproductName = (productName) => {
        return productName.replace(/ /g, "-");
      };


  return (
    <Slider {...settings}>
    <Link
         to={"/cruises/Lotus-5-Star-Cruise-marina"}
       >
       <img 
           src='https://res.cloudinary.com/dj9xbypxz/image/upload/v1722575539/117_d71sk1.png' 
           className="HomeBannerImageMob" 
           alt="Home Banner 1"
           />          
   </Link>
   <Link
         to={"/safaris/Jeep-Wrangler-Desert-Safari-Dubai"}
         >
       <img 
           src='https://res.cloudinary.com/dj9xbypxz/image/upload/v1722575654/Group_647_l0gazn.png' 
           className="HomeBannerImageMob" 
           alt="Home Banner 2"
       />          
   </Link>
   <Link
         to={"/yachts/Columbus-Yacht-dubai"}
       >
       <img 
           src='https://res.cloudinary.com/dj9xbypxz/image/upload/v1722575698/Group_648_afrwmj.png' 
           className="HomeBannerImageMob" 
           alt="Home Banner 3"
       />          
   </Link>
</Slider>
  )
}

export default ImageSliderMobileHomeCmpt

