import React from "react";
import "../ReviewComponent/ReviewComponent.css";

import { Swiper, SwiperSlide } from 'swiper/react';
import EmptyStar from '../../Images//emptystar.svg';
import FullStar from '../../Images/Fullstar.svg';
import HalfStar from '../../Images/Halfstar.svg';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import './styles.css';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

import ReviewPrflImg1 from '../../Images/Review-Profile-Image-1.png';
import ReviewPrflImg2 from '../../Images/Review-Profile-Image-2.png';
import ReviewPrflImg3 from '../../Images/Review-Profile-Image-3.png';
import Reviewprf from "../../Images/newdp.png";


const ReviewComponent = () => {
  return (
    <>
      <div className="flex w-full flex-col">
        <div className="flex w-full justify-center text-2xl font-semibold md:my-6">
          <div className="md:text-2xl font-bold md:mb-4 text-[#2D2D2D]"> Our Customer Feedback</div>
        </div>
        <div className="PartnerImgReview">
          <Swiper
                spaceBetween={30}
                // centeredSlides={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                pagination={{
                  clickable: true,
                }}
                slidesPerView={3}
                modules={[Autoplay, Navigation]}
                className="mySwiper"
                breakpoints={{
                  0: { slidesPerView: 1 },
                  400: { slidesPerView: 1 },
                  767: { slidesPerView: 1 },
                  865: { slidesPerView: 3 },
                  1050: { slidesPerView: 3 },
                  1366: { slidesPerView: 3 },
                  1500: { slidesPerView: 3 },
                  1700: { slidesPerView: 4 },
                }}
            >
                <SwiperSlide>
                    <div className="MainSlideComponent">
                        <div className="SliderBoxImgRev">
                            <div className="SliderPrflName">
                                <div className="ImgStarCommonCls">
                                    <div className="ReviewImgone">
                                        <img src={ReviewPrflImg3}/>
                                        <h4>Floyd Miles</h4>
                                    </div>
                                    <div className="flex"> 
                                        <img src={FullStar}/>
                                        <img src={FullStar}/>
                                        <img src={FullStar}/>
                                        <img src={HalfStar}/>
                                        <img src={EmptyStar}/>
                                    </div>
                                </div>
                            </div>
                            <div className="ReviewBoxText">
                              <p>
                              "Had an amazing experience with Humrah Travels! The dinner cruise in Dubai was the highlight of our trip. The food was delicious, the staff was incredibly friendly, and the views were simply breathtaking. Highly recommend!"                              </p>
                            </div>

                        </div>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className="MainSlideComponent">
                        <div className="SliderBoxImgRev">
                            <div className="SliderPrflName">
                                <div className="ImgStarCommonCls">
                                    <div className="ReviewImgone">
                                        <img src={ReviewPrflImg1}/>
                                        <h4>Sophia Johnson  </h4>
                                    </div>
                                    <div className="flex"> 
                                        <img src={FullStar}/>
                                        <img src={FullStar}/>
                                        <img src={FullStar}/>
                                        <img src={HalfStar}/>
                                        <img src={EmptyStar}/>
                                    </div>
                                </div>
                            </div>
                            <div className="ReviewBoxText">
                              <p>
                              "Humrah Travels exceeded our expectations! The desert safari was an unforgettable adventure. From thrilling dune bashing to a magical evening under the stars, every moment was perfect. The team was professional and attentive. Highly recommend their services!"

                                     </p>
                            </div>

                        </div>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className="MainSlideComponent">
                        <div className="SliderBoxImgRev">
                            <div className="SliderPrflName">
                                <div className="ImgStarCommonCls">
                                    <div className="ReviewImgone">
                                        <img src={ReviewPrflImg2}/>
                                        <h4>Liam Anderson      </h4>
                                    </div>
                                    <div className="flex"> 
                                        <img src={FullStar}/>
                                        <img src={FullStar}/>
                                        <img src={FullStar}/>
                                        <img src={HalfStar}/>
                                        <img src={EmptyStar}/>
                                    </div>
                                </div>
                            </div>
                            <div className="ReviewBoxText">
                              <p>
                              "Traveling with Humrah Travels was a fantastic experience! The luxury yacht cruise in Dubai was simply incredible. The yacht was stunning, the food was exquisite, and the crew was friendly and accommodating. This was a highlight of our vacation!"
                                     </p>
                            </div>

                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="MainSlideComponent">
                        <div className="SliderBoxImgRev">
                            <div className="SliderPrflName">
                                <div className="ImgStarCommonCls">
                                    <div className="ReviewImgone">
                                        <img src={Reviewprf}/>
                                        <h4>Isabella Martinez</h4>
                                    </div>
                                    <div className="flex"> 
                                        <img src={FullStar}/>
                                        <img src={FullStar}/>
                                        <img src={FullStar}/>
                                        <img src={HalfStar}/>
                                        <img src={EmptyStar}/>
                                    </div>
                                </div>
                            </div>
                            <div className="ReviewBoxText">
                              <p>
                              "Humrah Travels made our Dubai trip exceptional! The guided city tour was informative and fun, allowing us to see all the major attractions without any hassle. Our guide was knowledgeable and engaging. A wonderful experience overall!"    </p>
                            </div>

                        </div>
                    </div>
                </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </>
  );
};
export default ReviewComponent;
