import React, { useEffect, useRef, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import CarouselComponent from "../../../components/CarouselComponent/CarouselComponent";
import RecomendedSlider from "../../../components/RecomendedSlider/ActivitySlider";
import RecomendedSlider1 from "../../../components/RecomendedSlider/RecomendedSlider";

import { luxuryYachtImage } from "../../../Images/carouselmages";
import { useNavigate } from "react-router-dom";
import { IoMdStar } from "react-icons/io";
import { Link, useParams } from "react-router-dom";
import {
  ObservationActivites,
  RecommendedActivites,
  SightseeingActivites,
  ThemeParkActivites,
  WaterParkActivites,
  cruises,
  popularActivites,
  popularCruises,
  popularSafaris,
  popularYachts,
  safaris,
} from "../../../cruiseData";
import Post1 from "../../../Images/safariImg/Image1.png";
import Post2 from "../../../Images/safariImg/Image2.png";
import Post3 from "../../../Images/safariImg/Image3.png";
import Post4 from "../../../Images/safariImg/Image4.png";
import Post5 from "../../../Images/safariImg/Image5.png";
import Post6 from "../../../Images/safariImg/Image6.png";
import ActivityBox from "../Main/ActivityBox/ActivityBox";
import ActivityBox1 from "../Main/ActivityBox/ActivityBox1";
import ActivityBox2 from "../Main/ActivityBox/ActivityBox2";
import ActivityBox3 from "../Main/ActivityBox/ActivityBox3";

const ActivityCarouselPage = () => {
  const { productName } = useParams();
  const [activeTab, setActiveTab] = useState("Desert Safari");
  const [SafariData, setSafariData] = useState([]);
  const [data, setData] = useState(ObservationActivites);
  const navigate = useNavigate();

  const popularRef = useRef(null);
  const marinaRef = useRef(null);
  const greekRef = useRef(null);
  const canalRef = useRef(null);

  // useEffect(() => {
  //   const cruise = cruises.find(
  //     (item) => item.productName.replace(/ /g, "-") === productName
  //   );
  //   setSafariData(cruise);

  //   setData([...marinaCruises, ...creekCruises]);
  // }, [productName]);

  const [selectedCategory, setSelectedCategory] = useState("Observation");

  const handleScroll = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);

    switch (category) {
      case "Observation":
        setData(ObservationActivites);
        break;
      case "Sightseeing":
        setData(SightseeingActivites);
        break;
      case "Theme Park":
        setData(ThemeParkActivites);
        break;
      case "Water Park":
        setData(WaterParkActivites);
        break;
      default:
        setData([]);
    }
  };

  const handleViewMore = (name) => {
    navigate(`/activities/${formatproductName(name.replace(/ /g, "-"))}`);
  };

  const formatproductName = (productName) => {
    return productName.replace(/ /g, "-");
  };

  const populardata = [...popularCruises, ...popularSafaris];

  return (
    <>
      <div className="SafariSection">
        <div className="flex flex-col items-center mt-20 text-center p-4 md:p-8 bg-white rounded-lg max-w-screen-lg mx-auto">
          <h1 className="md:text-4xl text-[#171717] font-semibold mb-6">
            Enjoy Vacation in Dubai
          </h1>
          <p className="text-[#3B474B] text-sm md:text-base mb-4">
            Enjoy a vacation in Dubai packed with incredible activities such as
            exploring Ferrari World, experiencing thrilling theme parks,
            relaxing on pristine beaches, and visiting the iconic Dubai Frame
            Museum.
          </p>
          <div className="flex flex-wrap justify-center gap-4">
            {/* <button className="bg-[#E5F5FB] text-[#009BDE] border border-[#009BDE] px-4 py-2 rounded-md">
              Observation Deck
            </button>
            <button className="bg-[#E5F5FB] text-[#009BDE] border border-[#009BDE] px-4 py-2 rounded-md">
              Sight Seeing
            </button>
            <button className="bg-[#E5F5FB] text-[#009BDE] border border-[#009BDE] px-4 py-2 rounded-md">
              Theme Park
            </button>
            <button className="bg-[#E5F5FB] text-[#009BDE] border border-[#009BDE] px-4 py-2 rounded-md">
              Water Park
            </button> */}
          </div>
        </div>
        <div className="w-10/12 flex">
          <RecomendedSlider data={popularActivites} />
        </div>
        <div className="vacation-info mt-8 text-center p-4">
          <h1 className="title md:text-2xl mb-5 text-[#171717] font-semibold justify-center">
            Enjoy Vacation in Dubai
          </h1>
          <p className="description text-sm md:text-base text-[#3B474B]">
            Enjoy a vacation in Dubai with amazing activities like visiting the
            iconic Burj Khalifa, exploring the Louvre Museum, marveling at the
            Burj Al Arab, and experiencing the luxury of Atlantis The Palm.
          </p>
        </div>

        <div className="flex h-full w-full 2xl:mx-0 2xl:w-11/12 flex-col">
          <div className="w-full min-h-[18vh] flex flex-col mx-auto px-4 py-2">
            <div className="sticky top-0 bg-white z-10 px-6 md:py-8 w-full">
              <div className="flex space-x-6 text-xs sm:text-sm md:text-base mb-6 w-full justify-center">
                <div
                  className={`pb-2 ${
                    selectedCategory === "Observation"
                      ? "text-gray-800 border-b-2 border-gray-800 cursor-pointer"
                      : "text-gray-600 hover:text-gray-800 cursor-pointer"
                  }`}
                  onClick={() => handleCategoryClick("Observation")}
                >
                  Observation
                </div>
                <div
                  className={`pb-2 ${
                    selectedCategory === "Sightseeing"
                      ? "text-gray-800 border-b-2 border-gray-800 cursor-pointer"
                      : "text-gray-600 hover:text-gray-800 cursor-pointer"
                  }`}
                  onClick={() => handleCategoryClick("Sightseeing")}
                >
                  Sightseeing
                </div>
                <div
                  className={`pb-2 ${
                    selectedCategory === "Theme Park"
                      ? "text-gray-800 border-b-2 border-gray-800 cursor-pointer"
                      : "text-gray-600 hover:text-gray-800 cursor-pointer"
                  }`}
                  onClick={() => handleCategoryClick("Theme Park")}
                >
                  Theme Park
                </div>
                <div
                  className={`pb-2 ${
                    selectedCategory === "Water Park"
                      ? "text-gray-800 border-b-2 border-gray-800 cursor-pointer"
                      : "text-gray-600 hover:text-gray-800 cursor-pointer"
                  }`}
                  onClick={() => handleCategoryClick("Water Park")}
                >
                  Water Park
                </div>
              </div>
            </div>
            <div ref={popularRef} className="flex w-full h-full">
              <ActivityBox data={data} />
            </div>
            {/* <div ref={popularRef} className="flex w-full mt-8 h-full">
              <ActivityBox1
               />
            </div>
            <div ref={popularRef} className="flex w-full mt-8 h-full">
              <ActivityBox2
               />
            </div> <div ref={popularRef} className="flex w-full mt-8 h-full">
              <ActivityBox3
               />
            </div> */}
            {/* <div ref={popularRef} className="flex w-full mt-12 h-full">
              <ActivityBox />
            </div>
            <div ref={popularRef} className="flex w-full mt-8 h-full">
              <ActivityBox />
            </div>
            <div ref={popularRef} className="flex w-full mt-8 h-full">
              <ActivityBox />
            </div>
            <div ref={popularRef} className="flex w-full mt-8 h-full">
              <ActivityBox />
            </div>
             */}
          </div>

          <div className="tour-title px-3 text-[#282B2C] font-semibold">
            Popular Tours
          </div>
          <div className="tours-details-section px-4">
            <RecomendedSlider1 populardata={populardata} />
          </div>
          <div className="activity-container px-4">
            <div className="tour-titl px-4 text-[#282B2C]">
              <h2>Create best experience for your trip to Dubai</h2>
            </div>
            <div className="desc-cont px-4">
              <p>
                Create the best experience for your trip to Dubai with our
                exceptional services, tailored to ensure every moment of your
                journey is unforgettable.
              </p>
            </div>
            <div className="menu-cont px-4">
              {popularActivites.map((item, index) => (
                <div
                  key={index}
                  className="min-w-full mb-5  border-2 p-2 md:mb-0 cursor-pointer  bg-[#FFFFFF] sm:min-w-[calc(50%-1rem)] md:min-w-[calc(33.333%-1rem)] lg:min-w-[calc(25%-1rem)] xl:min-w-[20%] rounded-lg overflow-hidden"
                >
                  <Link to={`/activities`}>
                    <div
                      className="h-48 bg-normal1 rounded-md bg-cover bg-no-repeat relative overflow-hidden"
                      style={{ backgroundImage: `url(${item?.imageUrl[0]})` }}
                    >
                      <LazyLoadImage
                        alt={"cardimage"}
                        effect="blur"
                        src={item?.imageUrl[0]}
                        className="hidden"
                      />
                    </div>
                    <div className="py-4 px-1">
                      <div className="flex w-full justify-between ">
                        <h3 className="md:text-lg font-semibold overflow-hidden whitespace-nowrap text-ellipsis">
                          {item.productName}
                        </h3>
                      </div>
                      <div className="flex items-center text-sm overflow-hidden whitespace-nowrap text-ellipsis">
                        <span className="text-yellow-500 text-lg md:text-xl">&#9733;</span>
                        <span className="ml-2 pr-3">{item.ratings}(21.1k)</span>
                      </div>
                      <p className="mt-2 text-xs md:text-sm text-[#2D2D2D]">
                        Starting From <b>{item.price}</b> AED
                      </p>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActivityCarouselPage;
