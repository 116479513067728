import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import "./SafariCarouselPage.css";
import { IoMdStar } from "react-icons/io";
import safariImage1 from "../../../Images/safariImg/Group1.svg";
import safariImage2 from "../../../Images/safariImg/Group2.svg";
import safariImage3 from "../../../Images/safariImg/Group3.svg";
import {
  cruises,
  popularActivites,
  popularCruises,
  popularSafaris,
  popularYachts,
  safaris,
} from "../../../cruiseData.js";
import Carousel from "../../../Images/BEST-IN-SAFARI-Image-ONE.webp";
import Tabs from "./Tabs.jsx";
import { LazyLoadImage } from "react-lazy-load-image-component";
import RecomendedSlider from "../../../components/RecomendedSlider/RecomendedSlider.jsx";
import SafariSection from "../Main/SafariSection/SafariSection.jsx";
import SafariSection1 from "../Main/SafariSection/SafariSection1.jsx";
import SafariSection2 from "../Main/SafariSection/SafariSection2.jsx";
import CruiseSlider from "../Main/CruiseSlider/CruiseSlider.jsx";

import NewSafariBannerImgOne from "../../../Images/Banner-Desert-Safari-Image-one.png";

const SafariCarouselPage = () => {
  const { productName } = useParams();
  const [SafariData, setSafariData] = useState([]);
  const [data, setData] = useState(safaris);

  useEffect(() => {
    const cruise = safaris.find(
      (item) => item.productName.replace(/ /g, "-") === productName
    );
    setSafariData(cruise);

    setData(safaris);
  }, [productName]);

  const formatproductName = (productName) => {
    return productName.replace(/ /g, "-");
  };

  const populardata = [...popularCruises, ...popularYachts];

  return (
    <div className="flex w-full flex-col 2xl:justify-center 2xl:items-center">
      <div className="CruisesCarousel">
        <div className="carousel-image-placeholder">
          <LazyLoadImage
            alt={"review2"}
            effect="blur"
            src={NewSafariBannerImgOne}
            className="carousel-img"
          />
          <LazyLoadImage
            alt={"review2"}
            effect="blur"
            src={'https://res.cloudinary.com/dj9xbypxz/image/upload/v1722535382/125_q6f3wr.png'}
            className="carousel-img-mobile"
          />
        </div>
      </div>
      <div className="flex h-full w-full 2xl:mx-0 2xl:w-11/12 flex-col">
        <div className="w-full min-h-[18vh] flex flex-col mx-auto py-2">
          <SafariSection />
        </div>
        {/* <div className="w-full min-h-[18vh] flex flex-col mx-auto py-8">
          <SafariSection1 />
        </div>
        <div className="w-full min-h-[18vh] flex flex-col mx-auto py-8">
          <SafariSection2 />
        </div> */}

        <div className="tour-title px-3 text-[#282B2C] font-semibold">
          Popular Tours
        </div>
        <div className="tours-details-section px-4">
          <RecomendedSlider populardata={populardata} />
        </div>
        <div className="activity-container px-4">
          <div className="tour-titl px-4 text-[#282B2C]">
            <h2>Create best experience for your trip to Dubai</h2>
          </div>
          <div className="desc-cont px-4">
            <p>
              Create the best experience for your trip to Dubai with our
              exceptional services, tailored to ensure every moment of your
              journey is unforgettable.
            </p>
          </div>
          <div className="menu-cont px-4">
            {popularActivites.map((item, index) => (
              <div
                key={index}
                className="min-w-full mb-6 md:mb-0  bg-[#FFFFFF] border-2 p-2 cursor-pointer sm:min-w-[calc(50%-1rem)] md:min-w-[calc(33.333%-1rem)] lg:min-w-[calc(25%-1rem)] xl:min-w-[20%] rounded-lg overflow-hidden"
              >
                <Link key={index} to={`/activities`} className="CardImageOne">
                  <div
                    className="h-48 bg-normal1 rounded-md bg-cover bg-no-repeat relative overflow-hidden"
                    style={{ backgroundImage: `url(${item?.imageUrl[0]})` }}
                  >
                    <LazyLoadImage
                      alt={"cardimage"}
                      effect="blur"
                      src={item?.imageUrl[0]}
                      className="hidden"
                    />
                  </div>
                  <div className="py-4 px-1">
                    <div className="flex w-full justify-between ">
                      <h3 className="md:text-lg font-semibold overflow-hidden whitespace-nowrap text-ellipsis">
                        {item.productName}
                      </h3>
                    </div>
                    <div className="flex items-center text-sm overflow-hidden whitespace-nowrap text-ellipsis">
                      <span className="text-yellow-500 md:text-xl sm:text-lg">&#9733;</span>
                      <span className="ml-2 pr-3">{item.ratings}(21.1k)</span>
                    </div>
                    <p className="mt-2 text-xs md:text-sm text-[#2D2D2D]">
                      Starting From <b>{item.price}</b> AED
                    </p>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SafariCarouselPage;
