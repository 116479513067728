import React, { useRef } from 'react';
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from 'swiper/react';
import { yachts } from '../../../../cruiseData.js';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';

const YachtSection = () => {
  const sliderRef1 = useRef(null);

  const handleNext1 = () => {
    if (sliderRef1.current) {
      sliderRef1.current.swiper.slideNext();
    }
  };

  const handlePrev1 = () => {
    if (sliderRef1.current) {
      sliderRef1.current.swiper.slidePrev();
    }
  };

  const formatproductName = (productName) => {
    return productName.replace(/ /g, "-");
  };

  return (
    <div className="w-full min-h-[18vh] flex 2xl:px-12 px-5 flex-col">
      <div className="flex w-full justify-between min-h-[6vh] items-center">
        <h2 className="md:text-2xl font-bold md:mb-1 text-[#2D2D2D] md:p-3 md:mt-2">Yachts</h2>
        <div className="flex items-center space-x-4">
          {/* Navigation buttons can be re-enabled here */}
        </div>
      </div>
      <div className="flex justify-between items-center mb-4">
        <div className="flex text-sm md:text-base w-full text-[#3B474B] flex-wrap justify-between md:mb-5 sm:mb-0 md:px-3">
          Sail along Dubai’s stunning coastline in unparalleled comfort and style. Enjoy gourmet cuisine, soak up the sun, and take in the breathtaking skyline. 
          Book your voyage today and elevate your Dubai experience to new heights!
        </div>
      </div>

      <div className="overflow-hidden mt-3 grid gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
        {yachts.map((item, index) => (
          <div 
            key={index} 
            className="min-w-full border-2 p-2 cursor-pointer  bg-[#FFFFFF] rounded-lg overflow-hidden"
          >
            <Link
              to={`/yachts/${formatproductName(item.productName)}`}
              className="CardImageOne"
            >
            <div 
              className="h-48 bg-normal1 rounded-md bg-cover bg-no-repeat relative overflow-hidden"
              style={{ backgroundImage: `url(${item?.imageUrl[0]})` }}
            >
              <LazyLoadImage
                alt={"cardimage"}
                effect="blur"
                src={item?.imageUrl[0]}
                className="hidden"
              />
            </div>
            <div className="py-4 px-1">
              <div className="flex w-full justify-between ">
                <h3 className="md:text-lg text-[#2D2D2D] font-semibold overflow-hidden whitespace-nowrap text-ellipsis">
                  {item.productName}
                </h3>
              </div>
                <div className="flex items-center text-sm overflow-hidden whitespace-nowrap text-ellipsis">
                  <span className="text-yellow-500 text-xl pl-2">&#9733;</span>
                  <span className="ml-2 pr-3">{item.ratings}(21.1k)</span>
                </div>
              <p className="mt-2 text-xs md:text-sm text-[#2D2D2D]">Starting From <b>{item.price}</b> AED</p>
            </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default YachtSection;
