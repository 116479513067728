import LandingBanner from './Landing-Banner.png';
import PlaceHolder from './Humrah-Website-Logo-New.svg';
import NumberOneIcon from '../Images/Number-One-Icon-Home.png';
import NumberTwoIcon from '../Images/Number-Two-Icon-Home.png';
import NumberThreeIcon from '../Images/Number-Three-Icon-Home.png';
import HomeSectionImage from '../Images/Home-Banner-Image-Third.png';

import BestInSafariIMGONE from '../Images/BEST-IN-SAFARI-Image-ONE.webp';
import BestInSafariIMGTWO from '../Images/BEST-IN-SAFARI-Image-FIVE.webp';
import BestInSafariIMGTHREE from '../Images/BEST-IN-SAFARI-Image-THREE.webp';
import BestInSafariIMGFOUR from '../Images/BEST-IN-SAFARI-Image-FOUR.webp';


import BestCurse1 from '../Images/Curse-Img-1.png';
import BestCurse2 from '../Images/Curse-Img-2.png';
import BestCurse3 from '../Images/Curse-Img-3.png';
import BestCurse4 from '../Images/Curse-Img-4.png';
import BestCurse5 from '../Images/Curse-Img-5.png';

import DubaiVisaBannerImage from '../Images/Dubai-Visa-Main-Banner-Image.png';

import ServicesDetailsIcon1 from '../Images/Services-Icon-AbuDhabi.png';
import ServicesDetailsIcon2 from '../Images/Services-Icon-Sharing.png';
import ServicesDetailsIcon3 from '../Images/Services-Icon-Secure.png';
import ServicesDetailsIcon4 from '../Images/Services-Icon-Airport.png';

import ContactUsBannerImage from '../Images/Get-In-Touch-banner-Image.webp';

import NewSectionImgone from '../Images/New-Section-Image-one.jpeg';
import NewSectionImgtwo from '../Images/New-Section-Image-two.jpeg';
import NewSectionImgthree from '../Images/New-Section-Image-three.jpeg';


export const NewSectionOne = NewSectionImgone;
export const NewSectionTwo = NewSectionImgtwo;
export const NewSectionThree = NewSectionImgthree;

// export const HomeBanner  = LandingBanner;
export const HomeBanner  = LandingBanner;
export const SampleImage  = PlaceHolder;

export const NumberOne = NumberOneIcon;
export const NumberTwo = NumberTwoIcon;
export const NumberThree = NumberThreeIcon;

export const HomeSectionImageThree = HomeSectionImage;

export const BestSafariOne = BestInSafariIMGONE;
export const BestSafariTwo = BestInSafariIMGTWO;
export const BestSafariThree = BestInSafariIMGTHREE;
export const BestSafariFour = BestInSafariIMGFOUR;


export const BestInCurseImg1 = BestCurse1;
export const BestInCurseImg2 = BestCurse2;
export const BestInCurseImg3 = BestCurse3;
export const BestInCurseImg4 = BestCurse4;
export const BestInCurseImg5 = BestCurse5;


export const DubaiMainBanner = DubaiVisaBannerImage;

export const ServicesAirport = ServicesDetailsIcon4;
export const ServicesSecure = ServicesDetailsIcon3;
export const ServicesSharing = ServicesDetailsIcon2;
export const ServicesAbuDhabi = ServicesDetailsIcon1;

export const ContactBannerImage = ContactUsBannerImage