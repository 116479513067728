import React, { useState } from "react";
import '../SidebarComponent/SidebarComponent.css';
import MultilevelSidebar from "react-multilevel-sidebar";
import "react-multilevel-sidebar/src/Sidebar.css";
import { RxHamburgerMenu } from "react-icons/rx";

const SidebarComponent = () => {
    const [isOpen, setIsOpen] = useState(false);

    let options = [
      {
        title: "Services",
        titleIcon: <i className="fa fa-graduation-cap"></i>,
         content: [{ id: 1, name: "Home", to: "/" }]
      },
      {
        // title: "Home",
        titleIcon: <i className="fa fa-graduation-cap"></i>,
         content: [{ id: 1, name: "Visa", to: "/" }]
      },
      {
        // title: "Home",
        titleIcon: <i className="fa fa-graduation-cap"></i>,
         content: [{ id: 1, name: "Cruises", to: "/cruises" }]
      },
      {
        // title: "Home",
        titleIcon: <i className="fa fa-graduation-cap"></i>,
         content: [{ id: 1, name: "Activites", to: "/activities" }]
      },
      {
        // title: "Home",
        titleIcon: <i className="fa fa-graduation-cap"></i>,
         content: [{ id: 1, name: "Safaris", to: "/safaris" }]
      },
      {
        // title: "Home",
        titleIcon: <i className="fa fa-graduation-cap"></i>,
         content: [{ id: 1, name: "Yachts", to: "/yachts" }]
      },
    ];
  
    const handleSidebarToggle = (isOpen) => {
      setIsOpen(isOpen);
    };
   
  return (
    <div className='border-gray-300 border rounded-md'>
        <MultilevelSidebar
           open={isOpen}
           onToggle={handleSidebarToggle}
           options={options}
        />
        <RxHamburgerMenu size={30} onClick={() => handleSidebarToggle(true)} className='hamburgerMenu'/>
    </div>
  )
}
export default SidebarComponent;
