import  React from 'react';
import logo from './logo.svg';
import './App.css';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HeaderMenu from './HeaderComponent/HeaderMenu';
import Homepage from './HomePage/HomePage';
import Footer from './FooterComponent/Footer';
import AutoScrollToTop from './ScrollToTopComponent.js';
import YachtCarouselPage from './pages/CarouselPages/YachtCarouselPage/YachtCarouselPage.jsx';
import CruisesCarouselPage from './pages/CarouselPages/CruisesCarouselPage/CruisesCarouselPage.jsx';
import CruiseDetailsPage from './pages/Yachts/cruiseDetails/CruiseDetailsPage.jsx';
import SafariDetails from './pages/Safari/SafariDetails/Safari.jsx';
import ActivityDetails from './pages/Activities/ActivityDetails/ActivityDetails.jsx';
import ActivityCarouselPage from './pages/CarouselPages/ActivityCarouselPage/ActivityCarouselPage.jsx';
import SafariCarouselPage from './pages/CarouselPages/SafariCarouselPage/SafariCarouselPage.jsx';
import YachtDetailsPage from './pages/Yachts/yachtDetails/YachtDetailsPage.jsx';
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import Home from './HomePage/Home.jsx';

function App() {
  return (
    <div className="flex flex-col w-full h-full overflow-x-hidden	">
      <BrowserRouter>
      <AutoScrollToTop/>
        <HeaderMenu />
        <Routes>
          {/* <Route path='/' element={<Homepage />} /> */}
          <Route path='/' element={<Home />} />

          {/* Yachts */}
          <Route path='/yachts' element={<YachtCarouselPage />} />
          <Route path='/yachts/:productName' element={<YachtDetailsPage/>} />

          {/* Crusies */}
          <Route path='/cruises' element={<CruisesCarouselPage />} />
          <Route path='/cruises/:productName' element={<CruiseDetailsPage/>} />

          {/* safaris */}
          <Route path='/safaris' element={<SafariCarouselPage />} />
          <Route path='/safaris/:productName' element={<SafariDetails/>} />

          {/* visa */}
          <Route path='/visa' element={<SafariDetails/>} />

          {/* activities */}
          <Route path='/activities' element={<ActivityCarouselPage/>} />
          <Route path='/activities/:productType/:productName' element={<ActivityDetails/>} />

          <Route path='*' element={<Home />} />     
        </Routes>
        <FloatingWhatsApp
         phoneNumber='971581732681'
          accountName='Humrah Travels'
          // avatar="path-to-avatar-image" // Optional: provide an avatar image path
          statusMessage="Typically replies within 1 hour"
          chatMessage="Hello there! 🤝 How can we help?"
          placeholder="Type a message.."
          messageDelay={2}
          darkMode={false}
          allowClickAway={false}
          allowEsc={false}
          className="floating-whatsapp"
          buttonClassName="floating-whatsapp-button"
          style={{ left: '20px', right: 'auto' }} // Custom style to move to the left
          buttonStyle={{ left: '20px', right: 'auto' }} // Custom style to move to the left
          chatboxStyle={{ left: '20px', right: 'auto' }}
          
          />
        <Footer/>
      </BrowserRouter>
    </div>
  );
}

export default App;
