import React, { useState, useEffect } from "react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-cards";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import { popularActivites, popularCruises, popularSafaris, popularYachts } from "../../cruiseData";

const ActivitySlider = () => {
  const [loading, setLoading] = useState(true);

  const populardata = [...popularYachts, ...popularCruises, ...popularSafaris];

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  const formatproductName = (productName) => {
    return productName.replace(/ /g, "-");
  };

  return (
    <>
      {loading ? (
        <div className="flex justify-center  items-center space-x-5">
          {[...Array(4)].map((_, index) => (
            <div key={index} className="p-4">
              <div className="w-[260px] h-[238px] bg-gray-200 animate-pulse rounded-lg"></div>
              <div className="mt-2 w-3/5 h-4 bg-gray-200 animate-pulse rounded"></div>
              <div className="mt-1 w-4/5 h-4 bg-gray-200 animate-pulse rounded"></div>
            </div>
          ))}
        </div>
      ) : (
        <Swiper
          slidesPerView={4}
          loop={true}
          scrollbar={{ draggable: true }}
          modules={[Navigation, Autoplay]}
          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
          }}
          className="mySwiper1"
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            640: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 2,
            },
            1076: {
              slidesPerView: 3,
            },
            1460: {
              slidesPerView: 4,
            },
          }}
        >
          {popularActivites.map((item, i) => (
            <SwiperSlide key={i}>
              <div className="tour-details1">
                <img
                  src={item.imageUrl[0]}
                  alt="Tour"
                  className="w-full h-[280px] object-cover rounded-lg"
                />
                <div className="absolute inset-0 bg-black bg-opacity-50 rounded-lg flex flex-col justify-between p-4">
                  <div className="flex justify-between items-start">
                    <div className="text-white font-semibold text-lg">
                      {item.productName}
                    </div>
                  </div>
                  <div className="text-white text-lg">{item.price} AED</div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </>
  );
};

export default ActivitySlider;
