import React, { useState } from "react";
import "./DetailedBox.css";
import { Link } from "react-router-dom";
import hoverimg from "../Images/hoverimg.png";
import FullStar from "../Images/Fullstar.svg";
import HalfStar from "../Images/Halfstar.svg";
import EmptyStar from "../Images/emptystar.svg";
import { Rating } from "@mui/material";
import { ObservationActivites, SightseeingActivites, ThemeParkActivites, WaterParkActivites } from "../cruiseData";

function DetailedBox({
  isVisible,
  handleMouseEnter,
  handleMouseLeave,
  category,
  data,
}) {
  const [ActivityData, setActivityData] = useState([...ObservationActivites]);

  if (!isVisible) {
    return null;
  }

  const formatproductName = (productName) => {
    return productName?.replace(/ /g, "-");
  };

  const handleCategoryClick = (category) => {

    switch (category) {
      case "Observation":
        setActivityData(ObservationActivites);
        break;
      case "Sightseeing":
        setActivityData(SightseeingActivites);
        break;
      case "Theme Park":
        setActivityData(ThemeParkActivites);
        break;
      case "Water Park":
        setActivityData(WaterParkActivites);
        break;
      default:
        setActivityData([]);
    }
  };


  return (
    <>
      {data?.length > 0 && (
        <>
          <div
            className="detailedBox"
            onMouseEnter={() => handleMouseEnter(category)}
            onMouseLeave={handleMouseLeave}
          >
            {category === "activities" ? (
              <>
                {/* {data?.data?.length > 0 &&
                  data?.data?.map((item, index) => (
                    <div key={index} className="detailedBoxActiveCategory">
                      <h3>{item.title}</h3>

                      <ul>
                        {item?.data?.map((detail, subIdx) => (
                          <Link
                            to={`/${category}/${
                              item.navigate
                            }/${formatproductName(detail?.productName)}`}
                            className="CardImageOne"
                          >
                            <li key={subIdx}>{detail.productName}</li>
                          </Link>
                        ))}
                      </ul>
                    </div>
                  ))} */}
                  <div className="flex flex-row ">
                   <div className="flex flex-row 2xl:flex-wrap">
                   <div className="rounded-md min-w-[220px] font-medium lg:flex justify-between mr-2 border-r-2 flex-col focus:outline-none none" role="menu"  aria-labelledby="menu-button">
                    <div class="py-1" role="none">
                      <div href="#" class="flex px-4 pb-4 text-md text-gray-700 hover:text-[#009BDE]" 
                      role="menuitem" id="menu-item-0"onClick={() => handleCategoryClick("Observation")}>Observation Activites</div>
                      <div href="#" class="flex px-4 py-4 text-md text-gray-700 hover:text-[#009BDE]" role="menuitem" 
                      id="menu-item-1" onClick={() => handleCategoryClick("Water Park")}>Waterpark Activites</div>
                      <div href="#" class="flex px-4 py-4 text-md text-gray-700 hover:text-[#009BDE]" 
                      role="menuitem" id="menu-item-2"onClick={() => handleCategoryClick("Sightseeing")}>SightSeeing Activites</div>
                      <div href="#" class="flex px-4 py-4 text-md text-gray-700 hover:text-[#009BDE]"
                       role="menuitem" id="menu-item-2"onClick={() => handleCategoryClick("Theme Park")}>ThemePark Activites</div>
                      <a href="/activities" class="flex px-4 py-4 text-md text-gray-700 hover:text-[#009BDE]"
                       role="menuitem" id="menu-item-2">View All</a>
                   
                    </div>
                  </div>

                  <div className="grid grid-cols-3 sm:grid-cols-2 gap-8 min-h-[3vh] pl-3">
                    {ActivityData?.length > 0 &&
                      ActivityData.map((item, index) => (
                        <div key={index} className="flex items-center w-full">
                          <img
                            src={item?.imageUrl[0]}
                            alt={`${item.productName} Ticket`}
                            className="w-20 h-16 rounded mr-3"
                          />
                          <div>
                            <p className="font-small">
                              <Link
                                to={`/activities/${item.category}/${formatproductName(
                                  item?.productName
                                )}`}
                                className=""
                              >
                                {item.productName}
                              </Link>
                            </p>
                            <div className="flex">
                              {" "}
                              <Rating name="read-only" size="small"  value={item.ratings} readOnly  />
                            </div>
                            <p className="text-sm text-gray-600">from <b>{item.price}</b> AED</p>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
                </div>
              </>
            ) : (
              <>
                <div className="flex flex-wrap">
                  <div className="grid grid-cols-3 sm:grid-cols-2 gap-8 min-h-[3vh]">
                    {data?.length > 0 &&
                      data?.slice(0,6).map((item, index) => (
                        <div key={index} className="flex items-center w-full">
                          <img
                            src={item.imageUrl[0]}
                            alt={`${item.productName} Ticket`}
                            className="w-20 h-16 rounded mr-3"
                          />
                          <div>
                            <p className="font-medium">
                              <Link
                                to={`/${category}/${formatproductName(
                                  item?.productName
                                )}`}
                                className=""
                              >
                                {item.productName}
                              </Link>
                            </p>
                            <div className="flex">
                              {" "}
                              <Rating name="read-only" size="small"  value={item.ratings} readOnly  />
                            </div>
                            <p className="text-sm text-gray-600">from <b>{item.price}</b> AED</p>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
}

export default DetailedBox;
