import React, { useEffect, useState } from "react";
import "./CruiseDetailsPage.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import {
  swipercruiseImage,
  swipercruiseImage2,
  swipercruiseImage3,
} from "../../../Images/cruiseImages/index.js";
// import "../../Safari/SafariDetailsPage/DetailsPage.css";
import "../../CarouselPages/YachtCarouselPage/YachtCarouselPage.css";
import LandingBanner from "../../Yachts/cruiseLanding/LandingBanner/LandingBaner.jsx";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCards } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-cards";
import { FaCheckCircle, FaTimesCircle, FaInfoCircle } from "react-icons/fa";
import FAQComponent from "../../../components/FAQSection/FAQComponent.jsx";
import BookingTour from "../../../components/BookingSection/BookingSection.jsx";
import { LuTimer } from "react-icons/lu";
import { MdOutlineFreeCancellation } from "react-icons/md";
import { FaPeopleGroup } from "react-icons/fa6";
import { FaLanguage } from "react-icons/fa";
import MapComponent from "../../../components/MapComponent/MapComponent.jsx";
import "swiper/css";
import "swiper/css/navigation";
import RecomendedSlider from "../../../components/RecomendedSlider/RecomendedSlider.jsx";
import {
  creekCruises,
  cruises,
  marinaCruises,
  popularActivites,
  yachts,
  safaris,
} from "../../../cruiseData.js";
import { Link, useParams } from "react-router-dom";
import { IoMdStarOutline } from "react-icons/io";
import Arrow from "../../../Images/safariImg/ArrowCircleUpRight.svg";
import Post1 from "../../../Images/safariImg/Image1.png";
import Post2 from "../../../Images/safariImg/Image2.png";
import Post3 from "../../../Images/safariImg/Image3.png";
import Post4 from "../../../Images/safariImg/Image4.png";
import Post5 from "../../../Images/safariImg/Image5.png";
import Post6 from "../../../Images/safariImg/Image6.png";
import GalleryPic from "../../../Images/gallery.png";
import Star from "../../../Images/Star.svg";
import NewSafari from "../../../Images/safaridetailmg.png";
import Dinner from "../../../Images/Dinerrrblock.png";
import check from "../../../Images/icons/greencheck.svg";
import notcheck from "../../../Images/icons/redno.svg";
import info from "../../../Images/icons/yellowinfo.svg";
import highlightpic from "../../../Images/Highlightimg.png";
import Highlight from "../../../Images/Highlightimg.png";
import emailjs from "@emailjs/browser";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

const CruiseDetailsPage = () => {
  const { productName } = useParams();
  const [cruiseData, setCruiseData] = useState([]);
  const normalizedProductName = productName.replace(/-/g, " ");
  const [totalPrice, setTotalPrice] = useState(0);
  const [calculatedPrice, setCalculatedPrice] = useState(
    cruiseData?.price || 0
  );
  const [guestNumber, setGuestNumber] = useState(1);
  const [checkInTime, setCheckInTime] = useState("");
  const [numberOfTickets, setNumberOfTickets] = useState(1);
  const [email, setEmail] = useState("");
  const now = new Date();
  const defaultDate = now.toISOString().split("T")[0]; // format date as yyyy-mm-dd
  const [checkInDate, setCheckInDate] = useState(defaultDate);
  const defaultTime = now.toTimeString().split(" ")[0].slice(0, 5); // format time as hh:mm
  const [mobileNumber, setMobileNumber] = useState("");

  const [phone, setPhone] = useState("");
  const [countryCode, setCountryCode] = useState({
    value: "+91",
    label: (
      <img
        src="https://flagcdn.com/in.svg"
        alt="India"
        style={{ width: "20px", marginRight: "8px" }}
      />
    ),
  });
  const [phoneError, setPhoneError] = useState("");

  useEffect(() => {
    const data = [...marinaCruises, ...creekCruises];
    const cruise = data.find(
      (item) => item.productName === normalizedProductName
    );

    setCruiseData(cruise);
  }, [normalizedProductName, yachts]);

  useEffect(() => {
    if (cruiseData?.price) {
      setTotalPrice(cruiseData?.price * guestNumber);
      setCalculatedPrice(cruiseData?.price * guestNumber);
    }
  }, [cruiseData?.price, guestNumber]);

  const tourDetails = [
    {
      title: "36ft Luxury Yacht",
      price: "AED 8000",
      image: Post2,
    },
    {
      title: "40ft Luxury Yacht",
      price: "AED 10000",
      image: Post3,
    },
    {
      title: "50ft Luxury Yacht",
      price: "AED 12000",
      image: Post4,
    },
    {
      title: "60ft Luxury Yacht",
      price: "AED 15000",
      image: Post5,
    },
  ];

  const handleGuestNumberChange = (e) => {
    const value = parseInt(e.target.value);
    if (!isNaN(value) && value >= 1) {
      setGuestNumber(value);
      setCalculatedPrice(cruiseData?.price * value);
    } else if (e.target.value === "") {
      setGuestNumber("");
      setCalculatedPrice(cruiseData?.price);
    } else {
      setGuestNumber(1);
      setCalculatedPrice(cruiseData?.price);
    }
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    if (name === "checkInDate") {
      setCheckInDate(value);
    }
  };

  const handleTimeChange = (e) => {
    setCheckInTime(e.target.value);
  };

  const handleTicketChange = (e) => {
    setNumberOfTickets(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleMobileNumberChange = (e) => {
    setMobileNumber(e.target.value);
  };

  const handlePhoneChange = (value) => {
    setPhone(value);
  };

  const [guestError, setGuestError] = useState("");
  const [phoneeError, setPhoneeError] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    // Reset errors
    setGuestError("");
    setPhoneeError("");

    // Check if phone number is provided
    if (!phone) {
      setPhoneeError("Please enter your phone number");
      console.error("Phone number is missing");
    }

    // Check if the number of guests is valid
    if (guestNumber <= 0 || isNaN(guestNumber)) {
      setGuestError("Please enter a valid number of guests.");
      console.error("Please enter a valid number of guests.");
    }

    // If there are errors, prevent submission
    if (guestError || phoneeError) {
      return;
    }

    // Proceed with form submission
    const templateParams = {
      guestNumber,
      checkInDate,
      numberOfTickets,
      phonenumber: phone,
      email:email,
      message: "To Enquire ",
      activityName: cruiseData.productName,
      price: calculatedPrice,
    };
    emailjs
      .send(
        "service_lwtlh2h",
        "template_9606ai3",
        templateParams,
        "upvgjVmpU7y0ctsks"
      )
      .then((response) => {
        alert("Email sent successfully!");
        // console.log("Email sent successfully!", response.status, response.text);
      })
      .catch((err) => {
        console.error("Failed to send email:", err);
        alert("Failed to send email. Please try again later.");
      });
  };

  const getTodayDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, "0"); // Months start at 0!
    const dd = String(today.getDate()).padStart(2, "0");

    return `${yyyy}-${mm}-${dd}`;
  };

  const populardata = [...marinaCruises, ...safaris, ...yachts];

  return (
    <>
      <div className="flex w-full flex-col 2xl:justify-center 2xl:items-center">
        <div className="flex h-full w-full 2xl:mx-0 2xl:w-10/12 flex-col ">
          <div className="CruisesCarousel">
            <LandingBanner data={cruiseData} />
          </div>

          <div className="vacation-info mt-2 text-center p-4">
            <h1 className="title md:text-2xl mb-4 text-[#171717] font-semibold justify-center">
              {cruiseData?.productName}
            </h1>
            <p className="description text-sm md:text-base text-[#3B474B]">
              {cruiseData?.overview}
            </p>
          </div>

          <div className="flex w-full h-full flex-col">
            <div className="max-w-full flex mx-2 p-4">
              <div className="bg-white w-full  flex flex-col rounded-lg overflow-hidden">
                {Object.keys(cruiseData).length > 0 && (
                  <>
                    <img
                      src={cruiseData?.imageUrl[1]}
                      alt="Desert Safari"
                      className="w-full h-64 object-cover"
                    />
                  </>
                )}

                <form onSubmit={handleSubmit}>
                  <div className="flex flex-col lg:flex-row w-full border-2 -mt-6 shadow-md bg-white lg:rounded-full overflow-hidden items-center">
                    <div className="flex flex-1 flex-col lg:flex-row gap-2 items-center p-2 lg:pl-6 lg:p-2 w-full">
                      <div className="flex-1 flex flex-col border-b lg:border-r-2 lg:border-b-0 justify-center items-start w-full lg:min-w-[120px]">
                        <label className="flex px-2 text-[#222222]">Date</label>
                        <input
                          type="date"
                          id="bookingDate"
                          value={checkInDate || defaultDate}
                          onChange={handleDateChange}
                          placeholder="All Dates"
                          name="bookingDate"
                          min={getTodayDate()}
                          className="mt-1 px-2 block w-full py-2 bg-white rounded-md sm:text-sm border-b border-transparent focus:border-gray-300 focus:outline-none"
                        />
                      </div>

                      <div className="flex-1 w-full lg:min-w-[100px] border-b lg:border-r-2 lg:border-b-0">
                        <label className="block px-1 text-gray-700">
                          No of guests
                        </label>
                        <input
                          type="number"
                          value={guestNumber}
                          onChange={handleGuestNumberChange}
                          placeholder="Add guests"
                          min="0"
                          className="mt-1 px-2 block w-full py-2 bg-white rounded-md sm:text-sm border-b border-transparent focus:border-gray-300 focus:outline-none"
                        />
                        {guestError && (
                          <p className="text-gray-500 text-xs mt-1">
                            {guestError}
                          </p>
                        )}
                      </div>

                      <div className="flex-1 w-full lg:min-w-[100px] border-b lg:border-r-2 lg:border-b-0">
                      <label className="block px-1 text-gray-700">
                        Email
                      </label>
                      <input
                        type="email"
                        onChange={handleEmailChange}
                        placeholder="Enter your Email Address"
                        className="mt-1 px-1 block w-full py-2 bg-white rounded-md sm:text-sm border-b border-transparent focus:border-gray-300 focus:outline-none"
                      />
                    </div>

                      <div className="flex-1 w-full lg:min-w-[100px] border-b lg:border-r-2 lg:border-b-0">
                        <label className="block text-gray-700 px-1">
                          Mobile
                        </label>
                        <PhoneInput
                          placeholder="Enter your mobile number"
                          value={phone}
                          onChange={handlePhoneChange}
                          className="mt-1 px-2 block w-full py-2 bg-white rounded-md sm:text-sm border-b border-transparent focus:border-gray-300 focus:outline-none"
                        />
                        {phoneeError && (
                          <p className="text-gray-500 text-xs mt-1">
                            {phoneeError}
                          </p>
                        )}
                      </div>

                      <div className="flex items-center text-lg px-2 font-semibold text-[#000000] w-full lg:w-auto justify-center lg:justify-start">
                        {calculatedPrice} AED
                      </div>
                    </div>

                    <div className="px-4 mb-5 lg:mb-0 w-full lg:w-auto flex justify-center lg:justify-start mt-4 lg:mt-0">
                      <button
                        type="submit"
                        disabled={
                          guestNumber <= 0 || isNaN(guestNumber) || !phone
                        }
                        className={`bg-[#009BDE] text-white lg:py-5 lg:rounded-r-full py-2 px-4 rounded w-full lg:w-auto ${
                          guestNumber <= 0 || isNaN(guestNumber) || !phone
                            ? "bg-gray-300 text-gray-600 cursor-not-allowed"
                            : "bg-[#009BDE] text-white"
                        }`}
                      >
                        Book Now
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="CruisesTitleSection p-12">
            <div className="CruisesTitleDescriptiveSection">
              <div className="CruisesTitleBookContainer">
                <div className="flex w-full min-h-[450px] h-[450px] lg:h-[510px] lg:min-h-[510px] ">
                  {Object.keys(cruiseData).length > 0 && (
                    <>
                      <img
                        className="flex rounded-lg object-cover w-full"
                        src={cruiseData?.imageUrl[3]}
                        alt="highlight"
                      />
                    </>
                  )}
                </div>
              </div>
              <div className="md:CruisesTitleDescriptionContainer">
                <div className="flex flex-col w-full h-full">
                  <div className="flex flex-1">
                    <div className="max-w-2xl mx-auto p-2">
                      <h2 className="md:text-2xl mt-3 md:mt-1 font-bold mb-4">
                        Highlights
                      </h2>

                      <p className="flex w-full text-sm md:text-base mb-5 md:mb-0 flex-wrap">
                        {cruiseData.voyage}
                      </p>
                      <ul className="space-y-2 mt-3">
                        {Object.keys(cruiseData).length > 0 &&
                          cruiseData?.highlights
                            .slice(0, 4)
                            .map((highlight, index) => (
                              <li
                                key={index}
                                className="flex items-start text-sm md:text-base py-2"
                              >
                                <span className="text-yellow-500 min-w-8 min-h-8 2xl:text-xl text-lg mr-2">
                                  <img
                                    className="-mt-1 mr-1"
                                    src={Star}
                                    alt="star"
                                  />
                                </span>
                                <p className="text-gray-700">{highlight}</p>
                              </li>
                            ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="CruisesTitleSectionn p-12">
            <div className="CruisesTitleDescriptiveSection">
              <div className="md:CruisesTitleDescriptionContainer">
                <div className="flex flex-col w-full mr-3 h-full">
                  <div className="flex flex-1">
                    <div className="max-w-2xl mx-auto p-2">
                      <h2 className="md:text-2xl font-bold mb-4">
                        Dinner Options
                      </h2>
                      <p className="flex w-full text-sm md:text-base mb-5 md:mb-0 flex-wrap">
                        {cruiseData.dinnerOptions}
                      </p>
                      <ul className="space-y-2 mt-5 lg:mt-9">
                        {Object.keys(cruiseData).length > 0 &&
                          cruiseData?.vitalInfo
                            ?.slice(0, 4)
                            .map((highlight, index) => (
                              <li
                                key={index}
                                className="flex items-start text-sm md:text-base py-2"
                              >
                                <span className="text-yellow-500 min-w-8 min-h-8 2xl:text-xl text-lg mr-2">
                                  <img className="mr-2" src={Star} alt="star" />
                                </span>
                                <p className="text-gray-700">{highlight}</p>
                              </li>
                            ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="CruisesTitleBookContainer">
                <div className="flex w-full min-h-[450px] h-[450px] lg:h-[510px] lg:min-h-[510px]">
                  {Object.keys(cruiseData).length > 0 && (
                    <>
                      <img
                        className="flex rounded-lg md:ml-4 object-cover w-full"
                        src={cruiseData?.imageUrl[2]}
                        alt="highlight"
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="MoreDetailsSection">
            <div className="card-container text-sm md:text-base">
              <p className="px-3">Additional Information</p>
              <div className="text-sm px-3 md:text-base w-full flex -mt-3 md:mt-0">
                Get excited! We are thrilled to offer you the best values for
                your travel journey, ensuring your trip is memorable and filled
                with unforgettable moments.
              </div>
              <div className="card-hold">
                <div className="card">
                  <p className="flex bg-[#189E6E] rounded-t-md text-white max-h-[48px] py-3 items-center">
                    <img src={check} alt="check" className="px-2" />
                    Included
                  </p>
                  <div className="flex flex-1 flex-col text-xs md:text-sm py-3">
                    <ul className="">
                      {cruiseData?.included?.map((item, idx) => (
                        <li key={idx}>
                          <span className="px-2">•</span>
                          {item}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>

                <div className="card">
                  <p className="flex bg-[#E84A4A] rounded-t-md text-white max-h-[48px] py-3 items-center">
                    <img src={notcheck} alt="check" className="px-2" />
                    Excluded
                  </p>{" "}
                  <div className="features text-xs md:text-sm py-3">
                    <ul>
                      {cruiseData?.excluded?.map((item, idx) => (
                        <li key={idx}>
                          <span className="px-2">•</span>
                          {item}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>

                <div className="card">
                  <p className="flex bg-[#E2A632] rounded-t-md text-white max-h-[48px] py-3 items-center">
                    <img src={info} alt="check" className="px-2" />
                    Vital Information
                  </p>{" "}
                  <div className="features text-xs md:text-sm py-3">
                    <ul>
                      {cruiseData?.vitalInfo?.map((item, idx) => (
                        <li key={idx}>
                          <span className="px-2">•</span>
                          {item}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="FAQSection">
            <FAQComponent />
          </div>

          <div className="image-collage">
            <div className="holder1">
              <div className="structure1">
                {Object.keys(cruiseData).length > 0 && (
                  <>
                    <img
                      src={cruiseData?.menuImages[0]}
                      alt="pic"
                      className="pic-horizontal"
                    />
                  </>
                )}
              </div>
              <div className="structure2">
                {Object.keys(cruiseData).length > 0 && (
                  <>
                    <img
                      src={cruiseData.menuImages[1]}
                      alt="pic"
                      className="p"
                    />
                  </>
                )}
                {Object.keys(cruiseData).length > 0 && (
                  <>
                    <img
                      src={cruiseData.menuImages[2]}
                      alt="pic"
                      className="pi"
                    />
                  </>
                )}
              </div>
            </div>

            <div className="holder2">
              <div className="structure3">
                {Object.keys(cruiseData).length > 0 && (
                  <>
                    <img
                      src={cruiseData.menuImages[3]}
                      alt="pic"
                      className="picture"
                    />
                  </>
                )}
              </div>
              <div className="structure4">
                {Object.keys(cruiseData).length > 0 && (
                  <>
                    <img
                      src={cruiseData.menuImages[4]}
                      alt="pic"
                      className="pp"
                    />
                  </>
                )}
                {Object.keys(cruiseData).length > 0 && (
                  <>
                    <img
                      src={cruiseData.menuImages[5]}
                      alt="pic"
                      className="pip"
                    />
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="dinner-cruise">
            <div className="dinner-cruise-content">
              <h2>
                Sunset Splendor: Indulge in Exquisite Cuisine on Our Dinner
                Cruises
              </h2>
              <p>
                Experience a delightful evening with our dinner cruises,
                offering exquisite cuisine, breathtaking views, and impeccable
                service. Unforgettable moments await!
              </p>
              <div className="rating">3.5</div>
            </div>
            <div className="dinner-cruise-button">
              <button>Download Menu</button>
            </div>
          </div>

          <div className="tour-title">Popular Tours</div>
          <div className="tours-details-section px-4">
            <RecomendedSlider populardata={populardata} />
          </div>
          <div className="activity-container px-2">
            <div className="tour-titl px-2 text-[#282B2C]">
              <h2>Create best experience for your trip to Dubai</h2>
            </div>
            <div className="desc-cont px-2">
              <p>
                Create the best experience for your trip to Dubai with our
                exceptional services, tailored to ensure every moment of your
                journey is unforgettable.
              </p>
            </div>
            <div className="menu-cont px-2">
              {popularActivites.map((item, index) => (
                <div
                  key={index}
                  className="min-w-full mb-5 cursor-pointer  border-2 p-2  bg-[#FFFFFF] sm:min-w-[calc(50%-1rem)] md:min-w-[calc(33.333%-1rem)] lg:min-w-[calc(25%-1rem)] xl:min-w-[20%] rounded-lg overflow-hidden"
                >
                  <Link key={index} to={`/activities`} className="CardImageOne">
                    <div
                      className="h-48 bg-normal1 rounded-md bg-cover bg-no-repeat relative overflow-hidden"
                      style={{ backgroundImage: `url(${item?.imageUrl[0]})` }}
                    >
                      <LazyLoadImage
                        alt={"cardimage"}
                        effect="blur"
                        src={item?.imageUrl[0]}
                        className="hidden"
                      />
                    </div>
                    <div className="py-4 px-1">
                      <div className="flex w-full justify-between ">
                        <h3 className="md:text-lg font-semibold overflow-hidden whitespace-nowrap text-ellipsis">
                          {item.productName}
                        </h3>
                      </div>
                      <div className="flex items-center text-sm overflow-hidden whitespace-nowrap text-ellipsis">
                        <span className="text-yellow-500">&#9733;</span>
                        <span className="ml-2 pr-3">{item.ratings}(21.1k)</span>
                      </div>
                      <p className="mt-2 text-xs md:text-sm text-[#2D2D2D]">
                        Starting From <b>{item.price}</b> AED
                      </p>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CruiseDetailsPage;
