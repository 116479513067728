import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import './MapComponent.css';

const iconUrl = require("leaflet/dist/images/marker-icon.png");
const iconRetinaUrl = require("leaflet/dist/images/marker-icon-2x.png");
const shadowUrl = require("leaflet/dist/images/marker-shadow.png");

L.Icon.Default.mergeOptions({
  iconRetinaUrl,
  iconUrl,
  shadowUrl,
});

const MapComponent = ({ location }) => {
  const { lat, lng } = location;


  return (
    <div className="MapPageContainer">
      <span className="FAQSectionTitleText">Location</span>
      <MapContainer
        center={[lat, lng]}
        zoom={13}
        style={{ height: "400px", width: "100%" }}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <Marker position={[lat, lng]}>
          <Popup>
            A pretty CSS3 popup. <br /> Easily customizable.
          </Popup>
        </Marker>
      </MapContainer>
    </div>
  );
};

export default MapComponent;
