import React, { useState, useEffect } from "react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-cards";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import { yachtactivityImage } from "../../Images/cruiseImages";
import { BestInCurseImg5 } from "../../Images/Images";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Skeleton,
  Stack,
} from "@mui/material";
import "./RecomendedSlider.css"; // Assume this is where your styles are
import Arrow from "../../Images/safariImg/ArrowCircleUpRight.svg";
import { popularCruises, popularSafaris, popularYachts } from "../../cruiseData";
import { Link } from "react-router-dom";



const RecomendedSlider = ({populardata}) => {
  const [loading, setLoading] = useState(true);

 
  useEffect(() => {

    setTimeout(() => {
      setLoading(false);
    }, 2000); 
  }, []);

  const formatproductName = (productName) => {
    return productName.replace(/ /g, "-");
  };

  return (
    <>
      {loading ? (
        <div className="skeleton-container">
          {[...Array(4)].map((_, index) => (
            <Stack key={index} spacing={1} className="skeleton-stack">
              <Skeleton variant="rectangular" width={260} height={238} />
              <Skeleton variant="text" width="60%" />
              <Skeleton variant="text" width="80%" />
            </Stack>
          ))}
        </div>
      ) : (
        <Swiper
          slidesPerView={4}
          loop={true}
          scrollbar={{ draggable: true }}
          modules={[Navigation, Autoplay]}
          autoplay={{
            delay: 3000, // Delay between transitions (in milliseconds)
            disableOnInteraction: false, // Continue autoplay after user interaction
          }}
          className="mySwiper"
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            469: {
              slidesPerView: 2,
            },
            767: {
              slidesPerView: 2,
            },
            865: {
              slidesPerView: 3,
            },
            965: {
              slidesPerView: 3,
            },
            1100: {
              slidesPerView: 4,
            },
            1500: {
              slidesPerView: 4,
            },
            1700: {
              slidesPerView: 5,
            },
          }}
        >
          {populardata?.length>0 && populardata?.map((item, i) => (
            <SwiperSlide key={i}>
              <Link
                to={`/${item.source}/${formatproductName(item.productName)}`}
                className="CardImageOne"
              >
              <div key={i} className="tour-details">
                <img src={item.imageUrl[0]} alt="Tour" className="tour-image" />
                <div className="overlay-text">
                  <div className="tourcontainer">
                    <div className="details-tour">
                      <div className="display-tour">{item.productName}</div>
                      <div className="route-tour">
                        <img src={Arrow} alt="Route" className="arrow-route" />
                      </div>
                    </div>
                    <div className="price-tour">{item.price} AED</div>
                  </div>
                </div>
              </div>
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </>
  );
};

export default RecomendedSlider;
