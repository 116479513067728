import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import "./FAQComponent.css";

const FAQComponent = () => {
  const [expanded, setExpanded] = useState(false);
  const [showMore, setShowMore] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
       <div className="FAQSectionMainContainer">
        <div className="flex w-full h-full mb-6 text-2xl text-[#E08010] font-semibold">
          <span className="flex w-full justify-center items-center">
            FAQ
          </span>
        </div>

        <div className="MainFAQContentSection">
          <div className="LeftSideFAQSection">
            {/* Task #BITVP-110  #Rehaan #30/03/2024 */}

            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
              className="MainAccordianContent"
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography
                  className={`AccordianHeaderTitle ${
                    expanded === "panel1" ? "bold" : ""
                  }`}
                >
                  What is included in a typical desert safari package?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="AccordianDescTxt">
                  A typical desert safari package includes activities like dune bashing, camel riding, sandboarding, quad biking, and cultural performances such as belly dancing and Tanoura shows. Meals, such as BBQ dinners, are often included as well.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
              className="MainAccordianContent"
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <Typography
                  className={`AccordianHeaderTitle ${
                    expanded === "panel2" ? "bold" : ""
                  }`}
                >
                  What should I wear for a desert safari in Dubai?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="AccordianDescTxt">
                  It is advisable to wear comfortable, loose-fitting clothing suitable for the weather. Light, breathable fabrics are recommended during the day, and warmer clothes are suggested for the cooler evenings. Closed-toe shoes are recommended for activities like dune bashing.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
              className="MainAccordianContent"
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
              >
                <Typography
                  className={`AccordianHeaderTitle ${
                    expanded === "panel3" ? "bold" : ""
                  }`}
                >
                  Is it safe to go on a desert safari in Dubai?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="AccordianDescTxt">
                  Yes, desert safaris in Dubai are generally safe. Reputable tour operators ensure the safety and comfort of guests by using experienced drivers and well-maintained vehicles. It is important to follow the safety instructions provided by the guide.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "panel4"}
              onChange={handleChange("panel4")}
              className="MainAccordianContent"
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4-content"
                id="panel4-header"
              >
                <Typography
                  className={`AccordianHeaderTitle ${
                    expanded === "panel4" ? "bold" : ""
                  }`}
                >
                  What is the best time to go on a desert safari in Dubai?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="AccordianDescTxt">
                  The best time for a desert safari in Dubai is during the cooler months from October to April. Evening safaris are popular for witnessing the sunset over the dunes, while morning safaris offer a chance to experience the desert in cooler temperatures.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>

          <div className="RightSideFAQSection">
            <Accordion
              expanded={expanded === "panel5"}
              onChange={handleChange("panel5")}
              className="MainAccordianContent"
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel5-content"
                id="panel5-header"
              >
                <Typography
                  className={`AccordianHeaderTitle ${
                    expanded === "panel5" ? "bold" : ""
                  }`}
                >
                  Are children allowed on desert safaris?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="AccordianDescTxt">
                  Yes, children are generally allowed on desert safaris. However, it is advisable to check with the tour operator regarding any age restrictions for specific activities like dune bashing. Some safaris offer family-friendly options tailored for younger children.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "panel6"}
              onChange={handleChange("panel6")}
              className="MainAccordianContent"
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel6-content"
                id="panel6-header"
              >
                <Typography
                  className={`AccordianHeaderTitle ${
                    expanded === "panel6" ? "bold" : ""
                  }`}
                >
                  What should I bring for a desert safari?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="AccordianDescTxt">
                  It is recommended to bring sunscreen, sunglasses, a hat, a camera, and a bottle of water. If you plan to stay overnight, bring additional warm clothing. Personal items such as medications should also be carried along.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "panel7"}
              onChange={handleChange("panel7")}
              className="MainAccordianContent"
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel7-content"
                id="panel7-header"
              >
                <Typography
                  className={`AccordianHeaderTitle ${
                    expanded === "panel7" ? "bold" : ""
                  }`}
                >
                  Are meals provided during a desert safari?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="AccordianDescTxt">
                  Yes, most desert safaris include meals such as a BBQ dinner or lunch buffet. Complimentary soft drinks and snacks are often provided. It is advisable to check with the tour operator for specific meal inclusions and dietary options.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "panel8"}
              onChange={handleChange("panel8")}
              className="MainAccordianContent"
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel8-content"
                id="panel8-header"
              >
                <Typography
                  className={`AccordianHeaderTitle ${
                    expanded === "panel8" ? "bold" : ""
                  }`}
                >
                  What happens in case of bad weather during a desert safari?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="AccordianDescTxt">
                  In the event of inclement weather, the tour operator may reschedule the safari to another suitable date or provide alternative options. Some operators offer weather-related refunds or rescheduling policies, so it is advisable to check with them for specific details.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </div>
    </>
  );
};

export default FAQComponent;
