import React, { useRef } from "react";
import icon1 from "../../Images/icons/ic1.svg";
import icon2 from "../../Images/icons/ic2.svg";
import icon3 from "../../Images/icons/ic3.svg";
import icon4 from "../../Images/icons/ic4.svg";
import icon5 from "../../Images/icons/ic5.svg";
import icon6 from "../../Images/icons/ic6.svg";
import icon7 from "../../Images/icons/ic7.svg";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-cards";
import "./DubaiExperience.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import Next from "../../Images/icons/Next.svg";
import Prev from "../../Images/icons/Prev.svg";
import {
  ObservationActivites,
  SightseeingActivites,
  ThemeParkActivites,
} from "../../cruiseData";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";

const DubaiExperience = () => {
  const sliderRef = useRef(null);
  const data = [
    ...ObservationActivites,
    ...ThemeParkActivites,
    ...SightseeingActivites,
  ];
  const handleNext = () => {
    if (sliderRef.current) {
      sliderRef.current.swiper.slideNext();
    }
  };

  const handlePrev = () => {
    if (sliderRef.current) {
      sliderRef.current.swiper.slidePrev();
    }
  };

  const formatproductName = (productName) => {
    return productName.replace(/ /g, "-");
  };

  return (
    <div className="w-full min-h-[18vh] flex flex-col mx-auto px-4 py-8">
      <div className="flex w-full justify-between md:min-h-[8vh] items-center">
        <h2 className="md:text-2xl font-bold mb-4 text-[#2D2D2D]">
          Top experiences in Dubai
        </h2>
        <div className="flex items-center mb-4 space-x-4">
          <button className="text-[#585858] hover:underline hover:text-[#009BDE]">
            <Link to={`/activities`} className="CardImageOne">
              See All
            </Link>
          </button>
          {/* <div className="md:flex none space-x-2">
            <button
              onClick={handlePrev}
              className="p-2  rounded-full"
            >
             <img width={35} src={Next} alt="next" />
            </button>
            <button
              onClick={handleNext}
              className="p-2 rounded-full"
            >
             <img width={35} src={Prev} alt="next" />
             </button>
          </div> */}
        </div>
      </div>

      <div className="flex justify-between items-center mb-4">
        <div className="flex w-full text-sm lg:text-base text-[#3B474B] flex-wrap mb-4 sm:mb-0">
          <button className="button  mr-[32px] flex rounded mb-2 hover:underline hover:text-[#009BDE]">
            <Link
              to={`/activities`}
              className="button p-2 mr-[32px] flex rounded mb-2 hover:underline hover:text-[#009BDE]"
            >
              <div
                className="icon mr-2 lg:mr-3"
                style={{ backgroundImage: `url(${icon1})` }}
              />
              All Activities
            </Link>
          </button>

          <button className="button  mr-[32px] flex rounded mb-2 hover:underline hover:text-[#009BDE]">
            <Link
              to={`/activities/ObservationActivites/Burj-Khalifa-At-the-Top--Deck-Ticket-`}
              className="button p-2 mr-[32px] flex rounded mb-2 hover:underline hover:text-[#009BDE]"
            >
              <div
                className="icon mr-2 lg:mr-3"
                style={{ backgroundImage: `url(${icon2})` }}
              />
              Burj Khalifa Ticket
            </Link>
          </button>

          <button className="button  mr-[32px] flex rounded mb-2 hover:underline hover:text-[#009BDE]">
            <Link
              to={`/activities`}
              className="button p-2 mr-[32px] flex rounded mb-2 hover:underline hover:text-[#009BDE]"
            >
              <div
                className="icon mr-2 lg:mr-3"
                style={{ backgroundImage: `url(${icon3})` }}
              />
              Adventure Ticket
            </Link>
          </button>

          <button className="button  mr-[32px] flex rounded mb-2 hover:underline hover:text-[#009BDE]">
            <Link
              to={`/cruises`}
              className="button p-2 mr-[32px] flex rounded mb-2 hover:underline hover:text-[#009BDE]"
            >
              <div
                className="icon mr-2 lg:mr-3"
                style={{ backgroundImage: `url(${icon4})` }}
              />
              Cruise
            </Link>
          </button>

          <button className="button  mr-[32px] flex rounded mb-2 hover:underline hover:text-[#009BDE]">
            <Link
              to={`/yachts`}
              className="button p-2 mr-[32px] flex rounded mb-2 hover:underline hover:text-[#009BDE]"
            >
              <div
                className="icon mr-2 lg:mr-3"
                style={{ backgroundImage: `url(${icon5})` }}
              />
              Yacht
            </Link>
          </button>

          <button className="button mr-[32px] flex rounded mb-2 hover:underline hover:text-[#009BDE]">
            <Link
              to={`/safaris`}
              className="button p-2 mr-[32px] flex rounded mb-2 hover:underline hover:text-[#009BDE]"
            >
              <div
                className="icon mr-2 lg:mr-3"
                style={{ backgroundImage: `url(${icon6})` }}
              />
              Desert Safari
            </Link>
          </button>

          {/* <button className="button p-2 mr-[32px] flex rounded mb-2 hover:underline hover:text-[#009BDE]">
            <div
              className="icon mr-3"
              style={{ backgroundImage: `url(${icon7})` }}
            />
           Food Menu
          </button> */}
        </div>
      </div>

      <div className="overflow-hidden">
        <Swiper
          ref={sliderRef}
          modules={[Autoplay, Navigation]}
          spaceBetween={16}
          slidesPerView={1}
          loop={true}
          breakpoints={{
            640: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 3,
            },
            1024: {
              slidesPerView: 4,
            },
            1280: {
              slidesPerView: 5,
            },
          }}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          navigation={{
            prevEl: ".swiper-button-prev",
            nextEl: ".swiper-button-next",
          }}
        >
          {data.map((item, index) => (
            <SwiperSlide key={index}>
              <div className="min-w-full p-1 cursor-pointer  sm:min-w-[calc(50%-1rem)] md:min-w-[calc(33.333%-1rem)] lg:min-w-[calc(25%-1rem)] xl:min-w-[20%] border-2 rounded-lg overflow-hidden">
                <Link
                  to={`/activities/${item.category}/${formatproductName(
                    item.productName
                  )}`}
                  className="CardImageOne"
                >
                  <div
                    className="h-48 bg-normal1 rounded-md bg-cover bg-no-repeat relative overflow-hidden"
                    style={{ backgroundImage: `url(${item?.imageUrl[0]})` }}
                  >
                    <LazyLoadImage
                      alt={"cardimage"}
                      effect="blur"
                      src={item?.imageUrl[0]}
                      className="hidden"
                    />
                  </div>
                  <div className="p-4">
                    <div className="flex w-full justify-between overflow-hidden whitespace-nowrap text-ellipsis">
                      <h3 className="text-lg lg:text-sm 2xl:text-lg font-semibold text-[#2D2D2D] overflow-hidden whitespace-nowrap text-ellipsis">
                        {item.productName}
                      </h3>
                    </div>
                    <div className="flex items-center text-xs overflow-hidden whitespace-nowrap text-ellipsis">
                      <span className="text-yellow-500 text-lg lg:text-xl">&#9733;</span>
                      <span className="ml-2 pr-3">{item.ratings}(21.1k)</span>
                    </div>
                    <p className="mt-2 lg:text-sm 2xl:text-auto text-[#3B474B]">
                      Starting From <b>{item.price}</b> AED
                    </p>
                  </div>
                </Link>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default DubaiExperience;
