import React, { useRef } from 'react';
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from 'swiper/react';
import Next from "../../../Images/icons/Next.svg";
import Prev from "../../../Images/icons/Prev.svg";
import { yachts } from '../../../cruiseData';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';

const YachtLux = () => {
  const sliderRef1 = useRef(null);

  const handleNext1 = () => {
    if (sliderRef1.current) {
      sliderRef1.current.swiper.slideNext();
    }
  };

  const handlePrev1 = () => {
    if (sliderRef1.current) {
      sliderRef1.current.swiper.slidePrev();
    }
  };

  const formatproductName = (productName) => {
    return productName.replace(/ /g, "-");
  };

  return (
    <div className="w-full mt-2 md:mt-14 bg-[#EBF9FF] min-h-[18vh] flex flex-col  px-8 py-8">
      <div className="flex w-full justify-between min-h-[6vh] items-center">
        <h2 className="md:text-2xl font-bold md:mb-4 text-[#2D2D2D]">Luxury Yacht in Dubai</h2>
        <div className="flex items-center space-x-4">
        <Link to={`/yachts`} >
        <button className="text-[#585858 hover:underline md:mb-4 hover:text-[#009BDE]">
        <Link to={`/yachts`} className="CardImageOne">
        See All
        </Link>
        </button>
        </Link>
          {/* <div className="md:flex none  md:mb-4 space-x-2">
            <button
              onClick={handlePrev1}
              className="p-2  rounded-full"
            >
             <img width={35} src={Next} alt="next" />
            </button>
            <button
              onClick={handleNext1}
              className="p-2 rounded-full"
            >
             <img width={35} src={Prev} alt="next" />
             </button>
          </div> */}
        </div>
      </div>
      <div className="flex justify-between items-center mb-4">
        <div className="flex w-full text-sm md:text-base text-[#3B474B] flex-wrap justify-between mb-8 sm:mb-0">

        Sail along Dubai’s stunning coastline in unparalleled comfort and style. Enjoy gourmet cuisine, soak up the sun, and take in the breathtaking skyline. 
         Book your voyage today and elevate your Dubai experience to new heights!
        </div>
      </div>

      <div className="overflow-hidden mt-3">
        <Swiper
          ref={sliderRef1}
          spaceBetween={16}
          slidesPerView={1}
          breakpoints={{
            640: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 3,
            },
            1024: {
              slidesPerView: 4,
            },
            1280: {
              slidesPerView: 5,
            },
          }}
          pagination={{ clickable: true }}
          grabCursor={true}
        >
          {yachts.map((item, index) => (
            <SwiperSlide key={index}>
              <Link
                  to={`/yachts/${formatproductName(item.productName)}`}
                >
              <div className="min-w-full cursor-pointer  bg-[#FFFFFF] sm:min-w-[calc(50%-1rem)] md:min-w-[calc(33.333%-1rem)] lg:min-w-[calc(25%-1rem)] xl:min-w-[20%] border-2 p-1 rounded-lg overflow-hidden">
              <div 
                  className="h-48 bg-normal1 rounded-md bg-cover bg-no-repeat relative overflow-hidden"
                  style={{ backgroundImage: `url(${item?.imageUrl[0]})` }}
                >
 
                  <LazyLoadImage
                    alt={"cardimage"}
                    effect="blur"
                    src={item?.imageUrl[0]}
                    className="hidden"
                  />
                </div>
                <div className="p-4">
                  <div className="flex w-full justify-between ">
                    <h3 className="text-lg font-semibold overflow-hidden whitespace-nowrap text-ellipsis">
                      {item.productName}
                    </h3>
                  </div>
                  <p className="mt-2 text-sm text-[#2D2D2D]">Starting From <b>{item.price}</b>AED</p>
                </div>
              </div>
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default YachtLux;
