import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import { ContactBannerImage } from "../../Images/Images.js";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import "./ContactUsComponent.css";

const ContactUsComponent = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [nameError, setNameError] = useState("");
  const [formError, setFormError] = useState("");

  const handleNameChange = (e) => {
    setName(e.target.value);
    setNameError("");
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setEmailError("");
  };

  const handlePhoneChange = (value) => {
    setPhone(value);
    setPhoneError("");
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  
    let valid = true;
  
    // Check name
    if (!name) {
      setNameError("Please enter your name");
      valid = false;
    } else {
      console.log("Name:", name); // Debugging
    }
  
    // Check email
    if (!email) {
      setEmailError("Please enter your email");
      valid = false;
    } else if (!validateEmail(email)) {
      setEmailError("Please enter a valid email");
      valid = false;
    } else {
      console.log("Email:", email); // Debugging
    }
  
    // Check phone number
    if (!phone) {
      setPhoneError("Please enter your phone number");
      valid = false;
    } else if (phone.length < 10) {
      setPhoneError("Please enter a valid phone number");
      valid = false;
    } else {
      console.log("Phone:", phone); // Debugging
    }
  
    // Check if all fields are valid
    if (!valid) {
      setFormError("Please enter all credentials correctly");
      console.log("Form error:", formError); // Debugging
      return;
    }
  
    // Form submission
    const templateParams = {
      name,
      user_email: email,
      phone,
      message: "Contact Us Form Submission",
    };
  
    emailjs
      .send(
        "service_lwtlh2h",
        "template_9606ai3",
        templateParams,
        "upvgjVmpU7y0ctsks"
      )
      .then((response) => {
        console.log('Email sent successfully!', response.status, response.text);
      })
      .catch((err) => {
        console.error("Failed to send email:", err);
        alert("Failed to send email. Please try again later.");
      });
  };

  return (
    <div className="flex flex-col-reverse lg:flex-row items-center justify-center min-h-[70vh] bg-[#E8F8FF] p-4 md:p-8">
      <div className="w-full lg:w-1/2 xl:px-20 flex flex-col items-center lg:items-start text-center lg:text-left">
        <h1 className="md:text-3xl text-xl font-bold mb-4">Get in Touch</h1>
        <p className="mb-8 text-sm md:text-base text-gray-700">
          We will contact you within 24 hours to know what you want
        </p>
        <form onSubmit={handleSubmit} className="w-full max-w-md space-y-4">
          <div className="w-full">
            <input
              type="text"
              id="name"
              value={name}
              onChange={handleNameChange}
              placeholder="Enter your name"
              className="w-full p-3 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
            />
            {nameError && <div className="text-red-500 text-sm mt-2">{nameError}</div>}
          </div>
          <div className="w-full">
            <input
              type="email"
              id="email"
              value={email}
              onChange={handleEmailChange}
              placeholder="Enter your email"
              className="w-full p-3 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
            />
            {emailError && <div className="text-red-500 text-sm mt-2">{emailError}</div>}
          </div>
          <div className="w-full bg-white flex items-center space-x-2">
            <PhoneInput
              placeholder="Enter your mobile number"
              value={phone}
              onChange={handlePhoneChange}
              className="w-full p-3 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
            />
          </div>
          {phoneError && <div className="text-red-500 text-sm mt-2">{phoneError}</div>}
          {formError && <div className="text-red-500 text-sm mt-2">{formError}</div>}
          <div className="w-full">
            <button
              type="submit"
              className="w-full p-3 bg-[#009BDE] text-white rounded focus:outline-none hover:bg-[#36b4eb]"
            >
              Contact Us
            </button>
          </div>
        </form>
      </div>
      <div className="w-full xl:w-3/4 2xl:w-2/3 p-5 sm:p-8">
        <img
          src={ContactBannerImage}
          alt="Contact Banner"
          className="w-full h-auto rounded"
        />
      </div>
    </div>
  );
};

export default ContactUsComponent;


//     const [name, setName] = useState("");
//     const [email, setEmail] = useState("");
//     const [phone, setPhone] = useState("");

//     const handleNameChange = (e) => {
//       setName(e.target.value);
//     };

//     const handleEmailChange = (e) => {
//       setEmail(e.target.value);
//     };

//     const handlePhoneChange = (e) => {
//       setPhone(e.target.value);
//     };

//     const handleSubmit = (e) => {
//       e.preventDefault();

//       const templateParams = {
//         name,
//         user_email: email,
//         phone,
//         message: "Contact Us Form Submission",
//       };

//       emailjs
//         .send("service_id", "template_id", templateParams, "user_id")
//         .then((response) => {
//           console.log("Email sent successfully!", response.status, response.text);
//         })
//         .catch((err) => {
//           console.error("Failed to send email:", err);
//           alert("Failed to send email. Please try again later.");
//         });
//     };
