import React, { useRef } from 'react';
import Car from "../../Images/homepage/carr.png";
import "swiper/css";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import Next from "../../Images/icons/Next.svg";
import Prev from "../../Images/icons/Prev.svg";
import { popularSafaris } from '../../cruiseData';
import { Link } from 'react-router-dom';

const OtherCruise = () => {

  const sliderRef3 = useRef(null);

  const handleNext3 = () => {
    if (sliderRef3.current) {
      sliderRef3.current.swiper.slideNext();
    }
  };

  const handlePrev3 = () => {
    if (sliderRef3.current) {
      sliderRef3.current.swiper.slidePrev();
    }
  };

  const formatproductName = (productName) => {
    return productName.replace(/ /g, "-");
  };


  return (
    <div className="w-full mt-2 md:mt-14 min-h-[18vh] flex flex-col mx-auto px-4 py-8">
      <div className="flex w-full justify-between min-h-[8vh] items-center">
        <h2 className="md:text-2xl font-bold text-[#2D2D2D]">Dubai Safari</h2>
        <div className="flex items-center space-x-4">
        <button className="text-[#585858] hover:underline md:mb-4 hover:text-[#009BDE]">
        <Link to={`/safaris`} className="CardImageOne">
        See All
        </Link>
        </button>
          {/* <div className="md:flex none md:mb-4 space-x-2">
            <button
              onClick={handlePrev3}
              className="p-2  rounded-full"
            >
             <img width={35} src={Next} alt="next" />
            </button>
            <button
              onClick={handleNext3}
              className="p-2 rounded-full"
            >
             <img width={35} src={Prev} alt="next" />
             </button>
          </div> */}
        </div>
      </div>
      <div className="flex justify-between items-center mb-4">
        <div className="flex w-full flex-wrap justify-between text-sm md:text-base mb-8 text-[#3B474B] sm:mb-0">
        Embark on an exhilarating safari through Dubai’s majestic desert landscapes in unparalleled comfort and style.
         Experience gourmet dining under the stars, thrilling dune rides, and captivating sunsets. 

        </div>
      </div>

      <div className="overflow-hidden mt-3">
        <Swiper
          ref={sliderRef3}
          modules={[Autoplay, Navigation]}
          spaceBetween={16}
          slidesPerView={1}
          breakpoints={{
            640: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 3,
            },
            1024: {
              slidesPerView: 4,
            },
            1280: {
              slidesPerView: 5,
            },
          }}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          navigation={{
            prevEl: '.swiper-button-prev',
            nextEl: '.swiper-button-next',
          }}
        >
          {popularSafaris.map((item, index) => (
            <SwiperSlide key={index}>
               <Link
                  to={`/safaris/${formatproductName(item.productName)}`}
                >
              <div className="min-w-[80%] relative sm:min-w-[calc(50%-1rem)] md:min-w-[calc(33.333%-1rem)] lg:min-w-[calc(25%-1rem)] xl:min-w-[20%] border rounded-lg md:min-h-[290px] lg:min-h-[290px] xl:min-h-[279px] min-h-[320px] 2xl:min-h-[300px] overflow-hidden">
                <img
                  src={item.imageUrl[0]}
                  alt="Dessert Safari 4x4 Car"
                  className="absolute inset-0 h-full w-full object-cover"
                />
                <div className="absolute inset-0 bg-black opacity-30"></div>
                <div className="absolute bottom-0 w-full p-4 text-white text-center">
                  {item.productName}
                </div>
              </div>
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default OtherCruise;
