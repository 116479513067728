import React, {
  useRef,
  useState,
  useImperativeHandle,
  forwardRef,
} from "react";
import "../HomePage/DubaiVisaComponent/DubaiVisaComponent.css";
import "../HomePage/NewHome.css";
import { HomeBanner } from "../Images/Images";
import BestExperienceCards from "./BestExperienceCards/BestExperienceCards";
import WhatYouWillComponent from "./WhatYouWillGetComponent/WhatYouWillComponent";
import BestInSafariComponent from "./BestInSafariComponent/BestInSafariComponent";
import BestInCruise from "./BestInCruise/BestInCruise";
import DubaiVisaComponent from "./DubaiVisaComponent/DubaiVisaComponent";
import TransferServicesComponent from "./TransferServicesComponent/TransferServicesComponent";
import ContactUsComponent from "./ContactUsComponent/ContactUsComponent";
import DinnerCruise from "./DinnerCruise/DinnerCruise";
import YatchThreeCardSection from "./YatchThreeCardSection/YatchThreeCardSection";
import ImageSliderHomePageComponent from "../HomePage/ImageSliderHomePageComponent/ImageSliderHomePageComponent.jsx";
import ReviewSectionComponents from "./ReviewSectionComponents/ReviewSectionComponents.jsx";
import ReviewComponent from "./ReviewComponent/ReviewComponent.jsx";
import LuxuryYatchActiviy from "./LuxuryYatchActiviy/LuxuryYatchActiviy.jsx";
import Select from "react-select";
import clock from "../Images/icons/Clock.svg";
import news from "../Images/icons/Newspaper.svg";
import Desert from "../Images/homepage/desert.png";
import GroupPhoto from "../Images/Group-Photo-Image-New.svg";
import FbLogo from "../Images/InstagramLogo-New.svg";
import WPLogo from "../Images/InstagramLogo-New.svg";
import InstaLogo from "../Images/WhatsappLogo-New.svg";
import DubaiExperience from "./DubaiExperience/DubaiExperience.jsx";
import YachtLux from "./ReviewSectionComponents/YachtLux/YachtLux.jsx";
import CruiseDin from "./CruiseDin/CruiseDin.jsx";
import OtherCruise from "./OtherCruise/OtherCruise.jsx";
import { InternationalVisaImage } from "../Images/carouselmages/index.js";
import { Link, useNavigate } from "react-router-dom";
import {
  Alert,
  Box,
  Button,
  Modal,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { RxCrossCircled } from "react-icons/rx";
import {
  creekCruises,
  cruises,
  marinaCruises,
  ObservationActivites,
  popularCruises,
  popularYachts,
  safaris,
  SightseeingActivites,
  ThemeParkActivites,
  WaterParkActivites,
  yachts,
} from "../cruiseData.js";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import ImageSliderMobileHomeCmpt from "./ImageSliderHomePageComponent/ImageSliderMobileHomeCmpt.jsx";

const countryOptions = [
  {
    value: "+1",
    label: (
      <div style={{ display: "flex", alignItems: "center" }}>
        <img
          src="https://flagcdn.com/us.svg"
          alt="USA"
          style={{ width: "20px", marginRight: "3px" }}
        />
        +1
      </div>
    ),
  },
  {
    value: "+44",
    label: (
      <div style={{ display: "flex", alignItems: "center" }}>
        <img
          src="https://flagcdn.com/gb.svg"
          alt="UK"
          style={{ width: "20px", marginRight: "3px" }}
        />
        +44
      </div>
    ),
  },
  {
    value: "+91",
    label: (
      <div style={{ display: "flex", alignItems: "center" }}>
        <img
          src="https://flagcdn.com/in.svg"
          alt="India"
          style={{ width: "20px", marginRight: "3px" }}
        />
        +91
      </div>
    ),
  },
  // Add more countries as needed
];

const customStyles = {
  control: (provided) => ({
    ...provided,
    minHeight: "48px",
    border: "1px solid gray",
    borderRadius: "6px",
    display: "flex",
    marginRight: "10px",
    alignItems: "center",
    boxShadow: "none",
    "&:hover": {
      borderColor: "gray",
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    display: "flex",
    alignItems: "center",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: "4px",
  }),
  input: (provided) => ({
    ...provided,
    margin: "0",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),
};

const Home = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [country, setCountry] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [countryCode, setCountryCode] = useState({
    value: "+91",
    label: (
      <img
        src="https://flagcdn.com/in.svg"
        alt="India"
        style={{ width: "20px", marginRight: "8px" }}
      />
    ),
  });
  const [phoneError, setPhoneError] = useState("");

  const handleNameChange = (event) => setName(event.target.value);
  const handleEmailChange = (event) => setEmail(event.target.value);
  const handleCountryChange = (event) => setCountry(event.target.value);
  const handleQuantityChange = (event) => setQuantity(event.target.value);

  const handleViewAll = () => {
    navigate(`/visa`);
  };

  const handleCountryCodeChange = (selectedOption) => {
    setCountryCode(selectedOption);
  };

  const [openVisaModal, setOpenVisaModal] = useState(false);
  const [visaName, setVisaName] = useState("");
  const [visaPhoneNumber, setVisaPhoneNumber] = useState("");
  const [visaNationality, setVisaNationality] = useState("");
  const [visaQuantity, setVisaQuantity] = useState(1);

  const handleOpenVisaModal = () => setOpenVisaModal(true);
  const handleCloseVisaModal = () => setOpenVisaModal(false);

  const handleVisaNameChange = (e) => setVisaName(e.target.value);
  const handleVisaPhoneNumberChange = (e) => setVisaPhoneNumber(e.target.value);
  const handleVisaNationalityChange = (e) => setVisaNationality(e.target.value);
  const handleVisaQuantityChange = (e) => setVisaQuantity(e.target.value);
  const [phone, setPhone] = useState("");

  const handleVisaSubmit = () => {
    // Handle form submission
    handleCloseVisaModal();

    if (!phone) {
      setPhoneError("Please enter your phone number");
      return;
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const handleSubmit = () => {
    // Handle form submission logic
    handleClose();
    setSnackbarOpen(true);

    if (!phone) {
      setPhoneError("Please enter your phone number");
      return;
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const formatproductName = (productName) => {
    return productName.replace(/ /g, "-");
  };

  const handlePhoneChange = (value) => {
    setPhone(value);
  };

  return (
    <>
      <div className="flex w-full flex-col 2xl:justify-center 2xl:items-center">
        <div className="flex h-full w-full 2xl:mx-0 2xl:w-10/12 flex-col">
          <div className="slidingImageWeb">
            <ImageSliderHomePageComponent />
          </div>
          <div className="slidingImageMob">
            <ImageSliderMobileHomeCmpt />
          </div>
          <div className="flex w-full py-4 h-full">
            <DubaiExperience />
          </div>

          <div className="flex w-full h-full">
            <YachtLux />
          </div>

          <CruiseDin />

          <div className="bg-[#EBF9FF] flex mt-2 md:mt-16 flex-col py-12 px-4">
            <div className="w-full mx-auto">
              <h2 className="md:text-2xl font-bold mb-4 text-[#2D2D2D] px-6">
                Dubai and International visa
              </h2>{" "}
              <div className="flex px-6 justify-between items-center mb-4">
                <div className="flex w-full flex-wrap text-sm md:text-base justify-between mb-8 sm:mb-0 text-[#3B474B]">
                  Get your visa quickly with our service! We provide
                  international visas for a hassle-free trip to Dubai. Enjoy
                  luxury cruises, desert safaris, and more!{" "}
                </div>
              </div>
              <div className="flex xl:flex-nowrap flex-wrap min-h-[32vh] w-full justify-between p-4">
                <div className="max-w-2xl cursor-pointer  xl:mr-10 w-full bg-white p-6 rounded-lg shadow-sm flex flex-col md:flex-row mb-4 md:mb-2">
                  <div className="h-72 md:w-72 bg-gray-300 flex-shrink-0 rounded-lg overflow-hidden">
                    <img
                      src={Desert}
                      alt="Dubai Travel"
                      className="h-full w-full object-cover"
                    />
                  </div>
                  <div className="flex flex-col justify-between mt-4 md:mt-0 md:ml-6">
                    <div>
                      <h3 className="text-xl font-semibold mb-2 text-[#2D2D2D]">
                        Get Dubai Travel Visa
                      </h3>
                      <p className="text-[#3B474B] text-sm md:text-base mb-4">
                        Unlock your dream Dubai vacation with our swift visa
                        service! Enjoy luxury, safaris, and more hassle-free!
                      </p>
                    </div>
                    <ul className="mb-4 text-[#3B474B]">
                      <li className="flex items-center text-xs md:text-sm overflow-hidden whitespace-nowrap text-ellipsis mb-2">
                        <span className="material-icons mr-2">
                          <img src={clock} alt="ico" className="flex mr-3" />
                        </span>
                        Quick Visa: fast, minimize time.
                      </li>
                      <li className="flex items-center text-xs md:text-sm overflow-hidden whitespace-nowrap text-ellipsis mb-2">
                        <span className="material-icons mr-2">
                          <img src={news} alt="ico" className="flex mr-3" />
                        </span>
                        We handle all paperwork.
                      </li>
                    </ul>
                    <button
                      className="bg-[#009BDE] text-white py-2 px-4 rounded"
                      onClick={handleOpenVisaModal}
                    >
                      Get Dubai Visa
                    </button>
                  </div>
                </div>
                <div className="max-w-2xl cursor-pointer  w-full bg-white p-6 rounded-lg shadow-sm flex flex-col md:flex-row">
                  <div className="h-72 md:w-72 bg-gray-300 flex-shrink-0 rounded-lg overflow-hidden">
                    <img
                      src={InternationalVisaImage}
                      alt="Dubai Travel"
                      className="h-full w-full object-cover"
                    />
                  </div>
                  <div className="flex flex-col justify-between mt-4 md:mt-0 md:ml-6">
                    <div>
                      <h3 className="md:text-xl font-semibold mb-2">
                        Get International Travel Visa
                      </h3>
                      <p className="text-[#3B474B] text-sm md:text-base mb-4">
                        Unlock your ideal international getaway with our
                        expedited visa service! Experience luxury, thrilling
                        adventures, and more without any hassle!
                      </p>
                    </div>
                    <ul className="mb-4 text-[#3B474B]">
                      <li className="flex items-center mb-2 text-xs md:text-sm">
                        <span className="material-icons mr-2 ">
                          {" "}
                          <img src={clock} alt="ico" className="flex mr-3" />
                        </span>
                        Quick Visa: fast, minimize time.
                      </li>
                      <li className="flex items-center text-xs md:text-sm">
                        <span className="material-icons mr-2">
                          <img src={news} alt="ico" className="flex mr-3" />
                        </span>
                        We handle all paperwork.
                      </li>
                    </ul>
                    <button
                      className="bg-[#009BDE] text-white py-2 px-4 rounded"
                      onClick={handleOpen}
                    >
                      Get International Visa
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <OtherCruise />

          <div className="MainCardSection">
            <WhatYouWillComponent />
          </div>
          <div className="flex w-full min-h-[28vh] my-20">
            <ReviewSectionComponents />
          </div>

          <div className="flex w-full h-full">
            <ContactUsComponent />
          </div>

          <div className="flex w-full my-2 md:my-20">
            <ReviewComponent />
          </div>
          <div className="flex w-full my-2 mb-7 md:mb-10 md:my-20 ">
            <div className="flex w-full px-4">
              <div className="flex w-full flex-col">
                <h2 className="md:text-2xl font-semibold mb-4 text-[#2D2D2D]">
                  Explore More On Humrah
                </h2>
                <div className="space-y-6">
                  <div>
                    <h3 className="md:text-xl text-[#2D2D2D] font-semibold mb-3">
                      Best Selling UAE Tours :
                    </h3>
                    <div className="flex text-sm md:text-base flex-wrap gap-2">
                      {[
                        ...ObservationActivites,
                        ...SightseeingActivites,
                        ...ThemeParkActivites,
                      ].map((item, index) => (
                        <Link
                          to={`/activities/${item.category}/${formatproductName(
                            item.productName
                          )}`}
                        >
                          <span
                            key={index}
                            className="px-2 py-1 md:border-r-2 border-gray-400 cursor-pointer"
                          >
                            {item.productName}
                          </span>
                        </Link>
                      ))}
                    </div>
                  </div>
                  <div>
                    <h3 className="md:text-xl text-[#2D2D2D] font-semibold mb-3">
                      Trending Safaris :
                    </h3>
                    <div className="flex text-sm md:text-base flex-wrap gap-2">
                      {[...safaris].map((item, index) => (
                        <Link
                          to={`/safaris/${formatproductName(item.productName)}`}
                        >
                          <span
                            key={index}
                            className="px-2 py-1 md:border-r-2 border-gray-400"
                          >
                            {item.productName}
                          </span>
                        </Link>
                      ))}
                    </div>
                  </div>
                  <div>
                    <h3 className="md:text-xl text-[#2D2D2D] font-semibold mb-3">
                      Water Fun to do in Dubai :
                    </h3>
                    <div className="flex text-sm md:text-base flex-wrap gap-2">
                      {[
                        ...popularYachts,
                        ...marinaCruises,
                        ...creekCruises,
                      ].map((item, index) => (
                        <Link
                          to={`/${item.type}/${formatproductName(
                            item.productName
                          )}`}
                        >
                          <span
                            key={index}
                            className="px-2 py-1  md:border-r-2 border-gray-400"
                          >
                            {item.productName}
                          </span>
                        </Link>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal open={open} onClose={handleClose} className="MainModalBox">
        <Box sx={style} className="InsideModalBoxDesign">
          <div className="TitlImgCrssModl">
            <Typography
              id="modal-modal-title"
              variant="h4"
              component="h2"
              className="ModalTitleText"
            >
              Visa Form Application
            </Typography>
            <div>
              <RxCrossCircled onClick={handleClose} />
            </div>
          </div>
          <div className="HomeContactBannerTxtbox">
            <TextField
              fullWidth
              label="Name"
              id="name"
              value={name}
              onChange={handleNameChange}
              className="HomeContactTxtField"
            />
          </div>
          <div className="HomeContactBannerTxtbox">
            <PhoneInput
              placeholder="Enter your mobile number"
              value={phone}
              onChange={handlePhoneChange}
              className="w-full p-3 border border-gray-300 rounded focus:border-blue-500 focus:outline-none"
            />
          </div>
          {phoneError && (
            <div className="text-red-500 text-sm mt-2">{phoneError}</div>
          )}
          <div className="HomeContactBannerTxtbox">
            <TextField
              fullWidth
              label="Country"
              id="country"
              value={country}
              onChange={handleCountryChange}
              className="HomeContactTxtField"
            />
          </div>
          <div className="HomeContactBannerTxtbox">
            <TextField
              fullWidth
              label="Quantity"
              id="quantity"
              type="number"
              value={quantity}
              onChange={handleQuantityChange}
              className="HomeContactTxtField"
              inputProps={{ min: 1 }}
            />
          </div>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            className="SubmitButton"
          >
            Submit
          </Button>
        </Box>
      </Modal>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          Your Query Was Submitted , We will Get Back to You
        </Alert>
      </Snackbar>

      <Modal
        open={openVisaModal}
        onClose={handleCloseVisaModal}
        className="MainModalBox"
      >
        <Box sx={style} className="InsideModalBoxDesign">
          <div className="TitlImgCrssModl">
            <Typography
              id="modal-modal-title"
              variant="h4"
              component="h2"
              className="ModalTitleText"
            >
              Visa Form Application
            </Typography>
            <div>
              <RxCrossCircled onClick={handleCloseVisaModal} />
            </div>
          </div>
          <div className="HomeContactBannerTxtbox">
            <TextField
              fullWidth
              label="Name"
              id="visa-name"
              value={visaName}
              onChange={handleVisaNameChange}
              className="HomeContactTxtField"
            />
          </div>
          <div className="HomeContactBannerTxtbox">
            <PhoneInput
              placeholder="Enter your mobile number"
              value={phone}
              onChange={handlePhoneChange}
              className="w-full p-3 border border-gray-300 rounded focus:border-blue-500 focus:outline-none"
            />
          </div>
          {phoneError && (
            <div className="text-red-500 text-sm mt-2">{phoneError}</div>
          )}
          <div className="HomeContactBannerTxtbox">
            <TextField
              fullWidth
              label="Nationality"
              id="visa-nationality"
              value={visaNationality}
              onChange={handleVisaNationalityChange}
              className="HomeContactTxtField"
            />
          </div>
          <div className="HomeContactBannerTxtbox">
            <TextField
              fullWidth
              label="Quantity"
              id="visa-quantity"
              type="number"
              value={visaQuantity}
              onChange={handleVisaQuantityChange}
              className="HomeContactTxtField"
              inputProps={{ min: 1 }}
            />
          </div>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleVisaSubmit}
            className="SubmitButton"
          >
            Submit
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default Home;
