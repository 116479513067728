import React, { useRef } from 'react';
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from 'swiper/react';
import { marinaCruises, yachts } from '../../../../cruiseData.js';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';


const Canal = () => {
  const sliderRef1 = useRef(null);

  const handleNext1 = () => {
    if (sliderRef1.current) {
      sliderRef1.current.swiper.slideNext();
    }
  };

  const handlePrev1 = () => {
    if (sliderRef1.current) {
      sliderRef1.current.swiper.slidePrev();
    }
  };

  const formatproductName = (productName) => {
    return productName.replace(/ /g, "-");
  };

  return (
    <div className="w-full min-h-[18vh] flex flex-col 2xl:px-12 bg-[#E9F9FF]">
      <div className="flex w-full justify-between min-h-[8vh] items-center">
        <h2 className="md:text-2xl font-bold mt-4 text-[#2D2D2D] px-2">Canal</h2>
        <div className="flex items-center space-x-4">
          {/* Navigation buttons can be re-enabled here */}
        </div>
      </div>
      <div className="flex justify-between items-center mb-4">
        <div className="flex text-sm md:text-base w-full text-[#3B474B] flex-wrap justify-between mb-8 sm:mb-0 px-2">
          Sail along Dubai’s stunning coastline in unparalleled comfort and style. Enjoy gourmet cuisine, soak up the sun, and take in the breathtaking skyline. 
          Book your voyage today and elevate your Dubai experience to new heights!
        </div>
      </div>

      <div className="overflow-hidden mt-3 px-2">
        <Swiper
          ref={sliderRef1}
          spaceBetween={16}
          slidesPerView={1}
          breakpoints={{
            640: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 3,
            },
            1024: {
              slidesPerView: 4,
            },
            1280: {
              slidesPerView: 5,
            },
          }}
          pagination={{ clickable: true }}
          grabCursor={true}
        >
          {marinaCruises.map((item, index) => (
            <SwiperSlide key={index}>
              <div className="min-w-full cursor-pointer  bg-[#FFFFFF] p-3 mb-10 sm:min-w-[calc(50%-1rem)] md:min-w-[calc(33.333%-1rem)] lg:min-w-[calc(25%-1rem)] xl:min-w-[20%] rounded-lg overflow-hidden">
                <Link
                  to={`/cruises/${formatproductName(
                    item?.productName
                  )}`}
                  className=""
                >
                  <div
                    className="h-48 bg-normal1 rounded-md bg-cover bg-no-repeat relative overflow-hidden"
                    style={{ backgroundImage: `url(${item?.imageUrl[0]})` }}
                  >
                    <LazyLoadImage
                      alt={"cardimage"}
                      effect="blur"
                      src={item?.imageUrl[0]}
                      className="hidden"
                    />
                  </div>
                  <div className="py-4 px-1">
                    <div className="flex w-full justify-between ">
                      <h3 className="text-lg text-[#2D2D2D] font-semibold overflow-hidden whitespace-nowrap text-ellipsis">
                        {item.productName}
                      </h3>
                    </div>
                    <div className="flex items-center text-sm overflow-hidden whitespace-nowrap text-ellipsis">
                      <span className="text-yellow-500 text-lg pl-2">
                        &#9733;
                      </span>
                      <span className="ml-2 pr-3">{item.ratings}(21.1k)</span>
                    </div>
                    <p className="mt-2 text-sm text-[#2D2D2D]">
                      Starting From <b>{item.price}</b> AED
                    </p>
                  </div>
                </Link>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default Canal;
