import React from "react";
import PartnerImg1 from '../../Images/im1.png';
import PartnerImg2 from '../../Images/im2.png';
import PartnerImg3 from '../../Images/im3.png';
import PartnerImg4 from '../../Images/im4.png';
import PartnerImg5 from '../../Images/5.png';

const ReviewSectionComponents = () => {
    return (
        <>
            <div className="flex w-full -mt-4 md:mt-0 min-h-[10vh] justify-around flex-col">
                <div className="flex font-semibold mb-16 w-full justify-center text-xl md:text-3xl">
                    <h2>Our Partners</h2>
                </div>
                <div className="flex w-full justify-around flex-wrap">
                    <img className="partner-img hover:scale-[1.1] cursor-pointer  mx-2 my-2 max-w-[150px] sm:max-w-[200px] md:max-w-[250px] lg:max-w-[310px]" src={PartnerImg1} alt="Partner 1" />
                    <img className="partner-img hover:scale-[1.1] cursor-pointer  mx-2 my-2 max-w-[150px] sm:max-w-[200px] md:max-w-[250px] lg:max-w-[310px]" src={PartnerImg2} alt="Partner 2" />
                    <img className="partner-img hover:scale-[1.1] cursor-pointer  mx-2 my-2 max-w-[150px] sm:max-w-[200px] md:max-w-[250px] lg:max-w-[310px]" src={PartnerImg3} alt="Partner 3" />
                    <img className="partner-img hover:scale-[1.1] cursor-pointer  mx-2 my-2 max-w-[150px] sm:max-w-[200px] md:max-w-[250px] lg:max-w-[310px]" src={PartnerImg4} alt="Partner 4" />
                    <img className="partner-img hover:scale-[1.1] cursor-pointer  mx-2 my-2 max-w-[150px] sm:max-w-[200px] md:max-w-[250px] lg:max-w-[310px]" src={PartnerImg5} alt="Partner 5" />
                </div>
            </div>
        </>
    )
}

export default ReviewSectionComponents;
