import React, { useRef } from 'react';
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-cards";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import Next from "../../Images/icons/Next.svg";
import Prev from "../../Images/icons/Prev.svg";
import { creekCruises, marinaCruises } from '../../cruiseData';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';

const CruiseDin = () => {
  const sliderRef2 = useRef(null); 
  const data = [...marinaCruises,...creekCruises]

  const handleNext2 = () => {
    if (sliderRef2.current) {
      sliderRef2.current.swiper.slideNext();
    }
  };

  const handlePrev2 = () => {
    if (sliderRef2.current) {
      sliderRef2.current.swiper.slidePrev();
    }
  };

  const formatproductName = (productName) => {
    return productName.replace(/ /g, "-");
  };


  return (
    <div className="w-full mt-1 md:mt-14 min-h-[18vh] flex flex-col mx-auto px-4 py-8">
      <div className="flex w-full justify-between min-h-[8vh] items-center">
        <h2 className="md:text-2xl font-bold text-[#2D2D2D]">Dinner Cruise</h2>
        <div className="flex items-center space-x-4">
        <button className="text-[#585858] hover:underline md:mb-4 hover:text-[#009BDE]">
        <Link to={`/cruises`} className="CardImageOne">
        See All
        </Link>
        </button>
          {/* <div className="md:flex none  md:mb-4 space-x-2">
            <button
              onClick={handlePrev2}
              className="p-2  rounded-full"
            >
             <img width={35} src={Next} alt="next" />
            </button>
            <button
              onClick={handleNext2}
              className="p-2 rounded-full"
            >
             <img width={35} src={Prev} alt="next" />
             </button>
          </div> */}
        </div>
      </div>
      <div className="flex justify-between items-center mb-4">
        <div className="flex w-full text-sm md:text-base flex-wrap text-[#3B474B] justify-between mb-8 sm:mb-0">
        Embark on a breathtaking cruise along Dubai’s stunning coastline in unmatched luxury and elegance.
         Savor gourmet cuisine, bask in the sun, and marvel at the spectacular skyline. 
        Reserve your cruise today and elevate your Dubai adventure to extraordinary heights!
        </div>
      </div>

      <div className="overflow-hidden mt-3">
        <Swiper
          ref={sliderRef2}
          modules={[Autoplay, Navigation]}
          spaceBetween={16}
          slidesPerView={1}
          breakpoints={{
            640: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 3,
            },
            1024: {
              slidesPerView: 4,
            },
            1280: {
              slidesPerView: 5,
            },
          }}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          navigation={{
            prevEl: '.swiper-button-prev',
            nextEl: '.swiper-button-next',
          }}
        >
          {data.map((item, index) => (
            <SwiperSlide key={index}>
              <Link
                  to={`/cruises/${formatproductName(item.productName)}`}
                >
              <div className="min-w-full cursor-pointer p-1 sm:min-w-[calc(50%-1rem)] md:min-w-[calc(33.333%-1rem)] lg:min-w-[calc(25%-1rem)] xl:min-w-[20%] border-2 rounded-lg overflow-hidden">
              <div 
                  className="h-48 bg-normal1 rounded-md bg-cover bg-no-repeat relative overflow-hidden"
                  style={{ backgroundImage: `url(${item?.imageUrl[0]})` }}
                >
                  <LazyLoadImage
                    alt={"cardimage"}
                    effect="blur"
                    src={item?.imageUrl[0]}
                    className="hidden"
                  />
                </div>
                <div className="p-4">
                  <div className="flex w-full justify-between">
                    <h3 className="text-lg lg:text-sm 2xl:text-lg font-semibold text-[#2D2D2D]  overflow-hidden whitespace-nowrap text-ellipsis">
                     {item.productName}
                    </h3>
                  </div>
                    <div className="flex items-center text-sm overflow-hidden whitespace-nowrap text-ellipsis">
                      <span className="text-yellow-500 text-lg md:text-xl">&#9733;</span>
                      <span className="ml-2 pr-3">4.2</span>
                    </div>
                  <p className="mt-2 lg:text-sm 2xl:text-auto text-[#3B474B]">Starting From <b>{item.price}</b> AED</p>
                </div>
              </div>
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default CruiseDin;
